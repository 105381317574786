import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import ProductDetails from "./productDetail";
import { CircularProgress } from "@material-ui/core";
import { HOST_URL } from "../../action/api";
import { Swiper, SwiperSlide } from "swiper/react";
import Pay from "./pay";
import Pay2 from "./pay2";  // Assuming Pay2 is another component for different level_code

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./pricing.css";
import { Autoplay } from "swiper/modules";

function PricingBox({ game }) {
  const [datas, setDatas] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "flex-end",
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${HOST_URL}promoted-levels/`);
        const item = await response.json();
        if (item !== null) {
          setIsLoaded(true);
          setDatas([...item, item[0], item[1]]);
        }
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      {!isLoaded ? (
        <div>
          <Row>
            <Col lg={4}>
              <div className="text-center pricing-box">
                <CircularProgress />
              </div>
            </Col>
            <Col lg={4}>
              <div className="text-center pricing-box">
                <CircularProgress />
              </div>
            </Col>
            <Col lg={4}>
              <div className="text-center pricing-box">
                <CircularProgress />
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <Swiper
          loop={true}
          centeredSlides={true}
          slidesPerView={3}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            240: { slidesPerView: 1 },
            640: { slidesPerView: 1 },
            768: { slidesPerView: 1 },
            1024: { slidesPerView: 3, spaceBetween: 30 },
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {datas.map((pricing, key) => (
            <Col key={key} lg={4}>
              <SwiperSlide>
                <div className="text-left pricing-box">
                  <ProductDetails pricings={pricing} />
                  <div className="product_details">
                    <h3 className="text-uppercase" style={{ fontWeight: "bold" }}>
                      {pricing.level_name}
                    </h3>
                    <div className="plan-features">
                      <p>
                        <span style={{ fontWeight: "bold" }}>Level Code: </span>
                        <b className="text-secondary">{pricing.level_code}</b>
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Price: </span>
                        <b className="text-secondary">₹{pricing.price}</b>
                      </p>
                    </div>
                    <div style={buttonContainerStyle}>
                      {pricing.level_code === "TBF_L02" ? <Pay /> : <Pay2 />}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Col>
          ))}
        </Swiper>
      )}
    </React.Fragment>
  );
}

export default PricingBox;
