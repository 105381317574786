import { GET_PROFILE_DATA } from "../reducerKeys";

const initialState = {
  profileData: {
    add: [],
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE_DATA:
      return {
        ...state,
        profileData: {
          stu: action.payload.stu || state.profileData.stu,
          add: action.payload.add || state.profileData.add,
        },
      };
    default:
      return state;
  }
}
