import React from 'react'
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from './App'
import swDev from './swDev.js';
import store from "../src/redux/store";
import {Provider} from 'react-redux';
const app = (
  <BrowserRouter>
  <Provider store={store}>
    <App />
    </Provider>
  </BrowserRouter>
);
ReactDOM.render(app, document.getElementById("root"));
swDev();


