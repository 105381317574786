import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useEffect } from "react";
import { Avatar, Button, Grid, Paper, Typography } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import Image from "../assets/Image.jpga1.jpg";
import TeamEdit from "./TeamEdit";
import Profile from "./Profile";
// import Paytm from "../../pages/NewIslp/Registration/paytm";
import VideoUpload from "./VideoUpload";
import AddTeamMember from "./AddTeamMember";
import { HOST_URL } from "../../action/api";
import { useHistory } from "react-router-dom";

// import Paytm from '../../pages/NewIslp/Registration/paytm';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function IsplDashboard() {
  let [students, changeData] = useState([]);
  let [team_name, setTeam_name] = useState("");
  let [team_captain, setTeam_captain] = useState("");
  let [stem_project_idea, setstem_project_idea] = useState("");
  let [project_descripition, setproject_descripition] = useState("");
  const [value, setValue] = useState(0);
  const [team_id, setTeamId] = useState();
  const amount = 129;
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditClick = () => {
    setOpen(true);
  };

  const history = useHistory();

  const handleIsplRegistrationPage = () => {
    history.push({
      pathname: "/dashboard/registration",
    });
  };

  // const handleCloseDialog = () => {
  //   setOpen(false);
  // };

  useEffect(() => {
    // call your API or perform other side effects here
    getIsplDetails();
    // reset the formSubmitted state after submitting
  }, [formSubmitted]);

  const getIsplDetails = () => {
    var token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(`${HOST_URL}ispl/team/details`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let data = result.Data[0].fields;
        // changeData(data.student.slice(0, 5));
        setTeam_name(data.team_name);
        setTeam_captain(data.team_captain)
        setstem_project_idea(data.stem_project_idea);
        setproject_descripition(data.project_description);
        setTeamId(result.Data[0].pk);
        setData(data);
        // setAmount(amount)
      })
      .catch((error) => console.log("error", error));
  };

  let updateApi = (data) => {
    let newStudents = students.map((item) => {
      if (item.id === data.id) {
        return {
          id: item.id,
          name: data.name,
          contact: data.contact,
          email: data.email,
          school_name: data.schoolName,
          address: data.address,
          is_lead: "True",
        };
      }
      return item;
    });

    changeData(newStudents); // update the state with the new students data
    setFormSubmitted(true);

    // var raw = JSON.stringify({
    //   "id": 56,
    //   "team_name": team_name,
    //   "stem_project_idea": stem_project_idea,
    //   "project_descripition": project_descripition,
    //   "students": newStudents
    // });
    // console.log("raw----", raw);

    var token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: data.name,
      stu_id: data.id,
      email: data.email,
      contact: data.contact,
      school_name: data.schoolName,
      address: data.address,
      // "is_captain": data.is_captain
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${HOST_URL}ispl/student/update`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  return (
    <Grid>
      <Grid
        style={{
          backgroundImage: `url(${Image})`,
          marginBottom: "50px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          height: "180px",
          padding: "10px",
          borderRadius: "10px",
          backgroundSize: "contain",
        }}
      >
        <Grid>
          <Grid style={{ display: "flex", marginBottom: "6px" }}>
            <Avatar src={require("../assets/teamIcon.jpeg")} />
            <Grid
              style={{
                fontSize: "20px",
                paddingLeft: "30px",
                fontWeight: "800",
              }}
            >
              {" "}
              {team_name}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          onClick={handleIsplRegistrationPage}
          style={{
            borderRadius: 50,
          }}
        >
          Ispl-Registration
        </Button>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
          >
            <Tab label="Team members" {...a11yProps(0)} />
            <Tab label="Project Details" {...a11yProps(1)} />
            <Tab label="Competition Rules" {...a11yProps(2)} />
            <Tab label="Upload Video" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box sx={{ flexGrow: 1 }}>
            {/* <Grid container spacing={2} justify="center">
              <Grid item xs={12} sm={6} md={4}>
                <AddTeamMember
                  team_id={team_id}
                  data={data}
                  amount={amount}
                  setFormSubmitted={setFormSubmitted}
                />
              </Grid>
            </Grid> */}

            <Grid container spacing={2}>
              {students.map((item) => {
                return (
                  <Grid
                    key={item.id}
                    my={2}
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    sx={{ flexGrow: 1, flexBasis: 0 }}
                  >
                    {/* <Card key={item}> */}
                    <Profile
                      updateApi={updateApi}
                      id={item.id}
                      name={item.name}
                      email={item.email}
                      schoolname={item.school_name}
                      schooladdress={item.address}
                      contact={item.contact}
                    />
                    {/* </Card> */}
                  </Grid>
                );
              })}
            </Grid>
            {/* </Box> */}
            {/* </div> */}
            <Box
              display="flex"
              justifyContent="space-between"
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                alignContent: "space-between",
                justifyContent: "space-evenly",
                alignItems: "baseline",
                marginTop: "25px",
              }}
            >
              {/* <Grid item xs={2} sm={4} md={4}>
                <AddMember team_id={team_id} data={data} amount={amount} />
                {/* <Paytm team_id={team_id} totalAmount={129} /> */}
              {/* </Grid>                */}
            </Box>
            {/* </Grid> */}
          </Box>
        </TabPanel>

        <div style={{ position: "relative" }}>
          <TabPanel value={value} index={0}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid>
              <Grid>
                  <Typography variant="h5" gutterBottom>
                    Team name
                  </Typography>
                  <Typography>{team_name}</Typography>
                </Grid>
                <Grid>
                  <Typography variant="h5" gutterBottom>
                    Team captain
                  </Typography>
                  <Typography>{team_captain}</Typography>
                </Grid>
                <Grid>
                  <Typography variant="h5" gutterBottom>
                    Project idea name
                  </Typography>
                  <Typography>{stem_project_idea}</Typography>
                </Grid>
                <Grid>
                  <Typography variant="h5" gutterBottom>
                    Project idea Description
                  </Typography>
                  <Typography>{project_descripition}</Typography>
                </Grid>
                <Grid
                  style={{
                    position: "absolute",
                    top: "25%",
                    left: "80%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Button onClick={handleEditClick}>
                    <TeamEdit
                    team_captain={team_captain}
                      team_name={team_name}
                      stem_project_idea={stem_project_idea}
                      project_descripition={project_descripition}
                      getIsplDetails={getIsplDetails}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        </div>

        <TabPanel value={value} index={2}>
          Coming soon
        </TabPanel>
        <TabPanel value={value} index={3}>
          {/* Upload Your Team Video here.. */}
          <VideoUpload />
        </TabPanel>
      </Box>
    </Grid>
  );
}
