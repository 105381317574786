// import React, { useState, useRef, useEffect } from 'react';
// import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, TextField } from '@mui/material';
// import { HOST_URL } from "../../action/api";
// import { TOAST_LOADING, TOAST_SUCCESS, TOAST_WARN } from "../../utils/index";
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import { Typography } from '@material-ui/core';
// import AWS from 'aws-sdk';

// const region = 'US East (N. Virginia) us-east-1'; // Update with your region
// const accessKeyId = 'AKIASJIIGJEQXVFQEKYR'; // Update with your access key
// const secretAccessKey = '8g0STksj67/7KmfP/uOfxdgLjDzsGEnzk86XXe4o'; // Update with your secret access key
// const bucketName = 'studentvideo'; // Update with your bucket name

// const s3 = new AWS.S3({
//   region,
//   accessKeyId,
//   secretAccessKey
// });

// const UploadTaskVideo = ({ task }) => {
//   const fileInput = useRef(null);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [uploadedVideos, setUploadedVideos] = useState([]);
//   const [mediaTitle, setMediaTitle] = useState('');
//   const [mediaDescription, setMediaDescription] = useState('');

//   useEffect(() => {
//     fetchUploadedVideos(task.id); // Pass task ID to fetchUploadedVideos
//   }, [task]);

//   const fetchUploadedVideos = (taskId) => {
//     const token = localStorage.getItem("token");
//     const requestOptions = {
//       method: 'GET',
//       headers: {
//         'Authorization': token
//       },
//     };

//     fetch(`${HOST_URL}student/media/${taskId}/`, requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         console.log(result);
//         if (result.success) {
//           const videosWithSignedUrls = result.data.videos.map(video => {
//             const params = {
//               Bucket: bucketName,
//               Key: video.s3_urls // Assuming 's3_urls' is the key for the video URL
//             };
//             const signedUrl = s3.getSignedUrl('getObject', params); // Get the signed URL for the video
//             console.log("signedUrl", signedUrl)
//             return {
//               ...video,
//               signedUrl: signedUrl // Add the signed URL to the video object
//             };
//           });
//           setUploadedVideos(videosWithSignedUrls);
//         } else {
//           TOAST_WARN(result.message);
//         }
//       })
//       .catch(error => {
//         TOAST_WARN("Something went wrong");
//         console.log('error', error);
//       });
//   };

//   const handleFileUpload = (event) => {
//     const files = event.target.files;
//     const updatedVideos = [];

//     for (let i = 0; i < files.length; i++) {
//       const video = URL.createObjectURL(files[i]);
//       updatedVideos.push(video);
//     }

//     setUploadedVideos((prevVideos) => [...prevVideos, ...updatedVideos]);
//   };

//   const handleSubmit = () => {
//     if (mediaTitle.trim() === '') {
//       TOAST_WARN("Please fill in the media title.");
//       return;
//     }
//     TOAST_LOADING("Please wait...");

//     const token = localStorage.getItem("token");

//     var myHeaders = new Headers();
//     myHeaders.append("Authorization", token);

//     var formdata = new FormData();
//     formdata.append("media_title", mediaTitle);

//     if (fileInput.current.files && fileInput.current.files.length > 0) {
//       formdata.append("media_files", fileInput.current.files[0]);
//     } else {
//       TOAST_WARN("Please select a file.");
//       return;
//     }

//     formdata.append("media_description", mediaDescription);
//     formdata.append("task_id", task.id);

//     var requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: formdata,
//       redirect: 'follow'
//     };

//     fetch(`${HOST_URL}student/media`, requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         console.log(result);
//         if (result.success) {
//           TOAST_SUCCESS(result.message);
//           setMediaTitle('');
//           setMediaDescription('');
//           setUploadedVideos([]);
//           fetchUploadedVideos(task.id);
//         } else {
//           TOAST_WARN(result.message);
//         }
//       })
//       .catch(error => {
//         TOAST_WARN("Something went wrong");
//         console.log('error', error);
//       });
//   };

//   const handleDialogClose = () => {
//     setOpenDialog(false);
//   };

//   const handleDialogOpen = () => {
//     setOpenDialog(true);
//   };

//   return (
//     <div>
//       <Box m={2}>
//         <Button variant="contained" onClick={handleDialogOpen}>
//           Upload Videos
//         </Button>
//       </Box>

//       <Grid container spacing={2}>
//         {uploadedVideos.map((video, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Typography>{video.media_title}</Typography>
//             <br />
//             <video width="100%" controls>
//               <source src={video.signedUrl} type="video/mp4" />
//             </video>
//           </Grid>
//         ))}
//       </Grid>

//       <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="md">
//         <DialogTitle>Upload Videos</DialogTitle>
//         <DialogContent sx={{ padding: '24px' }}>
//           <Box mb={2}>
//             <TextField
//               required
//               label="Media Title"
//               value={mediaTitle}
//               onChange={(e) => setMediaTitle(e.target.value)}
//               fullWidth
//               InputLabelProps={{ style: { color: '#4a94d0' } }}
//             />
//           </Box>
//           <Box mb={2}>
//             <TextField
//               label="Media Description"
//               value={mediaDescription}
//               onChange={(e) => setMediaDescription(e.target.value)}
//               fullWidth
//               multiline
//               rows={4}
//               InputLabelProps={{ style: { color: '#4a94d0' } }}
//             />
//           </Box>
//           <Grid item xs={12} md={4} ml={2} mt={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "auto", borderRadius: "4px", border: "2px dashed blue", padding: "5px 20px 0px 0px", textAlign: "center", order: { xs: 2, md: 1 } }}>
//             {/* <label htmlFor="video-upload-input">
//               <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
//                 Upload Video
//               </Button>
//             </label> */}
//             <input
//               ref={fileInput}
//               type="file"
//               name="media_files"
//               id="video-upload-input"
//               accept="video/*"
//               multiple
//               onChange={handleFileUpload}
//             // style={{ display: 'none' }}
//             />
//           </Grid>
//         </DialogContent>
//         <DialogActions sx={{ padding: '24px' }}>
//           <Button onClick={handleDialogClose} style={{ border: '1px solid red' }}>Cancel</Button>
//           <Button onClick={handleSubmit} style={{ border: '1px solid red' }}>Submit</Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }

// export default UploadTaskVideo;




import React, { useState, useRef, useEffect } from 'react';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, TextField } from '@mui/material';
import { HOST_URL } from "../../action/api";
import { TOAST_LOADING, TOAST_SUCCESS, TOAST_WARN } from "../../utils/index";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Typography } from '@material-ui/core';

const UploadTaskVideo = ({ task }) => {
  const fileInput = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [mediaTitle, setMediaTitle] = useState('');
  const [mediaDescription, setMediaDescription] = useState('');
  const [taskId, setTaskId] = useState('');

  useEffect(() => {
    fetchUploadedVideos(task.id); // Pass task ID to fetchUploadedVideos
  }, [task]);

  const fetchUploadedVideos = (taskId) => {
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': token
      },
    };

    fetch(`${HOST_URL}student/media/${taskId}/`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          setUploadedVideos(result.data.videos);
        } else {
          TOAST_WARN(result.message);
        }
      })
      .catch(error => {
        TOAST_WARN("Something went wrong");
        console.log('error', error);
      });
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const updatedVideos = [];

    for (let i = 0; i < files.length; i++) {
      const video = URL.createObjectURL(files[i]);
      updatedVideos.push(video);
    }

    setUploadedVideos((prevVideos) => [...prevVideos, ...updatedVideos]);
  };

  const handleSubmit = () => {
    if (mediaTitle.trim() === '') {
      TOAST_WARN("Please fill in the media title.");
      return;
    }
    TOAST_LOADING("Please wait...");

    const token = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var formdata = new FormData();
    formdata.append("media_title", mediaTitle);

    if (fileInput.current.files && fileInput.current.files.length > 0) {
      formdata.append("media_files", fileInput.current.files[0]);
    } else {
      TOAST_WARN("Please select a file.");
      return;
    }

    formdata.append("media_description", mediaDescription);
    formdata.append("task_id", task.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${HOST_URL}student/media`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          TOAST_SUCCESS(result.message);
          setMediaTitle('');
          setMediaDescription('');
          setUploadedVideos([]);
          fetchUploadedVideos(task.id);
        } else {
          TOAST_WARN(result.message);
        }
      })
      .catch(error => {
        TOAST_WARN("Something went wrong");
        console.log('error', error);
      });
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  return (
    <div>
      <Box m={2}>
        <Button variant="contained" onClick={handleDialogOpen}>
          Upload Videos
        </Button>
      </Box>

      <Grid container spacing={2}>
        {uploadedVideos.map((video, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Typography>{video.media_title}</Typography>
            <br />
            <video width="100%" controls >
              <source src={video.s3_urls} type="video/mp4"/>
            </video>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="md">
        <DialogTitle>Upload Videos</DialogTitle>
        <DialogContent sx={{ padding: '24px' }}>
          <Box mb={2}>
            <TextField
              required
              label="Media Title"
              value={mediaTitle}
              onChange={(e) => setMediaTitle(e.target.value)}
              fullWidth
              InputLabelProps={{ style: { color: '#4a94d0' } }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Media Description"
              value={mediaDescription}
              onChange={(e) => setMediaDescription(e.target.value)}
              fullWidth
              multiline
              rows={4}
              InputLabelProps={{ style: { color: '#4a94d0' } }}
            />
          </Box>
          <Grid item xs={12} md={4} ml={2} mt={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "auto", borderRadius: "4px", border: "2px dashed blue", padding: "5px 20px 0px 0px", textAlign: "center", order: { xs: 2, md: 1 } }}>
            {/* <label htmlFor="video-upload-input">
              <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
                Upload Video
              </Button>
            </label> */}
            <input
              ref={fileInput}
              type="file"
              name="media_files"
              id="video-upload-input"
              accept="video/*"
              multiple
              onChange={handleFileUpload}
            // style={{ display: 'none' }}
            />
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: '24px' }}>
          <Button onClick={handleDialogClose} style={{ border: '1px solid black' }}>Cancel</Button>
          <Button onClick={handleSubmit} style={{ border: '1px solid black' }}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UploadTaskVideo;


// import React, { useState } from 'react';
// import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
// import { useEffect } from 'react';

// const UploadTaskVideo = () => {
//     const [openDialog, setOpenDialog] = useState(false);
//     const [uploadedVideos, setUploadedVideos] = useState([]);

//     useEffect(() => {
//         // Retrieve uploaded videos from localStorage on component mount
//         const storedVideos = localStorage.getItem('uploadedVideos');
//         if (storedVideos) {
//             setUploadedVideos(JSON.parse(storedVideos));
//         }
//     }, []);

//     useEffect(() => {
//         // Save uploaded videos to localStorage whenever it changes
//         localStorage.setItem('uploadedVideos', JSON.stringify(uploadedVideos));
//     }, [uploadedVideos]);

//     const handleFileUpload = (event) => {
//         const files = event.target.files;
//         const updatedVideos = [];

//         for (let i = 0; i < files.length; i++) {
//             const video = URL.createObjectURL(files[i]);
//             updatedVideos.push(video);
//         }

//         setUploadedVideos((prevVideos) => [...prevVideos, ...updatedVideos]);
//         setOpenDialog(false);
//     };

//     return (
//         <div>
//             <Box m={2}>
//                 <Button variant="contained" onClick={() => setOpenDialog(true)}>
//                     Upload Videos
//                 </Button>
//             </Box>

//             <Grid container spacing={2}>
//                 {uploadedVideos.map((video, index) => (
//                     <Grid item xs={12} sm={6} md={4} key={index}>
//                         <video width="100%" controls>
//                             <source src={video} type="video/mp4" />
//                         </video>
//                     </Grid>
//                 ))}
//             </Grid>

//             <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
//                 <DialogTitle>Upload Videos</DialogTitle>
//                 <DialogContent>
//                     <input type="file" accept="video/*" multiple onChange={handleFileUpload} />
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
//                 </DialogActions>
//             </Dialog>
//         </div>
//     );
// };

// export default UploadTaskVideo

