import allInfo from "./allinfo";
import videoUploadReducer from "./videoUploadReducer";
import profileData from "./profileData"

const reducers = () => {
  return {
    allInfo: allInfo,
    videoUpload: videoUploadReducer,
    profileData: profileData
  };
};

export default reducers;
