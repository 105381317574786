export const Is_Live = process.env.REACT_APP_ENV=="development" ? false : true;
export const HOST_URL = Is_Live
  ? "https://ilearnpoint.com/ilpapi/"
  : "http://65.0.178.227:80/ilpapi/";
  // : "http://13.234.67.50:80/ilpapi/";
export const RegistrationApi = `${HOST_URL}ispl/team/registration`;
export const AddStudentApi = `${HOST_URL}ispl/student/add`;
export const AddressApi = `${HOST_URL}ispl/student/address`;
export const PaymentApi = `${HOST_URL}ispl/team/payment`;

export const ClubMember = `http://13.234.67.50:80//clubapi/club/details/data`;

export const postClubDetails = `http://13.234.67.50:80/clubapi/Institute/club/registration`;
export const postClubExcutive = `http://13.234.67.50:80/clubapi/Institute/Executive_admin/registration`;
// export const postClubMember = `http://15.206.189.238:8000/clubapi/club/add_member/registration`;
// "https://ilearnpoint.com/"
// "https://ilearnplace.in/"

// "http://15.206.189.238:8000/ilpapi/student/payment"
export const StudentPaymentApi = `${HOST_URL}student/payment`;

export const Team_Media = `${HOST_URL}ispl/team/media`;
export const PROFIEL_DATA = `${HOST_URL}create/student`; //put get delete
export const EDIT_PROFILE = `${HOST_URL}create/student`;


// export const IsplPaymentApi = `${HOST_URL}ispl/team/payment`;