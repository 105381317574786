import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        maxWidth: '100%',
        marginTop: '20px'
    },
    AppBar: {
        justifyContent: 'center',
        backgroundColor: '#272a33'
    }
});

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function ShippingDeliveryPolicy(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className={classes.root}>
                <CssBaseline />
                <HideOnScroll {...props}>
                    <AppBar >
                        <Toolbar className={classes.AppBar}>
                            <Typography variant="h6">SHIPPING & DELIVERY POLICY</Typography>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <Toolbar />
                <Container maxWidth="md">



                    <Typography variant="body2" gutterBottom> Welcome to Almondai Technologies  Private Limited (“Ilearnplace”). If you continue to browse and use this
                        website you are agreeing to comply with and be bound by the following terms and conditions of use, which
                        together with our privacy policy govern Ilearnplace relationship with you in relation to this website.</Typography>
                    <Typography variant="body2" gutterBottom>
                        <b>Minimum delivery timeline:</b>  3 working days <br />
                        <b>Maximum delivery timeline:</b>  15 working days
                    </Typography>
                    <Typography variant="body2" gutterBottom> For domestic buyers, orders are shipped through BlueDart,
                        DTDC or speed post only. Orders will be shipped through DHL, FEDEX or other reliable shippers.
                        Orders will be shipped within <b>3 working days</b>, if stocks are available or as per the delivery
                        date agreed at the time of order confirmation.</Typography>

                    <Typography variant="body2" gutterBottom> Almondai Group - Ilearnplace  is not liable for any delay
                        due to custom processing, weather condition or due to any other reason in delivery by the courier
                        company / postal authorities and only guarantees to hand over the consignment to the courier company
                        or postal authorities within <b>3 working days</b> from the date of the order and payment or as per the
                        delivery date agreed at the time of order confirmation.</Typography>

                    <Typography variant="body2" gutterBottom> Delivery of all orders will be to registered address of the
                        buyer as per the information provided at the time of placement of order. Almondai Group - Ilearnplace
                        is in no way responsible for any damage to the order while in transit to the buyer.</Typography>

                    <Typography variant="body2" gutterBottom> As per Government of India regulations, batteries cannot be
                        shipped by air. All the products contain batteries and hence will only be transported by land.</Typography>

                    <Typography variant="body2" gutterBottom> Delivery of our services will be confirmed on your mail ID as
                        specified during registration. For any issues in utilizing our services you may contact our helpdesk
                        on 809 441 4990 / +91 141 400 4597 / info@ilearnplace.com.</Typography>

                    <b>IMPORTANT:</b>
                    <Typography variant="body2" gutterBottom>
                        Generally our web shop predicts accurate shipping charges but in case of any problem or errors we
                        may re-calculate shipping charges and will only dispatch such orders after acceptance from you.
                        If you are not accepting the shipping charges, you will get full refund.
                        Almondai Group - Ilearnplace  is not responsible for any extra charges once the original package
                        has been shipped. If the customer refuses to pay these extra charges, the return shipping and any
                        additional fees will be taken out of the cost of the order, and any remaining funds will be refunded
                        to the customer.
                        <b>SHIPPING AND DELIVERY TO OVERSEAS CUSTOMERS:</b></Typography>

                    <Typography variant="body2" gutterBottom>
                        The kits and products shipped to overseas customers i.e. customers based outside the territory of
                        the Republic of India shall not include or contain batteries. Overseas customers can refer to our
                        online portal where we have provided details of alternates to be used to operate the kits and products
                        including specifications of batteries to be purchased to operate the said kits and products.
                        Almondai Group - Ilearnplace  is not liable for any delay due to custom processing, weather condition
                        or due to any other reason in delivery by the courier company / postal authorities and only guarantees
                        to hand over the consignment to the courier company or postal authorities within <b>3 working days</b> from the
                        date of the order and payment or as per the delivery date agreed at the time of order confirmation.
                        Any product purchased from our stores may attract custom charges as per the rules of your country and
                        Almondai Group - Ilearnplace  is not liable for any such payments or refund due to refusal of acceptance
                        of the parcel by the customer.</Typography>
                </Container>
            </div>
        </React.Fragment>
    );
}




//                 </Typography>
//             </Container>
//         </React.Fragment>
//     );
// }
