import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import OurPartnerBox from "./ourpartner-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner1: [
        {
          image: "assets/images/partner/nvidia2.png",
          title: "Nvidia",
        },
        {
          image: "assets/images/partner/roboindia.png",
          title: "Robo India",
        },
      ]
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className={"section " + this.props.sectionClass} id="partners">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Our Partner"
            // desc="partner descriptions pandding............"
            />

            <Row className="">
              {/* services box */}
              <OurPartnerBox partners={this.state.partner1} />
            </Row>

          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
