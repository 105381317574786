import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useEffect } from "react";
import { Avatar, Button, Grid, Paper, Typography } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import club from "../assets/club.jpg";
import { Route, useHistory } from "react-router-dom";
import HorizontalLinearStepper from "./Stepper";
import ClubDetails from "./ClubDetails";
import ExecutiveMembers from "./ExcutiveMembers";
import StudentMembers from "./StudentMembers";
import ClubRules from "./ClubRules";
import ClubActivites from "./ClubActivites";
import { onClubData } from "../../action/islpAction";
import Test from "./Test";

// import Paytm from '../../pages/NewIslp/Registration/paytm';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function ClubDashboard() {
    const history = useHistory()
    let [team_name, setTeam_name] = useState("");
    const [value, setValue] = useState(0);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [data, setData] = useState([])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        var token = localStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        // myHeaders.append("IlpClubId", token);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        onClubData(requestOptions).then(res => res.json().then(data => setData(data.data)))
    }, [])
    const handleClick = () => {
        history.push('/dashboard/club/registration'); // replace '/my-app' with the path of the page you want to open
        setButtonClicked(true);
    }

    return (
        <Grid>
            <Grid
                style={{
                    backgroundImage: `url(${club})`,
                    marginBottom: "50px",
                    display: "flex",
                    //   justifyContent: "flex-start",
                    //   alignItems: "flex-end",
                    height: "720px",
                    padding: "10px",
                    borderRadius: "10px",
                    backgroundSize: "cover",
                }}
            >
            </Grid>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                    >
                        <Tab label="ILP Club Details" {...a11yProps(0)} />
                        <Tab label="Excutive Members" {...a11yProps(1)} />
                        <Tab label="Student Members" {...a11yProps(2)} />
                        <Tab label="Club Rules" {...a11yProps(3)} />
                        <Tab label="Club Activites" {...a11yProps(4)} />
                        <Tab label="Lab Inventries" {...a11yProps(5)} />
                        <Tab label="Test" {...a11yProps(6)} />
                        <Tab label="Club Registration" {...a11yProps(7)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Box>
                        <Grid>
                            <ClubDetails />
                        </Grid>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid>
                            <ExecutiveMembers />
                        </Grid>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Box>
                        <Grid>
                            <StudentMembers />
                        </Grid>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Box>
                        <Grid>
                            <ClubRules />
                        </Grid>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Box>
                        <Grid>
                            <ClubActivites />
                        </Grid>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={5}>
                    Coming soon
                </TabPanel>
                <TabPanel value={value} index={6}>
                    <Box>
                        <Test />
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={7}>
                    <Box sx={{ flexGrow: 1 }}>
                        <div>
                            <Route
                                path="/dashboard/club/registration"
                                // component={HorizontalLinearStepper}
                                render={(props) => (<HorizontalLinearStepper data={data} />)}
                            />
                        </div>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} sm={6} md={4}>
                                {!buttonClicked &&
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        sx={{ bgcolor: "#24346c" }}
                                        onClick={handleClick}
                                    >
                                        Club-Registration
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </TabPanel>
            </Box>
        </Grid>
    );
}
