import React, { useState } from "react";
import { Button, Card, CardContent, FormControl, FormControlLabel, Grid, LinearProgress, Radio, RadioGroup, Typography } from "@mui/material";

const questionsData = {
  questions: [
    {
      question: "What is the capital of France?",
      options: ["A. London", "B. Paris", "C. Berlin", "D. Rome"],
      correctAnswer: 1, // Index of the correct answer (starting from 0)
    },
    {
      question: "Which planet is known as the Red Planet?",
      options: ["A. Mars", "B. Jupiter", "C. Venus", "D. Saturn"],
      correctAnswer: 0,
    },
    {
      question: "Who painted the Mona Lisa?",
      options: ["A. Pablo Picasso", "B. Leonardo da Vinci", "C. Vincent van Gogh", "D. Michelangelo"],
      correctAnswer: 1,
    },
    {
      question: "Which animal is known as the 'King of the Jungle'?",
      options: ["A. Lion", "B. Tiger", "C. Elephant", "D. Giraffe"],
      correctAnswer: 0,
    },
    {
      question: "Which country won the FIFA World Cup in 2018?",
      options: ["A. Brazil", "B. Germany", "C. France", "D. Spain"],
      correctAnswer: 2,
    },
  ],
};

function Test() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(0); // New state variable for correct answers
  const currentQuestion = questionsData.questions[currentQuestionIndex];
  const totalQuestions = questionsData.questions.length;

  const handleNext = () => {
    if (selectedAnswer !== null) {
      if (selectedAnswer === currentQuestion.correctAnswer) {
        setCorrectAnswers(correctAnswers + 1); // Increment the correctAnswers count
      }

      if (currentQuestionIndex < totalQuestions - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedAnswer(null);
      } else {
        // End of the questions
      }
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedAnswer(null);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedAnswer(parseInt(event.target.value, 10));
  };

  const isOptionSelected = (optionIndex) => {
    return selectedAnswer === optionIndex;
  };

  const isOptionCorrect = (optionIndex) => {
    return selectedAnswer === optionIndex && selectedAnswer === currentQuestion.correctAnswer;
  };

  return (
    <Card>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">Question {currentQuestionIndex + 1}</Typography>
          </Grid>
          <Grid item>
            <LinearProgress variant="determinate" value={((currentQuestionIndex + 1) / totalQuestions) * 100} />
          </Grid>
          <Grid item>
            <Typography variant="body1">{currentQuestion.question}</Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup value={selectedAnswer === null ? "" : selectedAnswer.toString()} onChange={handleOptionChange}>
                {currentQuestion.options.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={index.toString()}
                    control={<Radio color="primary" />}
                    label={option}
                    disabled={selectedAnswer !== null}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Button onClick={handleBack} variant="contained" color="secondary" disabled={currentQuestionIndex === 0}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleNext}
                variant="contained"
                color="primary"
                disabled={selectedAnswer === null}
              >
                {currentQuestionIndex === totalQuestions - 1 ? "Finish" : "Next"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Test;
