import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
// import Form from './Form';
import { TOAST_LOADING, TOAST_SUCCESS, TOAST_WARN } from "../../utils/index";
import { onPostClubDetails, onPostClubExcutive } from '../../action/islpAction';
import validator from 'validator';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    formField: {
        marginBottom: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },
}));

const Step2Form = ({ clubID, data }) => {
    const classes = useStyles();

    const [contactError, setContactError] = useState(false);
    const [chairmanName, setChairmanName] = useState('');
    const [chairmanContact, setChairmanContact] = useState('');
    const [chairmanEmail, setChairmanEmail] = useState('');
    const [chairmanGender, setChairmanGender] = useState('');
    const [treasurerName, setTreasurerName] = useState('');
    const [treasurerContact, setTreasurerContact] = useState('');
    const [treasurerEmail, setTreasurerEmail] = useState('');
    const [treasurerGender, setTreasurerGender] = useState('');
    const [viceChairmanName, setViceChairmanName] = useState('');
    const [viceChairmanContact, setViceChairmanContact] = useState('');
    const [viceChairmanEmail, setViceChairmanEmail] = useState('');
    const [viceChairmanGender, setViceChairmanGender] = useState('');
    const [secretaryName, setSecretaryName] = useState('');
    const [secretaryContact, setSecretaryContact] = useState('');
    const [secretaryEmail, setSecretaryEmail] = useState('');
    const [secretaryGender, setSecretaryGender] = useState('');
    const [stemMentorName, setStemMentorName] = useState('');
    const [stemMentorContact, setStemMentorContact] = useState('');
    const [stemMentorEmail, setStemMentorEmail] = useState('');
    const [stemMentorGender, setStemMentorGender] = useState('');
    const [membershipExecutiveName, setMembershipExecutiveName] = useState('');
    const [membershipExecutiveContact, setMembershipExecutiveContact] = useState('');
    const [membershipExecutiveEmail, setMembershipExecutiveEmail] = useState('');
    const [membershipExecutiveGender, setMembershipExecutiveGender] = useState('');
    const [labExecutiveName, setLabExecutiveName] = useState('');
    const [labExecutiveContact, setLabExecutiveContact] = useState('');
    const [labExecutiveEmail, setLabExecutiveEmail] = useState('');
    const [labExecutiveGender, setLabExecutiveGender] = useState('');

    useEffect(() => {
        if (data && data.club_executive_member && data.club_executive_member.length > 0) {
            if (data.club_executive_member[0]) {
                setChairmanName(data.club_executive_member[0]?.name || '');
                setChairmanContact(data.club_executive_member[0]?.contact || '');
                setChairmanEmail(data.club_executive_member[0]?.email || '');
                setChairmanGender(data.club_executive_member[0]?.email || 'male');
            } else {
                setChairmanGender('male');
            }

            if (data.club_executive_member[1]) {
                setViceChairmanName(data.club_executive_member[1]?.name || '');
                setViceChairmanContact(data.club_executive_member[1]?.contact || '');
                setViceChairmanEmail(data.club_executive_member[1]?.email || '');
                setViceChairmanGender(data.club_executive_member[1]?.gender || 'male');
            }

            if (data.club_executive_member[2]) {
                setTreasurerName(data.club_executive_member[2]?.name || '');
                setTreasurerContact(data.club_executive_member[2]?.contact || '');
                setTreasurerEmail(data.club_executive_member[2]?.email || '');
                setTreasurerGender(data.club_executive_member[2]?.gender || 'male');
            }

            if (data.club_executive_member[3]) {
                setSecretaryName(data.club_executive_member[3]?.name || '');
                setSecretaryContact(data.club_executive_member[3]?.contact || '');
                setSecretaryEmail(data.club_executive_member[3]?.email || '');
                setSecretaryGender(data.club_executive_member[3]?.gender || 'male');
            }

            if (data.club_executive_member[4]) {
                setStemMentorName(data.club_executive_member[4]?.name || '');
                setStemMentorContact(data.club_executive_member[4]?.contact || '');
                setStemMentorEmail(data.club_executive_member[4]?.email || '');
                setStemMentorGender(data.club_executive_member[4]?.gender || 'male');
            }

            if (data.club_executive_member[5]) {
                setMembershipExecutiveName(data.club_executive_member[5]?.name || '');
                setMembershipExecutiveContact(data.club_executive_member[5]?.contact || '');
                setMembershipExecutiveEmail(data.club_executive_member[5]?.email || '');
                setMembershipExecutiveGender(data.club_executive_member[5]?.gender || 'male');
            }

            if (data.club_executive_member[6]) {
                setLabExecutiveName(data.club_executive_member[6]?.name || '');
                setLabExecutiveContact(data.club_executive_member[6]?.contact || '');
                setLabExecutiveEmail(data.club_executive_member[6]?.email || '');
                setLabExecutiveGender(data.club_executive_member[6]?.gender || 'male');
            }
        }
    }, [data?.club_executive_member]);

    const [apiResponse, setApiResponse] = useState(null);
    const [error, setError] = useState(null);

    // console.log("inside handle submit", props)
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!chairmanName || !chairmanContact || !chairmanEmail || !chairmanGender || !viceChairmanName || !viceChairmanContact || !viceChairmanEmail || !viceChairmanGender || !stemMentorName || !stemMentorEmail || !stemMentorContact || !stemMentorGender || !membershipExecutiveName || !membershipExecutiveEmail || !membershipExecutiveContact || !membershipExecutiveGender || !labExecutiveName || !labExecutiveEmail || !labExecutiveContact || !labExecutiveGender || !labExecutiveName) {
            TOAST_WARN("please fill required details.");
            return false;
        }
        else {
            const isValidEmail = validator.isEmail(chairmanEmail) && validator.isEmail(viceChairmanEmail) && validator.isEmail(stemMentorEmail) && validator.isEmail(membershipExecutiveEmail) && validator.isEmail(labExecutiveEmail) && validator.isEmail(secretaryEmail) && validator.isEmail(treasurerEmail);

            if (!isValidEmail) {
                TOAST_WARN("Please enter valid email addresses.");
                return false;
            }
            TOAST_LOADING("please wait...");
            var token = localStorage.getItem("token");
            // console.log("inside handle submit------------------------", props)
            let payload = {
                // club_id: props.clubID,
                // chairman: {
                //     name: "chairmanName",
                //     contact: 2882822,
                //     email: "chairmanEmail",
                //     gender: "chairmanGender",
                //     designation: "chairman",
                // },
                // 'treasurer': {
                //     'name': "treasurerName",
                //     'contact': 228182882,
                //     'email': "treasurerEmail",
                //     'gender': "treasurerGender",
                //     'designation': 'treasurer'
                // },
                // 'vice_chairman': {
                //     'name': "viceChairmanName",
                //     'contact': 9928282777,
                //     'email': "viceChairmanEmail",
                //     'gender': "viceChairmanGender",
                //     'designation': 'vice_chairman'
                // },
                // "secretary": {
                //     "name": "secretaryName",
                //     "contact": 3566532532,
                //     "email": "secretaryEmail",
                //     "gender": "secretaryGender",
                //     "designation": "secretary"
                // },
                // "stem_mentor": {
                //     "name": "stemMentorName",
                //     "contact": 67527536,
                //     "email": "stemMentorEmail",
                //     "gender": "stemMentorGender",
                //     "designation": "stem_mentor"
                // },
                // "membership_executive": {
                //     "name": "membershipExecutiveName",
                //     "contact": 987373788,
                //     "email": "membershipExecutiveEmail",
                //     "gender": "membershipExecutiveGender",
                //     "designation": "membership_executive"
                // },
                // "lab_executive": {
                //     "name": "labExecutiveName",
                //     "contact": 82873636,
                //     "email": "labExecutiveEmail",
                //     "gender": "labExecutiveGender",
                //     "designation": "lab_executive"
                // },

                club_id: clubID,
                chairman: {
                    name: chairmanName,
                    contact: chairmanContact,
                    email: chairmanEmail,
                    gender: chairmanGender,
                    "class_semester": "48",
                    designation: "chairman",
                },
                'treasurer': {
                    'name': treasurerName,
                    'contact': treasurerContact,
                    'email': treasurerEmail,
                    'gender': treasurerGender,
                    "class_semester": "48",
                    'designation': 'treasurer'
                },
                'vice_chairman': {
                    'name': viceChairmanName,
                    'contact': viceChairmanContact,
                    'email': viceChairmanEmail,
                    'gender': viceChairmanGender,
                    "class_semester": "48",
                    'designation': 'vice_chairman'
                },
                "secretary": {
                    "name": secretaryName,
                    "contact": secretaryContact,
                    "email": secretaryEmail,
                    "gender": secretaryGender,
                    "class_semester": "48",
                    "designation": "secretary"
                },
                "stem_mentor": {
                    "name": stemMentorName,
                    "contact": stemMentorContact,
                    "email": stemMentorEmail,
                    "gender": stemMentorGender,
                    "class_semester": "48",
                    "designation": "stem_mentor"
                },
                "membership_executive": {
                    "name": membershipExecutiveName,
                    "contact": membershipExecutiveContact,
                    "email": membershipExecutiveEmail,
                    "gender": membershipExecutiveGender,
                    "class_semester": "48",
                    "designation": "membership_executive"
                },
                "lab_executive": {
                    "name": labExecutiveName,
                    "contact": labExecutiveContact,
                    "email": labExecutiveEmail,
                    "gender": labExecutiveGender,
                    "class_semester": "48",
                    "designation": "lab_executive"
                }
                // similarly add other fields
            }
            console.log("payload", payload)
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
                redirect: "follow",
            };
            fetch(
                "http://15.206.189.238:8000/clubapi/Institute/Executive_admin/registration",
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => {
                    setApiResponse(result);
                    // TOAST_SUCCESS("Club Excutive Member Add successfully.");
                    // console.log(result)
                    if (result.success) {
                        TOAST_SUCCESS(result.message);
                    } else {
                        TOAST_WARN(result.message);
                    }
                })
                .catch((error) => {
                    setError(error);
                    TOAST_WARN("something went wrong");
                    console.log("error", error)
                });
        };
    }

    return (
        <React.Fragment>

            <Typography variant="h6" gutterBottom></Typography>
            <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
                Chairmain
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        value={chairmanName}
                        onChange={(e) => setChairmanName(e.target.value)}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel
                            required
                            id="demo-simple-select-label"
                        >
                            Gender
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={chairmanGender}
                            onChange={(e) => setChairmanGender(e.target.value)}
                            label="Choose ILP STEM Game"
                            fullWidth
                        >
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>
                                Female
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="contact"
                        name="contact"
                        label="Contact"
                        value={chairmanContact}
                        // onChange={(e) => setChairmanContact(e.target.value)}
                        fullWidth
                        autoComplete="family-name"
                        variant="outlined"
                        helperText={
                            contactError
                                ? "Please enter a 10-digit phone number (numbers only)"
                                : ""
                        }
                        InputProps={{
                            inputProps: {
                                maxLength: 10,
                            },
                        }}
                        onChange={(e) => {
                            setChairmanContact(e.target.value.replace(/[^0-9]/g, ""));
                            setContactError(false);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        value={chairmanEmail}
                        onChange={(e) => setChairmanEmail(e.target.value)}
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="class/semester"
                        name="class/semester"
                        label="Class/Semester"
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom></Typography>
            <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
                Vice Chairmain
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        value={viceChairmanName}
                        onChange={(e) => setViceChairmanName(e.target.value)}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel
                            required
                            id="demo-simple-select-label"
                        >
                            Gender
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={viceChairmanGender}
                            onChange={(e) => setViceChairmanGender(e.target.value)}
                            label="Choose ILP STEM Game"
                            fullWidth
                        >
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>
                                Female
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="contact"
                        name="contact"
                        label="Contact"
                        value={viceChairmanContact}
                        // onChange={(e) => setViceChairmanContact(e.target.value)}
                        fullWidth
                        autoComplete="family-name"
                        variant="outlined"
                        helperText={
                            contactError
                                ? "Please enter a 10-digit phone number (numbers only)"
                                : ""
                        }
                        InputProps={{
                            inputProps: {
                                maxLength: 10,
                            },
                        }}
                        onChange={(e) => {
                            setViceChairmanContact(e.target.value.replace(/[^0-9]/g, ""));
                            setContactError(false);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        value={viceChairmanEmail}
                        onChange={(e) => setViceChairmanEmail(e.target.value)}
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="class/semester"
                        name="class/semester"
                        label="Class/Semester"
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom></Typography>
            <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
                Stem Mentor
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        value={stemMentorName}
                        onChange={(e) => setStemMentorName(e.target.value)}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel
                            required
                            id="demo-simple-select-label"
                        >
                            Gender
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={stemMentorGender}
                            onChange={(e) => setStemMentorGender(e.target.value)}
                            label="Choose ILP STEM Game"
                            fullWidth
                        >
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>
                                Female
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="contact"
                        name="contact"
                        label="Contact"
                        value={stemMentorContact}
                        // onChange={(e) => setStemMentorContact(e.target.value)}
                        fullWidth
                        autoComplete="family-name"
                        variant="outlined"
                        helperText={
                            contactError
                                ? "Please enter a 10-digit phone number (numbers only)"
                                : ""
                        }
                        InputProps={{
                            inputProps: {
                                maxLength: 10,
                            },
                        }}
                        onChange={(e) => {
                            setStemMentorContact(e.target.value.replace(/[^0-9]/g, ""));
                            setContactError(false);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        value={stemMentorEmail}
                        onChange={(e) => setStemMentorEmail(e.target.value)}
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="class/semester"
                        name="class/semester"
                        label="Class/Semester"
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom></Typography>
            <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
                Membership Excutive
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        value={membershipExecutiveName}
                        onChange={(e) => setMembershipExecutiveName(e.target.value)}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel
                            required
                            id="demo-simple-select-label"
                        >
                            Gender
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={membershipExecutiveGender}
                            onChange={(e) => setMembershipExecutiveGender(e.target.value)}
                            label="Choose ILP STEM Game"
                            fullWidth
                        >
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>
                                Female
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="contact"
                        name="contact"
                        label="Contact"
                        value={membershipExecutiveContact}
                        // onChange={(e) => setMembershipExecutiveContact(e.target.value)}
                        fullWidth
                        autoComplete="family-name"
                        variant="outlined"
                        helperText={
                            contactError
                                ? "Please enter a 10-digit phone number (numbers only)"
                                : ""
                        }
                        InputProps={{
                            inputProps: {
                                maxLength: 10,
                            },
                        }}
                        onChange={(e) => {
                            setMembershipExecutiveContact(e.target.value.replace(/[^0-9]/g, ""));
                            setContactError(false);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        value={membershipExecutiveEmail}
                        onChange={(e) => setMembershipExecutiveEmail(e.target.value)}
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="class/semester"
                        name="class/semester"
                        label="Class/Semester"
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom></Typography>
            <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
                Lab Excutive
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        value={labExecutiveName}
                        onChange={(e) => setLabExecutiveName(e.target.value)}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel
                            required
                            id="demo-simple-select-label"
                        >
                            Gender
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={labExecutiveGender}
                            onChange={(e) => setLabExecutiveGender(e.target.value)}
                            label="Choose ILP STEM Game"
                            fullWidth
                        >
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>
                                Female
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="contact"
                        name="contact"
                        label="Contact"
                        value={labExecutiveContact}
                        // onChange={(e) => setLabExecutiveContact(e.target.value)}
                        fullWidth
                        autoComplete="family-name"
                        variant="outlined"
                        helperText={
                            contactError
                                ? "Please enter a 10-digit phone number (numbers only)"
                                : ""
                        }
                        InputProps={{
                            inputProps: {
                                maxLength: 10,
                            },
                        }}
                        onChange={(e) => {
                            setLabExecutiveContact(e.target.value.replace(/[^0-9]/g, ""));
                            setContactError(false);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        value={labExecutiveEmail}
                        onChange={(e) => setLabExecutiveEmail(e.target.value)}
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="class/semester"
                        name="class/semester"
                        label="Class/Semester"
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom></Typography>
            <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
                Secretary (optional)
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        value={secretaryName}
                        onChange={(e) => setSecretaryName(e.target.value)}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel
                            required
                            id="demo-simple-select-label"
                        >
                            Gender
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={secretaryGender}
                            onChange={(e) => setSecretaryGender(e.target.value)}
                            label="Choose ILP STEM Game"
                            fullWidth
                        >
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>
                                Female
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="contact"
                        name="contact"
                        label="Contact"
                        value={secretaryContact}
                        // onChange={(e) => setSecretaryContact(e.target.value)}
                        fullWidth
                        autoComplete="family-name"
                        variant="outlined"
                        helperText={
                            contactError
                                ? "Please enter a 10-digit phone number (numbers only)"
                                : ""
                        }
                        InputProps={{
                            inputProps: {
                                maxLength: 10,
                            },
                        }}
                        onChange={(e) => {
                            setSecretaryContact(e.target.value.replace(/[^0-9]/g, ""));
                            setContactError(false);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        value={secretaryEmail}
                        onChange={(e) => setSecretaryEmail(e.target.value)}
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="class/semester"
                        name="class/semester"
                        label="Class/Semester"
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom></Typography>
            <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
                Treasurer (optional)
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        value={treasurerName}
                        onChange={(e) => setTreasurerName(e.target.value)}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel
                            required
                            id="demo-simple-select-label"
                        >
                            Gender
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={treasurerGender}
                            onChange={(e) => setTreasurerGender(e.target.value)}
                            label="Choose ILP STEM Game"
                            fullWidth
                        >
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>
                                Female
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="contact"
                        name="contact"
                        label="Contact"
                        value={treasurerContact}
                        // onChange={(e) => setTreasurerContact(e.target.value)}
                        fullWidth
                        autoComplete="family-name"
                        variant="outlined"
                        helperText={
                            contactError
                                ? "Please enter a 10-digit phone number (numbers only)"
                                : ""
                        }
                        InputProps={{
                            inputProps: {
                                maxLength: 10,
                            },
                        }}
                        onChange={(e) => {
                            setTreasurerContact(e.target.value.replace(/[^0-9]/g, ""));
                            setContactError(false);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        value={treasurerEmail}
                        onChange={(e) => setTreasurerEmail(e.target.value)}
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="class/semester"
                        name="class/semester"
                        label="Class/Semester"
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Grid>
                <Button className={classes.submitButton} type="submit" color="primary" variant="contained" onClick={handleSubmit}>
                    Save
                </Button>
            </Grid>
        </React.Fragment>
    );
}

export default Step2Form;
