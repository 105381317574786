import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, Typography } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Link, useHistory } from "react-router-dom";
import { HOST_URL } from "../action/api";
import DashboardDescription from "./DashboardDescription";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    "& .MuiStepper-alternativeLabel": {
      backgroundColor: "inherit",
    },
    "& .MuiStepper-root": {
      flexWrap: "wrap",
    },
    "& .MuiButton-contained": {
      backgroundColor: "#11568e",
      color: "#FFFFFF",
    },
  },
  media: {
    height: 140,
    paddingTop: "56.25%",
  },
  cardGrid: {
    display: 'grid',
    gap: theme.spacing(3), // Set the desired gap between cards
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', // Flexible columns
    justifyContent: 'center', // Center the cards horizontally
    // [theme.breakpoints.up('sm')]: {
    //   gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', // Adjust for larger screens
    // },
    // [theme.breakpoints.up('md')]: {
    //   gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))', // Adjust for even larger screens
    // },
    padding: `${theme.spacing(3)}px 0`, // Add vertical padding to maintain space around cards
  },
  
  card: {
    maxWidth: 350,
    minWidth: 270,
    margin: "auto",
    transition: "0.3s",
    background: "transparent",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  lablearro: {
    overflow: "inherite",
    maxWidth: 300,
    margin: "auto",
  },
  content: {
    textAlign: "left",
  },
  heading: {
    fontWeight: "bold",
  },
  subheading: {
    lineHeight: 1.8,
  },
  lockIcon: {
    background: "#11568e",
    color: "#FFFFFF",
    width: "60px",
    height: "60px",
    margin: "auto",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: -155,
  },
  boxBtn: {
    maxWidth: 200,
    minWidth: 270,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
}));

export default function Games(props) {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(0);

  useEffect(() => {
    setDisabled(props.state?.activegame?.length || 0);
  }, [props.state?.activegame]);

  return (
    <div className={classes.root}>
      {/* <Grid container spacing={3}> */}
        <DashboardDescription />
        <div className={classes.cardGrid} style={{marginTop:'3%'}}>
        {props.state?.game?.map((data, index) => (
          <Game key={index} game={{ data, index, disabled }} />
        ))}
        </div>
      {/* </Grid> */}
    </div>
  );
}

function Game(props) {
  const classes = useStyles();
  const history = useHistory();
  const [width, setInnerWidget] = useState(window.innerWidth);

  const fixWidth = () => {
     setInnerWidget(window.innerWidth)
  };

  useEffect(() => {
    window.addEventListener("resize", fixWidth);
    return () => {
      window.removeEventListener("resize", fixWidth);
    };
  }, []);

  const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
    const discountedPrice =
      originalPrice - originalPrice * (discountPercentage / 100);
    return discountedPrice.toFixed(2); // Assuming 2 decimal places for the price
  };

  const { data } = props.game;
  const { gamephoto, order, game_name, game_type, discount } = data;

  return (
    // <Grid item sm={12} md={6} lg={4}>
    <Grid item  lg={4}>
      <Link
        to={{
          pathname: `/dashboard/levels`,
          state: { levels: data, path: props.game },
        }}
      >
        <div className={classes.lablearro}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.media}
              image={`${HOST_URL}${gamephoto}`}
            />

            <CardContent className={classes.content}>
              <Typography
                className={"MuiTypography--heading"}
                variant={"h6"}
                gutterBottom
              >
                {order}
              </Typography>
              <Typography
                className={"MuiTypography--subheading"}
                variant={"caption"}
              >
                {game_name}
              </Typography>
              <Typography>{game_type}</Typography>
              {/* <p variant="h6" sx={{ mr: 1 }}>
                  <span style={{ fontWeight: "bold" }}>Price :</span>
                  <span
                    style={{
                      color: "#6c757d",
                      textDecoration: "line-through",
                      marginRight: "0.5rem",
                    }}
                  >
                    {props.game.data.membership_price}
                  </span>
                  <span style={{ color: "#6c757d", fontWeight: "bold" }}>
                    {calculateDiscountedPrice(
                      props.game.data.membership_price,
                      props.game.data.discount
                    )}{" "}
                    INR
                  </span>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    <span style={{ color: "#6c757d" }}>
                      Save {props.game.data.discount}% with this discount offer!
                    </span>
                  </Typography>
                </p> */}
            </CardContent>
          </Card>
        </div>
      </Link>
    </Grid>
    // </Grid>
  );
}
