
import React from "react";
import { Container, Row } from "reactstrap";
import SectionTitle from "../common/section-title";
import PricingBox from "./pricing-box";

function Pricing() {

  return (
    <React.Fragment>
      <section className="section pt-5" id="games">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Our Products"
            desc=" At ilearnplace we have created a platform that allows 
              students to learn by doing. We have focused on developing a 
              way for students to assist each other in learning. By bringing 
              the classroom into the 21st century, ilearnplace offers an 
              alternative education model and hopes to make a positive impact 
              on the educational system as we know it."
          />
          <Row className="mt-5 pricing">
            {/* pricing box */}
            <PricingBox />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Pricing;


















// import React, { useState, useEffect } from "react";
// import { Container, Row, Col } from "reactstrap";

// //Import Section Title
// import SectionTitle from "../common/section-title";
// import BuyNow from "./buyNow";

// //Import Pricing
// //import PricingBox from "./pricing-box";


// function Pricing() {
//   const [state, setState] = useState({
//     pricings: [
//       {
//         image: "assets/images/team/img-9.jpg",
//         ProductName: "ilp startx",
//         price: 70,
//         ProductType: "robotics",
//         Cetagory: "12-17",
//         Description: "Robotics Kit for cognitive learing",
//       },
//       {
//         image: "assets/images/team/img-6.jpg",
//         ProductName: "ilp cognix",
//         price: 80,
//         ProductType: "robotics",
//         Cetagory: "17-24",
//         Description: "Robotics Kit for cognitive learing",
//       },
//       {
//         image: "assets/images/team/img-7.jpg",
//         ProductName: "ilp anaconda",
//         price: 90,
//         ProductType: "robotics",
//         Cetagory: "24+",
//         Description: "Robotics Kit for cognitive learing",
//       },
//     ],
//   })

//   useEffect(() => {
//     fetch('http://52.66.201.62:5000/product', {
//       method: 'get',
//       mode: 'cors',
//       body: JSON.stringify(),
//     }).then(res => res.json()
//     ).then(data => {
//       console.log('data', data)

//     }
//     ).catch(err => err);
//     return () => {

//     }
//   }, [])
//   console.log('main', state.pricings[2].ProductName);
//   return (
//     <React.Fragment>
//       <section className="section pt-5" id="pricing">

//         <Container>
//           {/* section title */}
//           <SectionTitle
//             title="Our Products"
//             desc=" At ilearnplace we have created a platform that allows 
//               students to learn by doing. We have focused on developing a 
//               way for students to assist each other in learning. By bringing 
//               the classroom into the 21st century, visionrival.com offers an 
//               alternative education model and hopes to make a positive impact 
//               on the educational system as we know it."
//           />
//           <Row className="mt-5">
//             {/* pricing box 1*/}
//             <Col lg={4}>
//               <div className="">
//                 <div className="text-center pricing-box" >
//                 <img src={state.pricings[0].image} width="350px" />
//                   <h4 className="text-uppercase">{state.pricings[0].ProductName}</h4>
//                   <h2>{state.pricings[0].price},000</h2>

//                     <div className="plan-features">
//                       <p >
//                         ProductType : {" "}
//                         <b className="text-secondary">{state.pricings[0].ProductType}</b>
//                       </p>
//                       <p >
//                         Cetagory : {" "}
//                         <b className="text-secondary">{state.pricings[0].Cetagory}</b>
//                       </p>
//                       {/* <p >
//                         Description : {" "}
//                         <b className="text-secondary">{state.pricings[0].Description}</b>
//                       </p> */}
//                     </div>
//                     <BuyNow pricings={state.pricings[0].ProductName} />     
//                 </div>
//               </div>
//             </Col>
//             {/* pricing box 2*/}
//             <Col lg={4}>
//               <div className="">

//                 <div className="text-center pricing-box" >
//                 <img  src={state.pricings[1].image} width="350px" />
//                   <h4 className="text-uppercase">{state.pricings[1].ProductName}</h4>
//                   <h2>{state.pricings[1].price},000</h2>
//                   {/* <div className="pricing-border"></div>      */}
//                     <div className="plan-features ">
//                       <p >
//                         ProductType : {" "}
//                         <b className="text-secondary">{state.pricings[1].ProductType}</b>
//                       </p>
//                       <p >
//                         Cetagory : {" "}
//                         <b className="text-secondary">{state.pricings[1].Cetagory}</b>
//                       </p>
//                       {/* <p >
//                         Description : {" "}
//                         <b className="text-secondary">{state.pricings[1].Description}</b>
//                       </p> */}
//                     </div>
//                     <BuyNow pricings={state.pricings[1].ProductName} />     
//                 </div>
//               </div>
//             </Col>
//             {/* pricing box 3 */}
//             <Col lg={4}>
//               <div className="">
//                 <div className="text-center pricing-box" >
//                 <img src={state.pricings[2].image} width="350px" />
//                   <h4 className="text-uppercase">{state.pricings[2].ProductName}</h4>
//                   <h2>{state.pricings[2].price},000</h2>
//                   {/* <div className="pricing-border"></div>      */}
//                     <div className="plan-features ">
//                       <p >
//                         ProductType : {" "}
//                         <b className="text-secondary">{state.pricings[2].ProductType}</b>
//                       </p>
//                       <p >
//                         Cetagory : {" "}
//                         <b className="text-secondary">{state.pricings[2].Cetagory}</b>
//                       </p>
//                       {/* <p >
//                         Description : {" "}
//                         <b className="text-secondary">{state.pricings[2].Description}</b>
//                       </p> */}
//                     </div>
//                     <BuyNow pricings={state.pricings[2].ProductName} />     
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </React.Fragment>
//   );
// }

// export default Pricing;












