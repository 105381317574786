import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import TestimonialsBox from "./TestimonialsBox";

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [
        {
          image: "assets/images/testimonials/user-2.jpg",
          title: "Sultan",
          subTitle: "CEO Robo India",
          desc:
            "I was so impressed with the iLearnplace. Their STEM learning games are engaging, challenging, and provide hands-on learning experiences that truly inspire creativity and critical thinking. My child loved playing their STEM learning game's Totoka smart city and was excited to share what they learned with me every day.",
        },
        {
          image: "assets/images/testimonials/user-1.jpg",
          title: "Sunil Kumar",
          subTitle: "CEO, Nescode India",
          desc:
            "I was amazed at the progress my child made in just a short period of time. Not only did they gain a deeper understanding of STEM concepts, but they also developed important skills such as teamwork, communication, and problem-solving. I highly recommend the iLearnplace to anyone looking for a high-quality STEM education for their child.",
        },
        {
          image: "assets/images/testimonials/user-3.jpg",
          title: "Jitendra Kumawat",
          subTitle: "CEO , ConnectSME India",
          desc:
            "I am truly impressed by the educational ecosystem that is powered by AI. It is amazing to see how the system integrates the expertise of highly experienced educators with the power of artificial intelligence. The learning content is top-notch and tailored to meet the needs of individual learners. The use of AI has helped to personalize the learning experience.",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section" id="testi">
          <Container>
            {/* section title */}
            <SectionTitle
              title="WHAT THEY'VE SAID"
              //desc="The Big Oxmox advised her not to do so, because there were thousands of bad Commas, wild Question Marks and devious Semikoli."
            />

            <Row className="mt-5">
              <TestimonialsBox clients={this.state.clients} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Testimonials;
