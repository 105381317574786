import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { HOST_URL } from "../action/api";
import { UpdateUserProfile, updataProfilePhoto } from "../redux/action/action";
import Resizer from "react-image-file-resizer";
import { useDispatch } from "react-redux";
import { GET_PROFILE_DATA } from "../redux/reducerKeys";

export default function UserProfile(props) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [schoolname, setSchoolname] = useState("");
  const [parentcontact, setParentcontact] = useState("");
  const [classNamesemester, setClassclassNamesemester] = useState("");
  const [photo, setPhoto] = useState(
    "https://bootdey.com/img/Content/avatar/avatar7.png"
  );
  const [LendMark,setLendmark]= useState();
  const [street, setStreet] = useState();
  const [district, setDistrict] = useState();
  const [state, setState] = useState();
  const [pincode, setPincode] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [initialAddress, setInitialAddress] = useState(null);

  useEffect(() => {
    const {
      stu,
      add,
    } = props?.profileData || {};
  
    setName(stu?.name || "");
    setContact(stu?.contact || "");
    setEmail(stu?.email || "");
    setGender(stu?.gender || "");
    // setPhoto(`${HOST_URL}${stu?.photo || "https://bootdey.com/img/Content/avatar/avatar7.png"}`);
    setPhoto(stu?.photo || "https://bootdey.com/img/Content/avatar/avatar7.png");
    // Assuming address is an array, you may need to adjust this based on your actual data structure
    // if (add && add.length > 0) {
    //   const [firstAddress] = add; // Assuming you want to use the first address
    //   setAddress(firstAddress.address || "");
    //   setDistrict(firstAddress.district || "");
    //   setState(firstAddress.state || "");
    //   setPincode(firstAddress.pincode || "");
    //   setInitialAddress(firstAddress);
    // }
    if (add && add.length > 0) {
      const [firstAddress] = add; // Assuming you want to use the first address
      setAddress(firstAddress?.address || "");
      setDistrict(firstAddress?.district || "");
      setState(firstAddress?.state || "");
      setPincode(firstAddress?.pincode || "");
      setInitialAddress(firstAddress);
    } else {
      // If no address is present, set all address-related states to empty strings
      setAddress("");
      setDistrict("");
      setState("");
      setPincode("");
      setInitialAddress(null);
    }
  }, [props?.profileData]);


  // useEffect(() => {
  //   const {
  //     name,
  //     contact,
  //     email,
  //     gender,
  //     // parent_contact,
  //     photo,
  //     // school_name,
  //     // className_semester,
  //     // address,
  //     // district,
  //     // state,
  //     // pincode,
  //   } = { ...props?.profileData };

  //   setName(name);
  //   setContact(contact);
  //   setEmail(email);
  //   // setAddress();
  //   setGender(gender);
  //   // setSchoolname(school_name);
  //   // setParentcontact(parent_contact);
  //   // setClassclassNamesemester(className_semester);
  //   setPhoto(photo || "https://bootdey.com/img/Content/avatar/avatar7.png");
  //   // setAddress(address);
  //   // setDistrict(district);
  //   // setState(state);
  //   // setPincode(pincode);
    
  // }, [props?.profileData]);
// console.log('props', props?.profileData?.stu)
  
  // useEffect(() => {
  //   const {
      
  //     address,
  //     district,
  //     state,
  //     pincode,
  //   } = { ...props?.profileData?.add?.[0] };

    
  //   setAddress(address);
  //   setDistrict(district);
  //   setState(state);
  //   setPincode(pincode);
    
  // }, [props?.profileData?.add?.[0]]);
  
// console.log("pro", props?.profileData?.add)

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "jpeg",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new File([ia], "user.jpeg", { type: mimeString });
  };
  const onChangeFile = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);
    updataProfilePhoto(newFile)
      .then((res) => {
        dispatch({ type: GET_PROFILE_DATA, payload: res });
        setPhoto(res.photo);
      })
      .catch((err) => console.error("error", err));
  };

  const updateProfile = () => {
    const data = {
      name: name,
      email: email,
      contact: contact,
      address: address,
      gender: `${gender}`,
      // schoolname: schoolname,
      // parentcontact: parentcontact,
      // classNamesemester: classNamesemester,
      // address: address,
      
      district: district,
      state: state,
      pincode: pincode,
      // LendMark:LendMark,
      // addressId: initialAddress.id,
    };
    dispatch(UpdateUserProfile(data));

    setModalMessage("Profile updated successfully!");
    setShowModal(true);
  };

  return (
    <div className="container">
      <div className="row gutters">
        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <div className="Profile_card h-100">
            <div className="card-body">
              <div className="account-settings">
                <div className="user-profile">
                  <div className="user-avatar">
                    <img
    src={`${HOST_URL}${photo || 'https://cdn-icons-png.flaticon.com/128/149/149071.png'}`}
    alt="Profile Icon"
  />

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pencil"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg>
                    <input
                      type="file"
                      id="fileInput"
                      className="profile-input"
                      onChange={onChangeFile}
                      accept="image/*"
                    />
                  </div>
                  <h5 className="user-name">{name}</h5>
                  <h6 className="user-email">{email}</h6>
                </div>
                {/* <div className="about">
                  <h5>About</h5>
                  <p>
                    I'm {name}. student of STEM I enjoy creating new
                    Ideas.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
          <div className="Profile_card h-100">
            <div className="card-body">
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h5 className="mb-2 text-primary">Personal Details</h5>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label for="fullName">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      placeholder="Enter full name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label for="eMail">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="eMail"
                      placeholder="Enter email ID"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label for="phone">Contact</label>
                    <input
                      type="text"
                      className="form-control"
                      id="contact"
                      placeholder="Enter contact number"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      readOnly 
                      // style={{ backgroundColor: 'white' }} 
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label for="website">Gender</label>
                    <select
                      class="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(e) => setGender(e.target.value)}
                      value={gender}
                    >
                      <option>male</option>
                      <option>female</option>
                      <option>transgender</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h5 className="mt-3 mb-2 text-primary">Address</h5>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label for="Street">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      placeholder="Enter Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label for="ciTy">District</label>
                    <input
                      type="text"
                      className="form-control"
                      id="district"
                      placeholder="Enter District"
                      value={district}
                      onChange={(e) => setDistrict(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label for="sTate">LendMark</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lendmark"
                      placeholder="Enter LendMark"
                      value={LendMark}
                      onChange={(e) => setLendmark(e.target.value)}
                    />
                  </div>
                </div> */}
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label for="sTate">State</label>
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      placeholder="Enter State"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label for="zIp">PIN Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="pincode"
                      placeholder="PIN Code"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="text-right">
                    <button
                      type="button"
                      id="submit"
                      name="submit"
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      id="submit"
                      name="submit"
                      className="btn btn-primary"
                      onClick={updateProfile}
                    >
                      Update
                    </button>
                  </div>
                  <Modal show={showModal} onHide={() => setShowModal(false)} >
        <Modal.Header closeButton>
          <Modal.Title>Profile Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{modalMessage}</p>
        </Modal.Body>
        
      </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
