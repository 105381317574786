import {
    UPLOAD_VIDEO_REQUEST,
    UPLOAD_VIDEO_SUCCESS,
    UPLOAD_VIDEO_FAILURE,
    FETCH_VIDEO_REQUEST,
    FETCH_VIDEO_SUCCESS,
    FETCH_VIDEO_FAILURE,
  } from "../reducerKeys";
  
  const initialState = {
    videoUrl: '',
    
    id: null,
    loading: false,
  };
  
  const videoUploadReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPLOAD_VIDEO_REQUEST:
      case FETCH_VIDEO_REQUEST:
        return { ...state, loading: true };
  
      case UPLOAD_VIDEO_SUCCESS:
        return {
          ...state,
          id: action.payload.id,
          videoUrl: action.payload,
          loading: false,
        };
  
      case FETCH_VIDEO_SUCCESS:
        return {
          ...state,
          videoUrl: action.payload.video_path,
          loading: false,
        };
      case UPLOAD_VIDEO_FAILURE:
      case FETCH_VIDEO_FAILURE:
        return { ...state, error: action.payload, loading: false };
      default:
        return state;
    }
  };
  
  export default videoUploadReducer;
  