import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import ModalSection from "../../components/common/ModalSection";


class Islp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  render() {
    return (
      <React.Fragment>
      <br></br><br></br><br></br><br></br>
        <section
          className="section bg-islp home-half"
          id="home"
        // data-image-src="images/bg-home.jpg"
        // style={!this.props.data ? { display: "none" } : { display: "" }} 
        // style={{
        //   marginLeft: "40px",
        //   marginRight: "40px"
        // }}
        >
          {/* <div className="bg-overlay"></div> */}
          {/* <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row>
                  <Col
                    lg={{ size: 8, offset: 2 }}
                    className="col-lg-8 offset-lg-2 text-white text-center"
                  > */}
                    {/* <h1 className="home-title">ILP STEM Premier League 2023</h1>
                    <p className="pt-3 home-desc">INNOVATIVE INSIGHT IN ACTION</p> */}

                    {/* <br />
                    <Link
                      to={"JoinUs"}
                      style={{ marginBottom: "20px" }}
                      className="mt-2 btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
                      type="button"
                    >
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() =>
                          window.open("https://paytm.me/xp-kav9")
                        }
                      >
                        Know more
                      </button>
                    </Link>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br /> */}
                    {/* <Link
                      to={"onlineseminar"}
                      style={{ marginBottom: "20px" }}
                      className="mt-2 btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
                      type="button"
                    >
                      join online seminar
                    </Link> */}
                    {/* <h3>Hurry Up: win up to 5 lacs
                      ISPL2023 - register now.
                    </h3> */}
                    <p className="play-shadow mt-4">
                      <Link
                        to="#"
                        onClick={this.callModal}
                        className="play-btn video-play-icon"
                      >
                        <i className="mdi mdi-play text-center"></i>
                      </Link>
                    </p>
                  {/* </Col> */}
                {/* </Row> */}
              {/* </Container> */}
            {/* </div> */}
          {/* </div> */}
          {/* <div className="wave-effect wave-anim">
            <div className="waves-shape shape-one">
              <div
                className="wave wave-one"
                style={{
                  backgroundImage: `url(assets/images/wave-shape/wave1.png)`,
                }}
              ></div>
            </div>
            <div className="waves-shape shape-two">
              <div
                className="wave wave-two"
                style={{
                  backgroundImage: `url(assets/images/wave-shape/wave2.png)`,
                }}
              ></div>
            </div>
            <div className="waves-shape shape-three">

              <div
                className="wave wave-three"
                style={{
                  backgroundImage: `url(assets/images/wave-shape/wave3.png)`,
                }}
              ></div>
            </div>
          </div> */}


          {/* Render ModalSection Component for Modal */}
          <ModalSection ref="child" channel="youtube" videoId="kTuT0MChC14" />


        </section>

      </React.Fragment>

    );
  }
}

export default Islp;
