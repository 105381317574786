import { HOST_URL } from "./action/api";



export function Buynow(data) {
    return fetch(`${HOST_URL}buynow/`, {
        method: 'POST',
        mode: 'no-cors',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
        }
    }).then(res => res.json()
    ).catch(err => err);
}


export function Contactdata(data) {
    return fetch(`${HOST_URL}contact/`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => res.json()
    ).catch(err => err);
}

//for login dashboard

export function Signin(data) {
    return fetch(`${HOST_URL}otp/generate`, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {
            'content-Type': 'application/json',
        }
    }).then(res => res.json()
    ).catch(err => err);
}

//this api for update student profile 

export function UpdateUserProfile(data, file) {
    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('contact', data.contact);
    formData.append('photo', file)
    return fetch(`${HOST_URL}student/${JSON.parse(localStorage.getItem('id'))}/`, {
        method: 'put',
        body: formData,
    })
}




export function GetUserProfile() {
    const token = localStorage.getItem("token");
    const API = `${HOST_URL}create/student`;
    return fetch(`${API}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
}

