import React, { useEffect } from 'react';

const RazorpayButton2 = () => {
  useEffect(() => {
    // Dynamically load the Razorpay script once the component mounts
    const script = document.createElement('script');
    script.src = "https://checkout.razorpay.com/v1/payment-button.js";
    script.setAttribute("data-payment_button_id", "pl_PHAfPloN1fm8M2"); // Your Razorpay payment_button_id
    script.async = true;

    // Append the script to the body
    document.getElementById('razorpay-button-container').appendChild(script);
  }, []);

  return (
    <div>
      <form id="razorpay-button-container">
        {/* Razorpay button will be rendered here */}
      </form>
    </div>
  );
};

export default RazorpayButton2;
