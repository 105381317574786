// PDFViewer.js
import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf';


const PDFViewer = ({ pdfUrl }) => {
  return (
    <div style={{ width: '100%', height: '500px' }}>
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
      <Viewer fileUrl={pdfUrl} />
    </Worker>
  </div>
  );
};

export default PDFViewer;




// import React from 'react';
// import { Box, Link, Typography } from '@mui/material';

// const jsonData = {
//   "TBF_L1": [
//     {"task_code": "TBF_L1_T1", "url": "https://drive.google.com/file/d/1z5zx_ggDGlqUqhJPkHSxHgMEmVRF_JKg/preview"},
//     {"task_code": "TBF_L1_T2", "url": "https://drive.google.com/your_pdf_link_2"},
//     {"task_code": "TBF_L1_T3", "url": "https://drive.google.com/your_pdf_link_3"}
//   ]
// };

// function PDFViewer() {
//   return (
//     <Box>
//       <Typography variant="h6">Task PDFs</Typography>
//       <Box id="pdfLinks">
//         {jsonData["TBF_L1"].map((task, index) => (
//           <Link
//             key={index}
//             href={task.url}
//             target="_blank"
//             rel="noopener noreferrer"
//             style={{ display: 'block', marginTop: '8px' }}
//           >
//             {task.task_code}
//           </Link>
//         ))}
//       </Box>
//     </Box>
//   );
// }

// export default PDFViewer ;

