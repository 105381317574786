import React from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/toaster.css'

const Toasters = () => {
    return <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={true} />
}

export default Toasters
