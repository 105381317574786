import React, { useState } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import MuiPhoneNumber from "material-ui-phone-number";
import { Buynow } from '../../api';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import Login from '../Navbar/login';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(6),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'outset',
    borderRadius: '15px',
    boxShadow: theme.shadows[6],
    padding: theme.spacing(5, 0, 3),
    // width: 370,
    minWidth: 360
  },
  submit: {
    marginRight: 13
  },
  info: {
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: 'dimgray',
    paddingLeft: 25,
    paddingBottom: 40,
    paddingRight: 25
  },
  paper1: {
    backgroundColor: theme.palette.background.paper,
    border: 'outset',
    borderRadius: '10px',
    boxShadow: theme.shadows[6],
    padding: theme.spacing(3, 4, 3),
    width: 370,
  },
}));

export default function BuyNow(props) {

  const [contact, setContact] = useState({ number: null });
  const [email, setEmail] = useState({ mail: null })
  const [msg, setMsg] = useState(' ');

  const [showLoginForm, setShowLoginForm] = useState(false);

  const handleBuyNowClick = () => {
    setShowLoginForm(true);
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
    if (!contact.number) {
      setMsg('plasse input your phone number')
    }
    else {
      let data = { contact: contact.number, product_name: props.pricings, email: email.mail }
      Buynow(data)
      setContact({ number: null })
      setEmail({ mail: null })
      setOpen(false)
      setMopen(true)
      setTimeout(() => {
        setMopen(false)
      }, 1500)
    }
  }

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [mopen, setMopen] = useState(false);

  // const handleOpen = () => {
  //   ReactGA.event({
  //     category: 'event',
  //     action: 'click the Button from buynow'
  //   })
  //   setOpen(true);
  // };

  const handleClose = () => {
    setMopen(false);
    setOpen(false);
  };

  const openLoginPopup = () => {
    const loginWindow = window.open('', 'Login', 'width=500,height=600');
    loginWindow.document.title = 'Login';
    loginWindow.document.body.innerHTML = '<div id="login-root"></div>';
    ReactDOM.render(<Login />, loginWindow.document.getElementById('login-root'));
  };

  return (
    <>
    {/* <button
        className="btn btn-primary"
        type="button"
        onClick={openLoginPopup}
      >
        Buy Now
      </button> */}
      {showLoginForm ? (
        <Login />
      ) : (
        <button className="btn btn-primary" type="button" onClick={handleBuyNowClick}>
          Buy Now
        </button>
      )}
      {/* <button className="btn btn-primary" type="button" onClick={()=>window.open('https://paytm.me/xp-kav9')} >
        Enrol Now
      </button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <form onSubmit={mySubmitHandler}>
            <div className={classes.paper}>
              <Typography className={classes.info} >
                Please share contact information, We will get back to you.
              </Typography>
              <DialogContent>
                <MuiPhoneNumber
                  required
                  name="phone"
                  //label="Phone Number"
                  data-cy="user-phone"
                  defaultCountry={"in"}
                  // variant="outlined"
                  value={contact.number}
                  onChange={(e) => {
                    setMsg('')
                    setContact({ number: e })
                  }}
                  fullWidth
                />
                <Typography color='secondary' >{msg}</Typography>
              </DialogContent>
              <DialogContent>
                <TextField
                  required
                  type="email"
                  placeholder="email@example.com"
                  id="standard-required"
                  value={email.mail}
                  onChange={(e) => {
                    const user = e.target.value
                    setEmail({ mail: user })
                  }}
                  fullWidth />
              </DialogContent>
              <DialogActions></DialogActions>
              <DialogActions className={classes.submit}>
                <button className="btn btn-primary" type='submit' >Submit</button>
              </DialogActions>
            </div>
          </form>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={mopen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={mopen}>
          <div className={classes.paper1}>
            {/* <Typography color='secondary' >
              Opps!</Typography> */}
            <Typography color='secondary' >
              Thank You!</Typography>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
