import { Avatar, Card, CardContent, CardHeader, Container, Grid, List, ListItem, ListItemAvatar, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { onClubData } from '../../action/islpAction';

function ExecutiveMembers() {
    const [data, setData] = useState(null);

    useEffect(() => {
        var token = localStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        onClubData(requestOptions)
            .then(res => res.json())
            .then(data => setData(data))
            .catch(error => console.log(error));
    }, []);

    if (!data) {
        return <div>Loading...</div>;
    }

    const clubMembers = data.data && data.data.club_executive_member ? data.data.club_executive_member : [];

    return (
        <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item>
                <Typography variant="h4">Club Executive Members</Typography>
            </Grid>
            <Grid item>
                <TableContainer component={Card}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Designation</TableCell>
                                <TableCell>Contact</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Gender</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clubMembers.map((member) => (
                                <TableRow key={member.email}>
                                    <TableCell>{member.name}</TableCell>
                                    <TableCell>{member.designation}</TableCell>
                                    <TableCell>{member.contact}</TableCell>
                                    <TableCell>{member.email}</TableCell>
                                    <TableCell>{member.gender}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

export default ExecutiveMembers;
