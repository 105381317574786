// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Modal from "@material-ui/core/Modal";
// import Backdrop from "@material-ui/core/Backdrop";
// import Fade from "@material-ui/core/Fade";
// import MuiPhoneNumber from "material-ui-phone-number";
// import { TextField, Typography, DialogActions, DialogContent, CircularProgress } from "@material-ui/core";
// import { useHistory } from "react-router";

// const useStyles = makeStyles((theme) => ({
//   modal: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     textAlign: "center",
//   },
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     border: "outset",
//     borderRadius: "15px",
//     boxShadow: theme.shadows[6],
//     padding: theme.spacing(5, 0, 3),
//     minWidth: 360,
//   },
//   info: {
//     fontSize: "16px",
//     fontWeight: 500,
//     color: "dimgray",
//     padding: "0 25px 40px",
//   },
//   centerText: {
//     fontSize: "20px",
//     fontWeight: 600,
//     color: theme.palette.error.main,
//     marginBottom: theme.spacing(3),
//   },
//   submit: {
//     marginRight: 13,
//   },
// }));

// export default function DeleteAccount() {
//   const classes = useStyles();
//   const history = useHistory();
//   const [contact, setContact] = useState({ number: "" });
//   const [otp, setOtp] = useState({ number: "" });
//   const [msg, setMsg] = useState("");
//   const [open, setOpen] = useState(false);
//   const [otpOpen, setOtpOpen] = useState(false);
//   const [error, setError] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [otpError, setOtpError] = useState("");

//   const startDeletionProcess = (event) => {
//     event.preventDefault();
//     let data = { contact: contact.number };
//     if (!(data.contact && data.contact.length === 15 && data.contact.includes("+91"))) {
//       setMsg("Please enter a valid mobile number.");
//     } else {
//       setOpen(false);
//       setOtpOpen(true);
//     }
//   };

//   const handleOtpVerification = (event) => {
//     event.preventDefault();
//     if (!(otp.number && otp.number.length === 6)) {
//       setOtpError("Please enter a valid OTP.");
//     } else {
//       setOtpOpen(false);
//       alert("Wrong OTP");
//     }
//   };

//   const handleClose = () => {
//     setMsg("");
//     setOtpOpen(false);
//     setOpen(false);
//     setContact({ number: "" });
//     setOtp({ number: "" });
//   };

//   const resendOtp = () => {
//     setOtpError("");
//     setMsg("OTP has been resent.");
//   };

//   return (
//     <>
//       {/* Centered Request Text */}
//       <div style={{ textAlign: "center", margin: "20px 0" }}>
//         <Typography className={classes.centerText}>Request for Delete User Account</Typography>
//         <button className="btn btn-danger" onClick={() => setOpen(true)}>
//           Delete Account
//         </button>
//       </div>

//       <Modal
//         className={classes.modal}
//         open={loading}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{ timeout: 500 }}
//       >
//         <Fade in={loading}>
//           <CircularProgress size={100} color="secondary" />
//         </Fade>
//       </Modal>

//       {/* Phone Number Input Modal */}
//       <Modal
//         className={classes.modal}
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{ timeout: 500 }}
//       >
//         <Fade in={open}>
//           <div className={classes.paper}>
//             <Typography className={classes.info}>Enter your phone number to delete your account</Typography>
//             <DialogContent>
//               <MuiPhoneNumber
//                 required
//                 label="Mobile Number"
//                 defaultCountry={"in"}
//                 value={contact.number}
//                 onChange={(value) => {
//                   setMsg("");
//                   setContact({ number: value });
//                 }}
//                 fullWidth
//               />
//               <Typography color="secondary">{msg}</Typography>
//             </DialogContent>
//             <DialogActions className={classes.submit}>
//               <button className="btn btn-secondary" onClick={handleClose}>
//                 Cancel
//               </button>
//               <button className="btn btn-danger" onClick={startDeletionProcess}>
//                 Submit
//               </button>
//             </DialogActions>
//           </div>
//         </Fade>
//       </Modal>

//       {/* OTP Verification Modal */}
//       <Modal
//         className={classes.modal}
//         open={otpOpen}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{ timeout: 500 }}
//       >
//         <Fade in={otpOpen}>
//           <div className={classes.paper}>
//             <Typography className={classes.info}>Enter OTP to confirm account deletion</Typography>
//             <DialogContent>
//               <TextField
//                 required
//                 inputProps={{ maxLength: 6 }}
//                 label="Enter OTP"
//                 value={otp.number}
//                 onChange={(e) => {
//                   setOtpError("");
//                   e.target.value = e.target.value.replace(/[^0-9]/g, "");
//                   setOtp({ number: e.target.value });
//                 }}
//                 fullWidth
//               />
//               <Typography color="secondary">{otpError}</Typography>
//             </DialogContent>
//             <DialogActions>
//               <button className="btn btn-link" onClick={resendOtp}>
//                 Resend OTP
//               </button>
//             </DialogActions>
//             <DialogActions className={classes.submit}>
//               <button className="btn btn-secondary" onClick={handleClose}>
//                 Cancel
//               </button>
//               <button className="btn btn-danger" onClick={handleOtpVerification}>
//                 Verify OTP
//               </button>
//             </DialogActions>
//           </div>
//         </Fade>
//       </Modal>

//       {/* Error Modal */}
//       <Modal
//         className={classes.modal}
//         open={error}
//         onClose={() => setError(false)}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{ timeout: 500 }}
//       >
//         <Fade in={error}>
//           <div className={classes.paper}>
//             <Typography color="error">Error in OTP verification. Please try again.</Typography>
//             <DialogActions>
//               <button className="btn btn-secondary" onClick={() => setError(false)}>
//                 Close
//               </button>
//             </DialogActions>
//           </div>
//         </Fade>
//       </Modal>
//     </>
//   );
// }


// DeleteAccount.js

import React, { useState } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';

const DeleteAccount = () => {
  const [contact, setContact] = useState('');
  const [otp, setOtp] = useState('');
  const [otpRequested, setOtpRequested] = useState(false);
  const [message, setMessage] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  // API URL endpoints
  const requestOtpUrl = 'https://ilearnpoint.com/ilpapi/otp/generate';
  const confirmDeletionUrl = 'https://ilearnpoint.com/ilpapi/otp/verify';

  const handleRequestOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(requestOtpUrl, { contact });
      if (response.status === 200) {
        setOtpRequested(true);
        setMessage('OTP sent to your mobile number.');
      }
    } catch (error) {
      setMessage('Error requesting OTP. Please try again.');
    }
  };

  const handleConfirmDeletion = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(confirmDeletionUrl, { contact, otp });
      if (response.status === 200) {
        setIsOtpVerified(true);
        setMessage('Account deletion scheduled in 24 hours.');
        await sendEmailNotification();
      }
    } catch (error) {
      setMessage('Invalid OTP or mobile number. Please try again.');
    }
  };

  const sendEmailNotification = async () => {
    const timestamp = new Date().toLocaleString();
    try {
      const templateParams = {
        to_email: 'info@ilearnplace.com',
        user_mobile: contact,
        request_timestamp: timestamp
      };
      await emailjs.send(
        'service_6wj4fku',
        'template_5zjutfo',
        templateParams,
        'vrlhqx3TsqPnrj_Bx'
      );
      console.log('Account deletion request email sent successfully to developer.');
    } catch (error) {
      console.log('Error sending account deletion request email:', error);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.header}>Delete Account</h2>
        {!otpRequested && !isOtpVerified && (
          <form onSubmit={handleRequestOtp} style={styles.form}>
            <label style={styles.label}>Enter Mobile Number:</label>
            <input
              type="text"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              required
              placeholder="Mobile Number"
              style={styles.input}
            />
            <button type="submit" style={styles.button}>Request OTP</button>
          </form>
        )}

        {otpRequested && !isOtpVerified && (
          <form onSubmit={handleConfirmDeletion} style={styles.form}>
            <label style={styles.label}>Enter OTP:</label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              placeholder="OTP"
              style={styles.input}
            />
            <button type="submit" style={styles.button}>Confirm Account Deletion</button>
          </form>
        )}

        {message && <p style={styles.message}>{message}</p>}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f7f9fc',
  },
  card: {
    maxWidth: '400px',
    width: '100%',
    padding: '30px',
    borderRadius: '12px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    textAlign: 'center',
  },
  header: {
    marginBottom: '20px',
    fontSize: '1.8rem',
    color: '#333',
    fontWeight: '600',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  label: {
    fontSize: '1rem',
    color: '#555',
    marginBottom: '5px',
    textAlign: 'left',
  },
  input: {
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    fontSize: '1rem',
    outline: 'none',
    transition: 'border 0.2s ease-in-out',
  },
  button: {
    padding: '10px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
  message: {
    marginTop: '15px',
    fontSize: '1rem',
    color: '#28a745',
  }
};

export default DeleteAccount;
