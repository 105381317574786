// import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import { Container, Row, Col, Form } from "reactstrap";

// //Import Footer link
// import FooterLinks from "./footer-links";
// //import Switcher from "./Switcher";

// class Footer extends Component {
//   state = {
//     links: [
//       {
//         title: "ilearnplace",
//         child: [
//           { link: "home", title: "Home" },
//           { link: "features", title: "About us" },
//           { link: "contact", title: "Contact us" },
//         ],
//       },
//       {
//         title: "Information",
//         child: [
//           { link: "/terms&Conditions", title: "Terms & Conditions" },
//           { link: "/shipping&deliveryPolicy", title: "Shipping & Delivery Policy" },
//           { link: "/return&cancellationPolicy", title: "Return & Cancellation Policy" },
//           { link: "/privacypolicy", title: "Privacy Policy" },
//         ],
//       },
//       {
//         title: "Support",
//         child: [
//           { link: "tel:+91 809 441 4990", title: "+91 809 441 4990" },
//           { link: "mailto:info@ilearnplace.com", title: "info@ilearnplace.com" },
//           { link: "address:25, CMR Road, Bangalore, KA, 560084", 
//           title: `Ileanaplace, #69, Jamna Puri, Murlipura, Jaipur Rajasthan 302032 ` }
//         ],
//       },
//     ],
//   };
//   render() {
//     return (
//       <React.Fragment>
//         <footer className="footer">
//           <Container>
//             <Row>
//               {this.state.links.map((fLink, key) => (
//                 <Col key={key} lg={3} className="mt-4">
//                   <h4>{fLink.title}</h4>
//                   <div className="text-muted mt-4">
//                     <ul className="list-unstyled footer-list">
//                       {fLink.child.map((fLinkChild, key) => (
//                         <li key={key}>
//                           <Link to={fLinkChild.link}>{fLinkChild.title}</Link>
//                         </li>
//                       ))}
//                     </ul>
//                   </div>
//                 </Col>
//               ))}

//               {/* <Col lg={3} className="mt-4">
//                 <h4>Subscribe</h4>
//                 <div className="text-muted mt-4">
//                   <p>
//                     Please Subscribe  your email for 
//                     latest new and updates about ilearnplace.
//                   </p>
//                 </div>
//                 <Form className="subscribe">
//                   <input
//                     placeholder="Email"
//                     className="form-control"
//                     required
//                   />
//                   <Link to="#" className="submit">
//                     <i className="pe-7s-paper-plane"></i>
//                   </Link>
//                 </Form>
//               </Col> */}
//             </Row>
//           </Container>
//         </footer>
//         {/* Render footer links */}
//         <FooterLinks />

//         {/* color switcher */}
//         {/* <Switcher /> */}
//       </React.Fragment>
//     );
//   }
// }

// export default Footer;

import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

// Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
  state = {
    links: [
      {
        title: "ilearnplace",
        child: [
          { link: "#home", title: "Home" },
          { link: "#features", title: "About us" },
          { link: "#contact", title: "Contact us" },
        ],
      },
      {
        title: "Information",
        child: [
          { link: "/terms&Conditions", title: "Terms & Conditions" },
          { link: "/shipping&deliveryPolicy", title: "Shipping & Delivery Policy" },
          { link: "/return&cancellationPolicy", title: "Return & Cancellation Policy" },
          { link: "/privacypolicy", title: "Privacy Policy" },
        ],
      },
      {
        title: "Support",
        child: [
          { link: "tel:+91 809 441 4990", title: "+91 809 441 4990" },
          { link: "mailto:info@ilearnplace.com", title: "info@ilearnplace.com" },
          {
            link: "address:25, CMR Road, Bangalore, KA, 560084",
            title: `Ilearnplace, #69, Jamna Puri, Murlipura, Jaipur Rajasthan 302032`,
          },
        ],
      },
    ],
  };

  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              {this.state.links.map((fLink, key) => (
                <Col key={key} lg="4" md="6" sm="12" className="mt-4">
                  <h4>{fLink.title}</h4>
                  <div className="text-muted mt-4">
                    <ul className="list-unstyled footer-list">
                      {fLink.child.map((fLinkChild, key) => (
                        <li key={key}>
                          <a href={fLinkChild.link}>{fLinkChild.title}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </footer>
        {/* Render footer links */}
        <FooterLinks />
      </React.Fragment>
    );
  }
}

export default Footer;
