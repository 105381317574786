// import React from 'react';
// import { Link } from 'react-router-dom';
// import { HOST_URL } from '../../action/api';
// import decryptURI from "../../components/Decrypt"
// export default function ProductDetails(props) {
//    return (
//     <React.Fragment>
//       {/* <Link className="Link" to={`game-details/${props.pricings.id}`} > */}
//         <img src={props.pricings.photo} width='400px' alt='img' />
//         {/* <span className="btn btn-primary">Know More</span> */}
//       {/* </Link> */}
//     </React.Fragment>
//   );
// } 

import React, { useEffect, useState } from 'react';
import decryptURI from "../../components/Decrypt";

export default function ProductDetails(props) {
    const [decryptedPhotoUrl, setDecryptedPhotoUrl] = useState("");

    useEffect(() => {
        // Decrypt the photo URL if it exists
        if (props.pricings?.photo) {
            const decryptedUrl = decryptURI(props.pricings.photo);
            setDecryptedPhotoUrl(decryptedUrl);
        }
    }, [props.pricings.photo]);
    

    return (
        <React.Fragment>
            {decryptedPhotoUrl && (
                <img src={decryptedPhotoUrl} width='400px' height='100px' alt='img' />
            )}
        </React.Fragment>
    );
}
