import React from 'react';
import { Typography } from '@material-ui/core';

const clubRules = [{
    "id": 1,

    "description": "Running an ilearnplace student club  in colleges/schools can be a fun and exciting way to engage students in science, technology, engineering, and mathematics (STEM) education. To ensure the success and safety of the club, there are certain rules and guidelines that should be followed. Here are some rules that can be helpful in running a STEM learning and robotics club in colleges"
},
{
    "id": 2,

    "description": "Club Membership : The club should be open to all students who have an interest in STEM and robotics games. The club may have a limit on the number of members to maintain an efficient and effective learning environment."
},
{
    "id": 3,
    "description": "Club Officers: The club should have a designated leader to oversee the club's activities and a team of officers to assist them. These officers should be elected by the members of the club."
},
{
    "id": 4,
    "description": "Club Meetings: The club should hold regular meetings to discuss the progress of the projects, plan new activities, and share ideas. The meetings should be held at a designated time and place and should be open to all members."
},
{
    "id": 5,
    "description": "Safety Guidelines: The club should follow all safety guidelines and procedures when working with ILP STEM gaming kits, robotics equipment and tools. This includes wearing protective gear and using equipment properly."
},
{
    "id": 6,
    "description": "Project/idea Selection: The club should choose projects that are appropriate for the skill level and experience of the members. Projects should also align with the club's goals and objectives."
},
{
    "id": 7,
    "description": "Funding: The club may require funding to purchase robotics equipment and materials. Funding can be acquired through donations, sponsorships, or fundraising activities."
},
{
    "id": 8,
    "description": "Competition Participation: The club may participate in ILP STEM learning Premier league (ISPL) yearly and STEM & robotics competitions to showcase their skills and knowledge. Participation in competitions can also provide the club with exposure and recognition."
},
{
    "id": 9,
    "description": "By following these rules and guidelines, an ilearnplace student club in colleges can be a fun and educational experience for all members involved."
}]


const ClubRules = () => {
    return (
        <div>
            <Typography variant="h6" gutterBottom>
               <b> Club Rules </b>
            </Typography>

            <div>
                <Typography variant="subtitle1" gutterBottom>
                    <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                        Running an ilearnplace student club  in colleges/schools can be a fun and exciting way to engage students in science, technology, engineering, and mathematics (STEM) education. To ensure the success and safety of the club, there are certain rules and guidelines that should be followed. Here are some rules that can be helpful in running a STEM learning and robotics club in colleges
                    </b>
                </Typography>
                
                <Typography variant="body1" gutterBottom>
                    <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                        <b>Club Membership :</b> The club should be open to all students who have an interest in STEM and robotics games. The club may have a limit on the number of members to maintain an efficient and effective learning environment.
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                <b>Club Officers:</b> The club should have a designated leader to oversee the club's activities and a team of officers to assist them. These officers should be elected by the members of the club.
                </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                <b>Club Meetings:</b> The club should hold regular meetings to discuss the progress of the projects, plan new activities, and share ideas. The meetings should be held at a designated time and place and should be open to all members.
                </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                <b>Safety Guidelines:</b> The club should follow all safety guidelines and procedures when working with ILP STEM gaming kits, robotics equipment and tools. This includes wearing protective gear and using equipment properly.
                </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                <b>Project/idea Selection:</b> The club should choose projects that are appropriate for the skill level and experience of the members. Projects should also align with the club's goals and objectives.
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
               <b> Funding:</b> The club may require funding to purchase robotics equipment and materials. Funding can be acquired through donations, sponsorships, or fundraising activities.
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                <b>Competition Participation:</b> The club may participate in ILP STEM learning Premier league (ISPL) yearly and STEM & robotics competitions to showcase their skills and knowledge. Participation in competitions can also provide the club with exposure and recognition.
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                By following these rules and guidelines, an ilearnplace student club in colleges can be a fun and educational experience for all members involved.
                    </b>
                </Typography>
            </div>

        </div>
    );
};

export default ClubRules;
