import React, { createContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { TOAST_LOADING, TOAST_SUCCESS, TOAST_WARN } from "../../utils/index";
import { onPostClubDetails } from '../../action/islpAction';
import { useEffect } from 'react';
import validator from 'validator';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const MyContext = createContext();

const Step1Form = ({ setClubId, data }) => {
  const classes = useStyles();

  const [instituteId, setInstituteId] = useState('')
  const [instituteName, setInstituteName] = useState('')
  const [instituteAddress, setInstituteAddress] = useState('')
  const [instituteType, setInstituteType] = useState('')

  const [clubCounselorName, setClubCounselorName] = useState('');
  const [clubCounselorContact, setClubCounselorContact] = useState('');
  const [clubCounselorEmail, setClubCounselorEmail] = useState('');
  const [clubCounselorGender, setClubCounselorGender] = useState('');

  const [clubFacultyCoordinatorName, setClubFacultyCoordinatorName] = useState('');
  const [clubFacultyCoordinatorContact, setClubFacultyCoordinatorContact] = useState('');
  const [clubFacultyCoordinatorEmail, setClubFacultyCoordinatorEmail] = useState('');
  const [clubFacultyCoordinatorGender, setClubFacultyCoordinatorGender] = useState('');

  const [clubBusineConsultantName, setClubBusineConsultantName] = useState('');
  const [clubBusineConsultantContact, setClubBusineConsultantContact] = useState('');
  const [clubBusineConsultantEmail, setClubBusineConsultantEmail] = useState('');
  const [clubBusineConsultantGender, setClubBusineConsultantGender] = useState('');

  const [contactError, setContactError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setClubCounselorEmail(inputValue);
    setClubFacultyCoordinatorEmail(inputValue);
    setClubBusineConsultantEmail(inputValue);

    // Email validation regex pattern
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValid = emailRegex.test(inputValue);
    setIsEmailValid(isValid);
  };

  useEffect(() => {
    setInstituteId(data?.institute_id || '');
    setInstituteName(data?.institute_name || '');
    setInstituteAddress(data?.institute_address || '');
    setInstituteType(data?.institute_type || '');

    if (data?.club_admin_member && data.club_admin_member.length > 0) {
      if (data.club_admin_member[0]) {
        setClubCounselorName(data.club_admin_member[0]?.name || '');
        setClubCounselorContact(data.club_admin_member[0]?.contact || '');
        setClubCounselorEmail(data.club_admin_member[0]?.email || '');
        setClubCounselorGender(data.club_admin_member[0]?.gender || 'male');
      } else {
        setClubCounselorGender('male');
      }

      if (data.club_admin_member[1]) {
        setClubFacultyCoordinatorName(data.club_admin_member[1]?.name || '');
        setClubFacultyCoordinatorContact(data.club_admin_member[1]?.contact || '');
        setClubFacultyCoordinatorEmail(data.club_admin_member[1]?.email || '');
        setClubFacultyCoordinatorGender(data.club_admin_member[1]?.gender || 'male');
      }

      if (data.club_admin_member[2]) {
        setClubBusineConsultantName(data.club_admin_member[2]?.name || '');
        setClubBusineConsultantContact(data.club_admin_member[2]?.contact || '');
        setClubBusineConsultantEmail(data.club_admin_member[2]?.email || '');
        setClubBusineConsultantGender(data.club_admin_member[2]?.gender || 'male');
      }
    }
  }, [data]);


  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!instituteId || !instituteName || !instituteAddress || !instituteType || !clubCounselorName || !clubCounselorContact || !clubCounselorEmail || !clubCounselorGender || !clubFacultyCoordinatorName || !clubFacultyCoordinatorContact || !clubFacultyCoordinatorGender || !clubCounselorEmail || !clubBusineConsultantName || !clubBusineConsultantContact || !clubBusineConsultantEmail || !clubBusineConsultantContact) {
      TOAST_WARN("please fill required details.");
      return false;
    }
    else {
      const isValidEmail = validator.isEmail(clubCounselorEmail) && validator.isEmail(clubFacultyCoordinatorEmail) && validator.isEmail(clubBusineConsultantEmail);
      setIsEmailValid(isValidEmail);
      if (!isValidEmail) {
        TOAST_WARN("Please enter valid email addresses.");
        return false;
      }

      TOAST_LOADING("please wait...");
      var token = localStorage.getItem("token");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");

      let payload = {
        institute_id: instituteId,
        institute_name: instituteName,
        institute_address: instituteName,
        institute_type: instituteType,
        ilp_club_counsellor: {
          name: clubCounselorName,
          contact: clubCounselorContact,
          email: clubCounselorEmail,
          gender: clubCounselorGender,
          designation: "ilp_club_counsellor",
        },
        ilp_fauclty_coordinator: {
          name: clubFacultyCoordinatorName,
          contact: clubFacultyCoordinatorContact,
          email: clubFacultyCoordinatorEmail,
          gender: clubFacultyCoordinatorGender,
          designation: "ilp_fauclty_coordinator",
        },
        ilp_business_consultant: {
          name: clubBusineConsultantName,
          contact: clubBusineConsultantContact,
          email: clubBusineConsultantEmail,
          gender: clubBusineConsultantGender,
          designation: "ilp_business_consultant",
        },
      }

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
      };
      onPostClubDetails(requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setApiResponse(result);
          setClubId(result.club_id);
          // TOAST_SUCCESS("Club Register successfully.");
          if (result.success) {
            TOAST_SUCCESS(result.message);
          } else {
            TOAST_WARN(result.message);
          }
        })
        .catch((error) => {
          setError(error);
          TOAST_WARN("something went wrong");
          console.log('error', error)
        });
    };
  }

  return (
    <React.Fragment>

      <Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
            Institute ID
          </Typography>
          <TextField
            required
            id="id"
            name="Institute_id"
            value={instituteId}
            onChange={(e) => setInstituteId(e.target.value)}
            label="Institute ID"
            fullWidth
            autoComplete="given-name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
            Institute Name
          </Typography>
          <TextField
            required
            id="name"
            name="Institute_name"
            value={instituteName}
            onChange={(e) => setInstituteName(e.target.value)}
            label="Institute Name"
            fullWidth
            autoComplete="given-name"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
            Institute Address
          </Typography>
          <TextField
            required
            multiline={true}
            rows={3}
            id="name"
            name="Institute_address"
            value={instituteAddress}
            onChange={(e) => setInstituteAddress(e.target.value)}
            label="Institute Address"
            fullWidth
            autoComplete="given-name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
            Institute Type
          </Typography>
          <FormControl fullWidth>
            <InputLabel
              required
              id="demo-simple-select-label"
            >
              Institute Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="Institute_type"
              value={instituteType}
              onChange={(e) => setInstituteType(e.target.value)}
              label="Choose ILP STEM Game"
              fullWidth
            >
              <MenuItem value={"School"}>
                School
              </MenuItem>
              <MenuItem value={"College"}>
                College
              </MenuItem>
              <MenuItem value={"Other"}>
                Other
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
          Student Club Counselor
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="name"
              label="Club Counselor Name"
              name="name"
              value={clubCounselorName}
              onChange={(e) => setClubCounselorName(e.target.value)}
              fullWidth
              autoComplete="given-name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel
                required
                id="demo-simple-select-label"
              >
                Gender
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Club Counselor Gender"
                name="gender"
                value={clubCounselorGender}
                onChange={(e) => setClubCounselorGender(e.target.value)}
                fullWidth
              >
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>
                  Female
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="contact"
              label="Club Counselor Contact"
              name="contact"
              value={clubCounselorContact}
              // onChange={(e) => setClubCounselorContact(e.target.value)}
              fullWidth
              autoComplete="family-name"
              variant="outlined"
              helperText={
                contactError
                  ? "Please enter a 10-digit phone number (numbers only)"
                  : ""
              }
              InputProps={{
                inputProps: {
                  maxLength: 10,
                },
              }}
              onChange={(e) => {
                setClubCounselorContact(e.target.value.replace(/[^0-9]/g, ""));
                setContactError(false);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              label="Club Counselor Email"
              name="email"
              type="email"
              value={clubCounselorEmail}
              onChange={(e) => {
                setClubCounselorEmail(e.target.value);
                // handleEmailChange(e, 'clubCounselor');
              }}
              fullWidth
              autoComplete="shipping address-line1"
              variant="outlined"
              error={!isEmailValid} // Display error state if email is not valid
              helperText={!isEmailValid ? 'Invalid email format' : ''}
            />
          </Grid>
        </Grid>
        <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
          Student Club Faculty Coordinator
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="name"
              label="Club Faculty Coordinator Name"
              name="name"
              value={clubFacultyCoordinatorName}
              onChange={(e) => setClubFacultyCoordinatorName(e.target.value)}
              fullWidth
              autoComplete="given-name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel
                required
                id="demo-simple-select-label"
              >
                Gender
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Club Faculty Coordinator Gender"
                name="gender"
                value={clubFacultyCoordinatorGender}
                onChange={(e) => setClubFacultyCoordinatorGender(e.target.value)}
                fullWidth
              >
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>
                  Female
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="contact"
              label="Club Faculty Coordinator Contact"
              name="contact"
              value={clubFacultyCoordinatorContact}
              // onChange={(e) => setClubFacultyCoordinatorContact(e.target.value)}
              fullWidth
              autoComplete="family-name"
              variant="outlined"
              helperText={
                contactError
                  ? "Please enter a 10-digit phone number (numbers only)"
                  : ""
              }
              InputProps={{
                inputProps: {
                  maxLength: 10,
                },
              }}
              onChange={(e) => {
                setClubFacultyCoordinatorContact(e.target.value.replace(/[^0-9]/g, ""));
                setContactError(false);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              label="Club Faculty Coordinator Email"
              name="email"
              type="email"
              value={clubFacultyCoordinatorEmail}
              onChange={(e) => {
                setClubFacultyCoordinatorEmail(e.target.value);
                // handleEmailChange(e, 'clubFacultyCoordinator')
              }}
              error={!isEmailValid} // Display error state if email is not valid
              helperText={!isEmailValid ? 'Invalid email format' : ''}
              fullWidth
              autoComplete="shipping address-line1"
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Typography sx={{ mt: 5, ml: 1 }} variant="h6" gutterBottom>
          ILP Business Consultant
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="name"
              label="ILP Business Consultant Name"
              name="name"
              value={clubBusineConsultantName}
              onChange={(e) => setClubBusineConsultantName(e.target.value)}
              fullWidth
              autoComplete="given-name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel
                required
                id="demo-simple-select-label"
              >
                Gender
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="ILP Business Consultant Gender"
                name="gender"
                value={clubBusineConsultantGender}
                onChange={(e) => setClubBusineConsultantGender(e.target.value)}
                fullWidth
              >
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>
                  Female
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="contact"
              label="ILP Business Consultant Contact"
              name="contact"
              value={clubBusineConsultantContact}
              // onChange={(e) => setClubBusineConsultantContact(e.target.value)}
              fullWidth
              autoComplete="family-name"
              variant="outlined"
              helperText={
                contactError
                  ? "Please enter a 10-digit phone number (numbers only)"
                  : ""
              }
              InputProps={{
                inputProps: {
                  maxLength: 10,
                },
              }}
              onChange={(e) => {
                setClubBusineConsultantContact(e.target.value.replace(/[^0-9]/g, ""));
                setContactError(false);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              label="ILP Business Consultant Email"
              name="email"
              type="email"
              value={clubBusineConsultantEmail}
              onChange={(e) => {
                setClubBusineConsultantEmail(e.target.value);
                // handleEmailChange(e, 'clubBusineConsultant')
              }}
              error={!isEmailValid} // Display error state if email is not valid
              helperText={!isEmailValid ? 'Invalid email format' : ''}
              fullWidth
              autoComplete="shipping address-line1"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button className={classes.submitButton} type="submit" color="primary" variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </React.Fragment >
  );
}

export default Step1Form;