import axios from "axios";
import {
  UPLOAD_VIDEO_REQUEST,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILURE,
  FETCH_VIDEO_REQUEST,
  FETCH_VIDEO_SUCCESS,
  FETCH_VIDEO_FAILURE,
  GET_ALL_INFO,
  GET_PROFILE_DATA,
} from "../reducerKeys";

import { EDIT_PROFILE, HOST_URL, PROFIEL_DATA } from "../../action/api";

export const allInfo = () => {
  const token = localStorage.getItem("token");
  var myHeaders = new Headers();
  myHeaders.append("Authorization", token);
  const API = `${HOST_URL}allinfo/${JSON.parse(localStorage.getItem("id"))}/`;
  return async (dispatch) =>
    fetch(API, {
      method: "get",
      headers: myHeaders,
      mode: "cors",
    })
      .then((res) => res.json())
      .then((data) => dispatch({ type: GET_ALL_INFO, payload: data }))
      .catch((err) => err);
};

export const postvideoUpload = (formData) => {
  return async (dispatch) => {
    dispatch({ type: UPLOAD_VIDEO_REQUEST });

    try {
      const response = await axios.post(EDIT_PROFILE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({ type: UPLOAD_VIDEO_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({
        type: UPLOAD_VIDEO_FAILURE,
        error: error.message,
      });
    }
  };
};

export const fetchVideo = (id) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_VIDEO_REQUEST });
    const response = await axios.get(`${EDIT_PROFILE}/${id}/`);
    dispatch({ type: FETCH_VIDEO_SUCCESS, payload: response.data });
  } catch (error) {
    console.error(error);
    dispatch({ type: FETCH_VIDEO_FAILURE, payload: error.message });
  }
};

export const profileData = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${PROFIEL_DATA}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await response.json();
    dispatch({ type: GET_PROFILE_DATA, payload: data });
  } catch (error) {
    console.error("Error:", error);
  }
};

export const UpdateUserProfile = (userData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(EDIT_PROFILE, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(userData),
    });

    if ((await response).ok) {
      const data = await response.json();
      dispatch({ type: GET_PROFILE_DATA, payload: data });
    } else {
      throw new Error("Failed to update user profile");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updataProfilePhoto = async(photo) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("photo", photo);
  formData.append("gender", 'male');
  try {
    const response = fetch(EDIT_PROFILE, {
      headers: { Authorization: token },
      method: "PUT",
      body: formData,
    });

    if ((await response).ok) {
      const data = (await response).json();
      return data
    }else{
      throw new Error("Failed to update user profile");
    }

  } catch (error) {
    console.error("Error:", error);
  }
}; 
