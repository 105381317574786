// Pay.js
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function Pay2() {
    const history = useHistory();

    // Redirect to ProductPage when button is clicked
    const handleRedirect = () => {
        history.push('/robocar');
    };

    return (
        <div>
            {/* <h2>Proceed with Payment</h2> */}
            {/* <button onClick={handleRedirect}>Buy Now</button> */}
            <button className="btn btn-primary" type="button" onClick={handleRedirect}>
          Buy Now
        </button>
        </div>
    );
}
