import { Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { onClubData } from '../../action/islpAction';
import { Route, useHistory } from 'react-router-dom/cjs/react-router-dom';
import HorizontalLinearStepper from './Stepper';
import { Box, Button } from '@material-ui/core';

function ClubDetails() {
    const history = useHistory()
    const [data, setData] = useState(null);
    const [buttonClicked, setButtonClicked] = useState(false);

    const handleClick = () => {
        history.push('/dashboard/club/registration'); // replace '/my-app' with the path of the page you want to open
        setButtonClicked(true);
    }

    useEffect(() => {
        var token = localStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        onClubData(requestOptions).then(res => res.json().then(data => setData(data)))
    }, []);

    if (!data) {
        return <div>Please register ilearnplace student club <br /> Click here for club  registration
            <Box sx={{ flexGrow: 1 }}>
                <div>
                    <Route
                        path="/dashboard/club/registration"
                        // component={HorizontalLinearStepper}
                        render={(props) => (<HorizontalLinearStepper data={data} />)}
                    />
                </div>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} sm={6} md={4}>
                        {!buttonClicked &&
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{ bgcolor: "#24346c" }}
                                onClick={handleClick}
                            >
                                Club-Registration
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Box>
        </div>;

        // return <div>Loading...</div>;
    }

    const instituteData = data.data;

    return (
        <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item>
                <Typography variant="h4">Institute Information</Typography>
            </Grid>
            {instituteData && (
                <Grid item>
                    <TableContainer component={Card}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">Institute ID:</TableCell>
                                    <TableCell>{instituteData.institute_id}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Institute Name:</TableCell>
                                    <TableCell>{instituteData.institute_name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Institute Address:</TableCell>
                                    <TableCell>{instituteData.institute_address}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Institute Type:</TableCell>
                                    <TableCell>{instituteData.institute_type}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            {instituteData && instituteData.club_admin_member && (
                <>
                    <Grid item>
                        <Typography variant="h4">Club Admin Members</Typography>
                    </Grid>
                    <Grid item>
                        <TableContainer component={Card}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Designation</TableCell>
                                        <TableCell>Contact</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Gender</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {instituteData.club_admin_member.map((member) => (
                                        <TableRow key={member.email}>
                                            <TableCell>{member.name}</TableCell>
                                            <TableCell>{member.designation}</TableCell>
                                            <TableCell>{member.contact}</TableCell>
                                            <TableCell>{member.email}</TableCell>
                                            <TableCell>{member.gender}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

export default ClubDetails;
