import React from 'react';
import { Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const StarRating = ({ rating }) => {
  const stars = [];

  // Determine the full, half, and empty stars based on the rating
  for (let i = 1; i <= 5; i++) {
    if (rating >= i) {
      stars.push(<StarIcon key={i} style={{ color: '#FFD700' }} />); // Full star
    } else if (rating >= i - 0.5) {
      stars.push(<StarHalfIcon key={i} style={{ color: '#FFD700' }} />); // Half star
    } else {
      stars.push(<StarBorderIcon key={i} style={{ color: '#FFD700' }} />); // Empty star
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {stars.map((star, index) => (
        <span key={index}>{star}</span>
      ))}
      <Typography sx={{ marginLeft: '8px', fontSize: '18px', color: '#FFD700' }}>
        {rating.toFixed(1)}
      </Typography>
    </Box>
  );
};

export default StarRating;
