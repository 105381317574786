import React, { useState,useEffect } from "react";
import Button from "@mui/material/Button";
import {
  getPaymentToken,
  getPaymentTokenStudent,
} from "../../../action/islpAction";
import PopupPayment from "./PopupPayment";
import { TOAST_LOADING } from "../../../utils";
import { HOST_URL } from "../../../action/api";
function Paytm({
  team_id,
  totalAmount,
  handleNext,
  P_from,
  address,
  address_id,
  paytmType,
  level_code,
  addressAdded
}) {
  const [orderId, setOrderID] = useState();
  const [txnToken, setTxnToken] = useState();
  const [popup, setPopup] = useState(false);


  const handlePayment = async () => {
    TOAST_LOADING("Please wait...");

    try {
      const response = await fetch(`${HOST_URL}ispl/team/payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          teamSize: team_id, // Adjust the property name based on your backend requirements
          totalAmount: totalAmount,
          // Add other relevant payment information
        }),
      });

      if (response.ok) {
        console.log("Payment successful");
        // Handle successful payment (update state, navigate, etc.)
      } else {
        console.error("Payment failed");
        // Handle failed payment (update state, show error message, etc.)
      }
    } catch (error) {
      console.error("Error making payment:", error);
      // Handle other errors during the payment process
    }
  };

  // const initiatePayment = () => {
  //   TOAST_LOADING("Please wait...");
  //   P_from === "ispl"
  //     ? getPaymentToken({ team_id: team_id, amount: totalAmount})
  //     : getPaymentTokenStudent({
  //         address_id: 1,
  //         product_code: level_code,
  //         amount: totalAmount,
  //       })
  //         .then((response) => {
  //           if (response.status === 200) {
  //             // console.log(" response.data", response.data);
  //             const { orderId, txnToken } = response.data;
  //             setOrderID(orderId);
  //             setTxnToken(txnToken);
  //             setTimeout(() => {
  //               setPopup(true);
  //             }, 500);
  //           }
  //         })
  //         .catch(function (error) {
  //           console.log(error);
  //         });
  // };


  const initiatePayment = () => {
    TOAST_LOADING("Please wait...");

    getPaymentToken({
      team_id: team_id,
      amount: totalAmount,
    })
      .then((response) => {
        if (response.status === 200) {
          const { orderId, txnToken } = response.data;
          setOrderID(orderId);
          setTxnToken(txnToken);
          setTimeout(() => {
            setPopup(true);
          }, 500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    // You can add additional logic here if needed
  }, [address,address_id]);
  return (
    <>
      {popup ? (
        <PopupPayment
          orderId={orderId}
          txnToken={txnToken}
          popup={popup}
          setPopup={setPopup}
        />
      ) : null}
      <Button
        variant="contained"
        onClick={() => (P_from === "ispl" ? handlePayment() :  initiatePayment())}
        disabled={address == 0 ? true : false}
        // disabled={!address || address === 0}
        // disabled={!addressAdded}
      >
        {P_from === "ispl" ? "Pay Now" : "Total Amount"}
      </Button>
    </>
  );
}

export default Paytm;
