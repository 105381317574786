import  {useState} from 'react';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import { Link as RouterLink } from "react-router-dom";
// import { FormGroup } from '@mui/material';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';
import { HOST_URL } from "../../action/api";

export default function FormDialog() {
  const [open, setOpen] = useState(false);
  // const [message, setMessage]= useState('false')

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
);

  const postMessage = () => {

    var token = localStorage.getItem('token');


    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "message": editorState
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(`${HOST_URL}ispl/help`, requestOptions)
      .then(response => response.json())
      .then(result => {if(result.success){
        alert(result.message) 
        handleClose()
      }})
      .catch(error => console.log('error', error));
    
  }; 

  const handleClose = () => {
    setOpen(false);
  };
    

 

  return (
    <div>

        <Button onClick={(e)=>setOpen(true)}sx={{
              color: "#e9ebea", bordeer: 0, marginLeft: 10, fontSize: "17px", textTransform: 'none', ':hover': {
                color: "#ffffff", textTransform: "capitalize", textDecoration: "none", fontfamily: "Rubik", fontweight: "400",
              },
            }}> Help</Button>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}

  
      <Dialog open={open}>
        <DialogTitle>SendMsg</DialogTitle>
        <DialogContent>
        <DialogContentText>
            
          </DialogContentText>
          {/* <TextField onChange={(e)=>{setMessage(e.target.value)}}
            autoFocus
            margin="dense"
            id="name"
            label="text"
            multiline={true}
            rows={3}
            type="email"
            fullWidth
            variant="standard"
          /> */}


              {/* <FormGroup className="mt-2">
                        <textarea 
                        onChange={(e)=>{setMessage(e.target.value)}}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="text"
                        multiline={true}
                        rows={4}
                        type="email"
                        fullWidth
                        variant="standard"
                        >{}</textarea>
                  </FormGroup> */}
      
           <Editor
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                wrapperStyle={{ width: 1000, border: "1px solid black" }}
                // onChange={(e)=>{setMessage(e.target)}}
                editorState={editorState}
                onEditorStateChange={setEditorState}
            />
        </DialogContent>

       





        <DialogActions>
          <Button onClick={()=>setOpen(false)}>Cancel</Button>
          <Button onClick={()=>postMessage (false)}>submit</Button>
         
        </DialogActions>
      </Dialog>
    </div>
  );
}



// import react, {Component} from "react";
// // import  react,{useState} from 'react';
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// // import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import { Link as RouterLink } from "react-router-dom";


// class HelpISPL extends Component{
//   constructor(){
//     super();
//     this.state={
//       open: false,
//       message: 'hari'
//     }
    
//   }

// componentDidMount(){
//    fetch('http://3.110.135.217:8000/ispl/help',{method:"POST"}).then((res)=>res.json()).then((result)=>{
//      console.log('help',result)
//    })   
// }



//   render(){
//     return(
//     <div> 
//      <Button onClick={(e)=>this.setState({open:!this.state.open})}sx={{
//               color: "#e9ebea", bordeer: 0, marginLeft: 10, fontSize: "17px", textTransform: 'none', ':hover': {
//                 color: "#ffffff", textTransform: "capitalize", textDecoration: "none", fontfamily: "Rubik", fontweight: "400",
//               },
//             }}> Help</Button>
//       {/* <Button variant="outlined" onClick={handleClickOpen}>
//         Open form dialog
//       </Button> */}
//       <Dialog open={this.state.open} onClose={()=>{this.setState({open:!this.state.open})}}>
//         <DialogTitle>Help</DialogTitle>
//         <DialogContent>

//          <h1> {this.state.message } </h1> 

          





        





          
          
          
          
//         </DialogContent>

       





//         <DialogActions>
//           <Button onClick={()=>{this.setState({open:!this.state.open})}}>Cancel</Button>
         
//         </DialogActions>
//       </Dialog>
    
    
//      </div>)

//   }
// }
// export default HelpISPL;

