import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  MenuItem,
  DialogContent, // Add MenuItem component
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { TOAST_WARN, TOAST_SUCCESS } from "../../utils/index";
import { HOST_URL } from "../../action/api";

// List of Indian states
const indianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

export default function AddNewAddress({
  initialAddress,
  onAddressSave,
  onCancel,
}) {
  const [open, setOpen] = useState(true);
  const [address, setAddress] = useState(initialAddress.address || "");
  const [district, setDistrict] = useState(initialAddress.district || "");
  const [state, setState] = useState(initialAddress.state || "");
  const [pinCode, setPinCode] = useState(initialAddress.pincode || "");

  const [pinCode_err, setPinCode_err] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!address || !district || !state || !pinCode) {
      TOAST_WARN("Please fill in all required details.");
      return;
    }

    const updatedAddress = {
      address,
      district,
      state,
      pincode: pinCode,
      address_id: initialAddress.address_id, // Include the address ID for identification
    };

    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: initialAddress.address_id ? "PUT" : "POST",
      headers: myHeaders,
      body: JSON.stringify(updatedAddress),
      redirect: "follow",
    };

    fetch(`${HOST_URL}student/address`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          TOAST_SUCCESS(result.message);
        } else {
          TOAST_WARN(result.message);
        }
      })
      .catch((error) => console.log("error", error));

    onAddressSave(updatedAddress);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    onCancel();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          textAlign="center"
          justifyContent={"space-between"}
          display={"flex"}
          color="#24346c"
        >
          {initialAddress.address_id
            ? "Edit Delivery Address"
            : "Add Delivery Address"}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container mt={1} mb={3} spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  id="address"
                  label="Address"
                  multiline
                  rows={2}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="district"
                  label="District"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select // Change to select field
                  required
                  fullWidth
                  id="state"
                  label="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <MenuItem value="">Select State</MenuItem>
                  {indianStates.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  error={pinCode_err}
                  id="zipCode"
                  name="zipCode"
                  label="Zip / Postal / Pin code"
                  fullWidth
                  autoComplete="Zip / Postal code"
                  variant="outlined"
                  helperText={
                    pinCode_err
                      ? "Please enter a 6-digit pin number (numbers only)"
                      : ""
                  }
                  InputProps={{
                    inputProps: {
                      maxLength: 6,
                    },
                  }}
                  value={pinCode}
                  onChange={(e) => {
                    setPinCode(e.target.value.replace(/[^0-9]/g, ""));
                    setPinCode_err(false);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={2}>
              <Button variant="contained" color="primary" type="submit">
                {initialAddress.address_id ? "Save Changes" : "Add Address"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
