import * as React from "react";
import { useState,useEffect } from "react";
import {
    Grid,
    Paper,
    Typography,
    List,
    ListItemIcon,
    ListItem,
    ListItemText,
    Box,
} from "@mui/material";
import task from "../assets/task.jpg";
import { useHistory } from "react-router-dom";
import VideoUpload from "../ISPLDashboard/VideoUpload";
import TaskDoc from "./TaskDocument";
import StemConcepts from "./Stem";
import UploadTaskVideo from "./UploadTaskVideo";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import PDFViewer from './PdfViewer';
import CryptoJS from "crypto-js";
const useStyles = makeStyles({
    taskVideoText: {
        "&:hover": {
            color: "blue", // Change this to the desired color
            cursor: "pointer", // Optional: Change cursor style on hover
        },
    },
});


export default function TaskPage(props) {
    const tasks = props.tasks;
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState(null);
    const classes = useStyles();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selectedTask = searchParams.get('task'); // Get the selected task name from URL parameters

console.log("dddddd",selectedTask)  
    const taskVideoUrls = {
        'TBF_L01_T01': 'https://www.youtube.com/embed/CMvrQk4FbkY',
        'TBF_L01_T02': 'https://www.youtube.com/embed/X2sxdDe-vEM',
        'TBF_L02_T01': 'https://www.youtube.com/embed/nzoQ7lDdwdc',
        'TBF_L02_T02': 'https://www.youtube.com/embed/9nw-el3SCXg',
        'TSC_L01_T01': 'https://www.youtube.com/embed/9GWVQQzjagc',
        'TSC_L01_T02': 'https://www.youtube.com/embed/6uWnsLhA-Ec',
        'TSC_L02_T01': 'https://www.youtube.com/embed/RCvy4CYqJ3I',

    }

    const taskUnboxingUrls = {
        'TBF_L01_T01': 'https://www.youtube.com/embed/FOrE6sohnDo',
        'TBF_L01_T02': 'https://www.youtube.com/embed/FOrE6sohnDo',
        'TBF_L02_T01': 'https://www.youtube.com/embed/Iq_YOhcIgvY',
        'TBF_L02_T02': 'https://www.youtube.com/embed/Iq_YOhcIgvY',
        'TSC_L01_T01': 'https://www.youtube.com/embed/MB5A67_KuzU',
        'TSC_L01_T02': 'https://www.youtube.com/embed/MB5A67_KuzU',
        'TSC_L02_T01': 'https://www.youtube.com/embed/s0vhXj6WK0M',
        'TSC_L02_T02': 'https://www.youtube.com/embed/s0vhXj6WK0M'
    }

    const taskStemUrls = {
        'TBF_L01_T01': "https://www.youtube.com/embed/6Tkz5oqEh74",
        'TBF_L01_T02': "https://www.youtube.com/embed/6Tkz5oqEh74",
        'TBF_L02_T01': 'https://www.youtube.com/embed/Iq_YOhcIgvY',
        'TBF_L02_T02': 'https://www.youtube.com/embed/Iq_YOhcIgvY',
        'TSC_L01_T01': 'https://www.youtube.com/embed/MB5A67_KuzU',
        'TSC_L01_T02': 'https://www.youtube.com/embed/MB5A67_KuzU'
    }


    // const taskPdfUrls = {
    //     'TBF_L01_T01': 'https://drive.google.com/file/d/1L3OkoCR-75zNDXCfKTFQtZelBSYxdFUM/preview',
    //     'TBF_L01_T02': 'https://drive.google.com/file/d/14hncF1c9Ia6k_q5KQn8w7Br7rO5x2uJ9/preview',
    //     'TBF_L01_T03': 'https://drive.google.com/file/d/15uJYE123uUTcnnCh8yUjM16t-MgAXFD0/preview',
    //     'TBF_L01_T04': 'https://drive.google.com/file/d/1d_LR5brXAeImftGJn54RtdI5uIYEShWs/preview',
    //     'TBF_L01_T05': 'https://drive.google.com/file/d/1AjSiu3R9D1R8A8yqHAVphGL2EYg-BBRl/preview',
    //     'TBF_L01_T06': 'https://drive.google.com/file/d/1kxFMBslVVfjy3Ti7T_QBZTi090WUv9D1/preview',
    //     'TBF_L01_T07': 'https://drive.google.com/file/d/1iOJKDlDuHwipdCwFabmNr8ALGR_6pRb_/preview',
    //     'TBF_L01_T08': 'https://drive.google.com/file/d/1knKebzJE-YQJVDkPp-EvNY2uIQDHpsgT/preview',
    //     'TBF_L01_T09': 'https://drive.google.com/file/d/163TcpkU_NisnBfrsNDvF6JbeY6VxYvbh/preview',
    //     'TBF_L01_T10': 'https://drive.google.com/file/d/19t73kWxjUXRqKVIygDjADFccmeQ9A_yx/preview',
    //     'TBF_L02_T01': 'https://drive.google.com/file/d/1i-Kj79tEVi8u8lvtRvqko7kzF7ZLVPSF/preview',
    //     'TBF_L02_T02': 'https://drive.google.com/file/d/18NhWEybkfW0FcEXnTWc9MwXvDzlTX1ib/preview',
    //     'TBF_L02_T03': 'https://drive.google.com/file/d/1U0T9e4j0HEflSSweFtuZhVMuBzG7dzJV/preview',
    //     'TBF_L02_T04': 'https://drive.google.com/file/d/16DPJDrVQVT0Jstsq2veY0hwvLe3UIZWx/preview',
    //     'TBF_L02_T05': 'https://drive.google.com/file/d/1UdZCva3cUfjKh0v-T6giWgR0e2mtkQJ7/preview',
    //     'TBF_L02_T06': 'https://drive.google.com/file/d/1cyLMFgGrahj-PrsZ2q2bVLMKqtg0rXzk/preview',
    //     'TBF_L02_T07': 'https://drive.google.com/file/d/11Jv11gmBLA341trxGBeYcqpOdKreUVsp/preview',
    //     'TBF_L02_T08': 'https://drive.google.com/file/d/1Kz6OklCI0VRFPri9VjB3i_lkn3g956KY/preview',
    //     'TBF_L02_T09': 'https://drive.google.com/file/d/15AYuPZ3N_9foLVtr7IoSucWfN9qrvOsF/preview',
    //     'TBF_L02_T10': 'https://drive.google.com/file/d/1BlKMzcUagG9G8GxIc7SoprcFrgSBtXHz/preview',
    //     'TSC_L01_T01': 'https://drive.google.com/file/d/17DILj-A9fWqTKkYiL6jjhkrRSsqyLY29/preview',
    //     'TSC_L01_T02': 'https://drive.google.com/file/d/1qfeqbjcvPbiwb8QlbfID4vY5jE5Mxoff/preview',
    //     'TSC_L01_T03': 'https://drive.google.com/file/d/1dSlYSXNoQxctCvPnvkLWhCT2UeI4IrNh/preview',
    //     'TSC_L01_T04': 'https://drive.google.com/file/d/1XN7zRfk7UEu1atcHCjwgHZCKbQBhxITl/preview',
    //     'TSC_L01_T05': 'https://drive.google.com/file/d/15toKY32xJsyG6ZCkmzCHfbQhnlCWBChC/preview',
    //     'TSC_L01_T06': 'https://drive.google.com/file/d/1-LeL-mZyWGw_2OgW566GGHF0y1vTPECY/preview',
    //     'TSC_L01_T07': 'https://drive.google.com/file/d/1wXypJHB-ekZXxvSH8-VGDL46MFUL5taM/preview',
    //     'TSC_L01_T08': 'https://drive.google.com/file/d/1aUbs7PLNKqPlhJGZtZhsac-H2jqa7JpH/preview',
    //     'TSC_L01_T09': 'https://drive.google.com/file/d/1hYh0vEp-G5GNL4gDpMfI239BRp4rBZd_/preview',
    //     'TSC_L01_T10': 'https://drive.google.com/file/d/1gpUAIX7bOHlLpsbHble4g0va40C_LesU/preview',
    //     'TSC_L02_T01': 'https://drive.google.com/file/d/1zrBzhITvgUZOmsKNd96Spl9jP8jyJC_r/preview',
    //     'TSC_L02_T02': 'https://drive.google.com/file/d/1vEm8AqSSylVBls-0sq95f0a4fvIsb-Od/preview',
    //     'TSC_L02_T03': 'https://drive.google.com/file/d/1N1H1COPlGpKopxZPc3990azwyt9FNl70/preview',
    //     'TSC_L02_T04': 'https://drive.google.com/file/d/1xjgNAFa-Mrh7muilhTysFt40f0Jpz6SA/preview',
    //     'TSC_L02_T05': 'https://drive.google.com/file/d/18ybWwE16QQJDpuqZbKlrkB8sq8v61xhr/preview',
    //     'TSC_L02_T06': 'https://drive.google.com/file/d/1O1fMvmlwCbbvKQM3awLD84Iw_nHp5Soq/preview',
    //     'TSC_L02_T07': 'https://drive.google.com/file/d/1RxsYKrQN86s7lh3ZN2KXHrziEIWRy2Nl/preview',
    //     'TSC_L02_T08': 'https://drive.google.com/file/d/1SEPv8vfK6N1vw8IJCGxlrTbCDHhXtXNb/preview',
    //     // Add more task URLs as needed
    // };
    const handleTabClick = (tabName) => {
        if (selectedTab === tabName) {
            setSelectedTab(null); // Close the tab if it's already open
        } else {
            setSelectedTab(tabName);
        }
    };

    const isTabOpen = (tabName) => {
        return selectedTab === tabName;
    };
    
    const [decryptedData, setDecryptedData] = useState("");
    useEffect(() => {
        const encryptedData = props?.tasks?.task_media?.[0]?.media_path;
        const base64Key ='jW1DMN6Gd62k4pQdssZBG0y1M7SldkWZTYIEENToswE=';
      
        const decryptData = () => {
            
            if (encryptedData) {
                const key = CryptoJS.enc.Base64.parse(base64Key);
      
                const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
                const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
                const ciphertext = CryptoJS.lib.WordArray.create(
                    dataBytes.words.slice(4)
                );
      
                const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });
                const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
                setDecryptedData(decryptedText);
                
            }
        };
      
        decryptData();
    }, 
    // [props?.tasks?.task_media?.[0]?.media_path]);
    [( (props?.tasks?.task_media?.[0]?.media_path) ? (props?.tasks?.task_media )  : [])]);
    //[props?.tasks.task_media[0]?.media_path]
    
    console.log("anil kumar takpage",props?.tasks)

    return (
        <Grid container spacing={2} >
            {/* <Grid item xs={12} sm={6}>
        <img src={task} alt="Task" />
      </Grid> */}
            {/* <Grid item xs={12} sm={6}>
        <Typography variant="h3">{tasks.task_name}</Typography>
        <Typography>Learning Concepts: {tasks.learning_concepts}</Typography>
      </Grid> */}
            <Grid item xs={12}>
                <List>
                    <ListItem button onClick={() => handleTabClick("Task Video")}
                        className={classes.taskVideoText}
                        style={{
                            background: "linear-gradient(#0B0B0D, #636379)",
                            marginLeft: "10%",
                            width: "80%",
                            // backgroundColor: "#0B0B0D",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                            // borderColor: "black"
                        }}
                    >
                        <ListItemIcon>
                            <AddCircleIcon style={{ color: "white" }} />

                        </ListItemIcon>
                        <ListItemIcon>
                            {/* <ExpandMoreIcon style={{ color: "black", marginLeft:"1850%",fontSize: "45px" }}/> */}
                        </ListItemIcon>
                        <ListItemText primary="TASK VIDEO" primaryTypographyProps={{ style: { fontSize: "17px", marginLeft: "-7%", color: "white" } }} />
                    </ListItem>
                    {isTabOpen("Task Video") && (
                        <Box p={2} style={{ marginLeft: "9%", width: "82%" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontSize: "180%", fontFamily: "cursive" }}>Task Video</span><br></br>
                                <iframe
                                    width="300"  
                                    height="200" 
                                    src={taskVideoUrls[selectedTask]}
                                    title="Task Video Player"
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe><br></br>

                                {/* <PDFViewer task={selectedTask} pdfUrl={taskVideoUrls[selectedTask]} /> */}
                                <span style={{ fontSize: "180%", fontFamily: "cursive" }}>Unboxing Video</span><br></br>
                                <iframe
                                    width="300"  
                                    height="200" 
                                    src={taskUnboxingUrls[selectedTask]}
                                    title="Task Video Player"
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe><br></br>

                                {/* <PDFViewer task={selectedTask} pdfUrl={taskUnboxingUrls[selectedTask]} /> */}
                                <span style={{ fontSize: "180%", fontFamily: "cursive" }}>STEM Concept Video</span><br></br>
                                <iframe
                                    width="300"  
                                    height="200" 
                                    src={taskStemUrls[selectedTask]}
                                    title="Task Video Player"
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>

                                {/* <PDFViewer task={selectedTask} pdfUrl={taskStemUrls[selectedTask]} /> */}
                            </div>
                        </Box>
                    )}
                    {/* {isTabOpen("Task Video") && (
                        <Box p={2} style={{ marginLeft: "9%" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontSize: "180%", fontFamily: "cursive" }}>Task Video</span>
                                <br></br>
                                <iframe
                                    width="500"
                                    height="300"
                                    src="https://www.youtube.com/embed/CMvrQk4FbkY"
                                    title="YouTube Video Player"
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe><br></br>
                                <span style={{ fontSize: "180%", fontFamily: "cursive" }}>Unboxing Video</span><br></br>
                                <iframe
                                    width="500"
                                    height="300"
                                    src="https://www.youtube.com/embed/FOrE6sohnDo"
                                    title="Unboxing Video Player"
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe><br></br>
                                <span style={{ fontSize: "180%", fontFamily: "cursive" }}>STEM Concept Video</span><br></br>
                                <iframe
                                    width="500"
                                    height="300"
                                    src="https://www.youtube.com/embed/6Tkz5oqEh74"
                                    title="STEM Concept Video Player"
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </Box>
                    )} */}
                    <br></br>
                    <ListItem button onClick={() => handleTabClick("Task Documents")}
                        className={classes.taskVideoText}
                        style={{
                            background: "linear-gradient(#2189FF, #143B67)",
                            marginLeft: "10%",
                            width: "80%",
                            // backgroundColor: "#2189FF",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                            // borderColor: "black"
                        }}
                    >
                        <ListItemIcon>
                            <AddCircleIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemIcon>
                            {/* <ExpandMoreIcon style={{ color: "black", marginLeft:"1850%",fontSize: "45px" }}/> */}
                        </ListItemIcon>
                        <ListItemText primary="TASK DOCUMENTS" primaryTypographyProps={{ style: { fontSize: "17px", marginLeft: "-7%", color: "white" } }} />
                    </ListItem>
                    {/* {isTabOpen("Task Documents") && (
                        <Box p={2} style={{ marginLeft: "9%", width: "82%" }}>
                            <TaskDoc />
                        </Box>
                    )} */}
                    {isTabOpen("Task Documents") && (
                        <Box p={2} style={{ marginLeft: "9%", width: "82%" }}>
                            {decryptedData && <PDFViewer  pdfUrl={decryptedData} />}
                        </Box>
                    )}
                    <br></br>
                    <ListItem button onClick={() => handleTabClick("STEM")}
                        className={classes.taskVideoText}
                        style={{
                            background: "linear-gradient(#FF4ECF, #2D104E)",
                            marginLeft: "10%",
                            width: "80%",
                            // backgroundColor: "#9F4DFd",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                            // borderColor: "black"
                        }}
                    >
                        <ListItemIcon>
                            <AddCircleIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemIcon>
                            {/* <ExpandMoreIcon style={{ color: "black", marginLeft:"1850%",fontSize: "45px" }}/> */}
                        </ListItemIcon>
                        <ListItemText primary="STEM" primaryTypographyProps={{ style: { fontSize: "17px", marginLeft: "-7%", color: "white" } }} />
                    </ListItem>
                    {isTabOpen("STEM") && (
                        <Box p={2} style={{ marginLeft: "9%", width: "82%" }}>
                            <StemConcepts stemData={tasks} />
                        </Box>
                    )}
                    <br></br>
                    <ListItem button onClick={() => handleTabClick("Upload Video")}
                        className={classes.taskVideoText}
                        style={{
                            background: "linear-gradient(#FC4B2F, #521A11)",
                            marginLeft: "10%",
                            width: "80%",
                            // backgroundColor: "#F32707",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                            // borderColor: "black"
                        }}
                    >
                        <ListItemIcon>
                            <AddCircleIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemIcon>
                            {/* <ExpandMoreIcon style={{ color: "black", marginLeft:"1850%",fontSize: "45px" }}/> */}
                        </ListItemIcon>
                        <ListItemText primary="UPLOAD VIDEO" primaryTypographyProps={{ style: { fontSize: "17px", marginLeft: "-7%", color: 'white' } }} />
                    </ListItem>
                    <br></br><br></br>

                    {isTabOpen("Upload Video") && (
                        <Box p={2} style={{ marginLeft: "9%", width: "82%" }}>
                            <Box >
                                <p style={{ fontFamily: "revert", fontWeight: "bold", fontSize: "180%", color: "black" }}>Instructions for Upload:</p>
                                After completing the STEM & Robotics gaming task using the given hardware, students are required to create a demonstration video showcasing their work. This video will serve as a record of their performance and will be used for evaluation and feedback.
                                <br></br><br></br>
                                <p style={{ fontFamily: "revert", fontWeight: "bold", fontSize: "180%", color: "black" }}>Review by iLearnPlace STEM Mentor:</p>
                                Once the video is uploaded, it will be carefully reviewed by a qualified STEM mentor from iLearnPlace. This mentor will assess the student's performance, problem-solving abilities, creativity, and adherence to task guidelines. They will provide valuable feedback to help the student improve their skills and understanding of STEM and Robotics concepts.
                                <br></br><br></br>
                                <p style={{ fontFamily: "revert", fontWeight: "bold", fontSize: "180%", color: "black" }}>Student Rewards:</p>
                                Based on the quality of the uploaded video and the mentor's evaluation, students will receive rewards. These rewards will be in the form of iLearnPlace (ILP) points. ILP points can be accumulated and later redeemed for various STEM and Robotics hardware items.
                                <br></br><br></br>
                                <p style={{ fontFamily: "revert", fontWeight: "bold", fontSize: "180%", color: "black" }}>Benefits of the Reward System:</p>
                                The reward system serves multiple purposes. Firstly, it incentivizes students to actively participate and excel in STEM & Robotics tasks, promoting engagement and learning. Secondly, it provides tangible benefits by allowing students to earn ILP points, which can be used to acquire additional hardware items for further exploration and experimentation. This not only enhances their hands-on learning experience but also encourages continuous participation and improvement.
                                <br></br><br></br>
                                <p style={{ fontFamily: "revert", fontWeight: "bold", fontSize: "180%", color: "black" }}>Encouraging Skill Development:</p>
                                Through this process, students are encouraged to refine their skills and knowledge in the STEM and Robotics fields. They are motivated to take on challenges, think critically, and develop practical problem-solving abilities. This not only benefits the individual students but also contributes to the overall growth and advancement of STEM education.
                                <br></br><br></br>
                                In summary, the process of uploading a demonstration video after completing a STEM & Robotics gaming task with provided hardware is an integral part of the iLearnPlace program. It allows students to showcase their achievements, receive mentor feedback, and earn rewards in the form of ILP points, which can be used to acquire more STEM and Robotics hardware. This approach not only motivates students but also fosters a deeper understanding of STEM concepts and the development of essential skills.
                            </Box>
                            <UploadTaskVideo task={tasks} />
                        </Box>
                    )}
                </List>
                {/* <Grid item xs={12} sm={6}>
                    <Typography style={{ marginLeft: "-21%", paddingLeft: "80%", marginRight: "-59%", paddingRight: "-22%" }} variant="h3">{tasks.task_name}</Typography>
                    <Typography style={{ marginLeft: "25%", paddingLeft: "38%", marginRight: "-57%" }}>Learning Concepts: {tasks.learning_concepts}</Typography>
                </Grid> */}
            </Grid>
        </Grid>
    );
}
