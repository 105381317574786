import f1 from './img/15.png';
import f2 from './img/16.png'; // Make sure to import the missing image
import f3 from './img/17.png';
import f4 from './img/18.png';
import f5 from './img/19.png';
import f6 from './img/20.png';
import f7 from './img/21.png';
import t1 from './img/t1.jpeg';
import t2 from './img/t2.jpeg';
import t3 from './img/t3.jpeg';
import t4 from './img/t4.jpeg';
import t5 from './img/t5.jpeg';
import t6 from './img/t6.jpeg';

// Create an array of testimonials with images and descriptions
const testimonials = [
    {
      video:"https://www.youtube.com/embed/3esqyf0CfLY?si=eY9l8nS-9N2_Ab40",
      title: "Hidden STEM Skills",
      description: "Approved by Purdue University, Plugo Link helps kids level up their spatial reasoning and problem-solving skills while they play."
    },
    {
      video: "https://www.youtube.com/embed/DiOWfWNUb-Q?si=6nnqt3IyP0nS8Ojf",
      title: "Enhancing Cognitive Abilities",
      description: "Studies show that using Plugo Link enhances cognitive abilities in children through hands-on learning."
    },
    {
      video: "https://www.youtube.com/embed/ImBoTK6Bdvs?si=INa-XKK8dWVjJCA4",
      title: "Fun and Engaging",
      description: "Parents love how Plugo Link combines fun and learning, keeping their kids engaged for hours."
    },
    {
      video: "https://www.youtube.com/embed/Th5FvZw3LFw?si=FsJYvQg1sC23Ahkq",
      title: "Recommended by Teachers",
      description: "Teachers recommend Plugo Link as a great tool for improving problem-solving skills in a classroom setting."
    },
    {
      video: "https://www.youtube.com/embed/9R2HbCjNuSM?si=q3moTItcxTqpO4Ex",
      title: "Boosts Confidence",
      description: "Children using Plugo Link often show increased confidence in tackling new challenges and solving puzzles."
    },
    {
      video: "https://www.youtube.com/embed/mUqozcvGOro?si=TnRFzm07OcCyAM4_",
      title: "Easy to Use",
      description: "Plugo Link’s intuitive design makes it easy for kids to navigate and learn independently."
    },
    {
      video: "https://www.youtube.com/embed/OKxsypY5n1I?si=dtnewL8AcT2m43sr",
      title: "Easy to Use",
      description: "Plugo Link’s intuitive design makes it easy for kids to navigate and learn independently."
    },
    {
      video: "https://www.youtube.com/embed/C_FmKSWBS5k?si=TPAWbKe2rJL55sU6",
      title: "Easy to Use",
      description: "Plugo Link’s intuitive design makes it easy for kids to navigate and learn independently."
    },
    {
      video: "https://www.youtube.com/embed/pcC6KmOpGug?si=LrHtEXljrr6bCDaU",
      title: "Easy to Use",
      description: "Plugo Link’s intuitive design makes it easy for kids to navigate and learn independently."
    }
];

// Create an array of feature media with URLs and descriptions
const featuremedia = [
    { url: f1, desc: "Image 1: Description for image 15" },
    { url: f2, desc: "Image 2: Description for image 16" },
    { url: f3, desc: "Image 3: Description for image 17" },
    { url: f4, desc: "Image 4: Description for image 18" },
    { url: f5, desc: "Image 5: Description for image 19" },
    { url: f6, desc: "Image 6: Description for image 20" },
    { url: f7, desc: "Image 7: Description for image 21" },
];

// Export both featuremedia and testimonials
export { featuremedia, testimonials };
