import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Paper,
  Button,
  
  
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { postvideoUpload, fetchVideo } from "../../redux/action/action";

export default function VideoUpload() {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const { id, loading, videoUrl } = useSelector((state) => state.videoUpload);


  useEffect(() => {
    if (id) {
      dispatch(fetchVideo(id));
    }
  }, [id]);

  const handleFileChange = async () => {
    if (!selectedFile) {
      return;
    }
    const formData = new FormData();
    formData.append("video_path", selectedFile);
    dispatch(postvideoUpload(formData));
    setSelectedFile(null);
    document.getElementById("video-upload-input").value = "";
  };

  
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <Paper elevation={3}>
      <Box p={2}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            md={4}
            ml={2}
            mt={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "auto",
              borderRadius: "4px",
              border: "2px dashed gray",
              padding: "5px 20px 0px 0px",
              textAlign: "center",
              order: { xs: 2, md: 1 },
            }}
          >
            <form>
              <TextField
                type="file"
                id="video-upload-input"
                inputProps={{ accept: "video/*" }}
                fullWidth
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    padding: "5px 0px 20px 0px",
                    fontSize: "15px",
                  },
                }}
                onChange={handleFileSelect}
              />

              <Button
                variant="contained"
                startIcon={<CloudUpload />}
                onClick={handleFileChange}
                sx={{ margin: "20px 0px" }}
              >
                Upload Video
              </Button>
            </form>
          </Grid>
          <Grid item xs={12} md={7} sx={{ order: { xs: 1, md: 2 } }}>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height={300}
              >
                <CircularProgress />
              </Box>
            ) : videoUrl ? (
              <Box
                width="100%"
                height={0}
                paddingTop="56.25%"
                position="relative"
                sx={{ ml: { xs: 0, md: 2 } }}
              >
                <video
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src={`http://3.110.135.217:8000${videoUrl}`}
                  controls
                />
              </Box>
            ) : (
            //   <Typography variant="h6"></Typography>
            ""
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
