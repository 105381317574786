import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  // Container,
  Collapse,
  // Button,
} from "reactstrap";
import { Icon } from "@material-ui/core";

import ScrollspyNav from "./scrollSpy";

//stickey header
import "react-sticky-header/styles.css";
import StickyHeader from "react-sticky-header";
import Login from "./login";
import { withRouter } from 'react-router-dom';
import IsplReg from "./Ispl";


class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
      isActive: false
    };
  }

  handleClick = () => {
    this.props.history.push('/dashboard/registration');
  }
  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  closeToggle = (idnm) => {
    this.setState({ isOpenMenu: false });
    this.props.setIslp(idnm === "ispl" ? true : false);
    if(idnm==="ispl"){
      this.setState({isActive:true});
  }
    else{this.setState({isActive:false})}
  };

  render() {
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    let targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });
    this.state.isActive? localStorage.setItem("isplActive", true):localStorage.setItem("isplActive", false)
    return (
      <React.Fragment>
        <StickyHeader
          header={
            <Navbar
              container
              expand="lg"
              fixed="top"
              className={
                "navbar-custom sticky sticky-dark " + this.props.navClass
              }
              style={{backgroundColor:"black"}}
            >
              {/* <Container> */}
              <NavbarBrand className="" href="/">
                <Icon>
                  <img
                    src="assets/images/logo/ilp.svg"
                    height={44}
                    width={110}
                    alt="logo"
                  />
                </Icon>
              </NavbarBrand>
              <NavbarToggler onClick={this.toggle}>
                <i className="mdi mdi-menu"></i>
              </NavbarToggler>

              <Collapse
                id="navbarCollapse"
                isOpen={this.state.isOpenMenu}
                navbar
              >
                <ScrollspyNav
                  scrollTargetIds={targetId}
                  scrollDuration="800"
                  headerBackground="true"
                  activeNavClass={"active"}
                  className="navbar-collapse"
                  isActive={this.state.isActive}
                >
                  <Nav navbar className="navbar-center" id="mySidenav">
                    {this.props.navItems.map((item, key) => (
                      <NavItem
                        key={key}
                        className={!this.state.isActive ?item.navheading === "Home" && !this.state.isActive ? "active" : "" : item.navheading === "ISPL-2023" ? "active":""}
                      >
                        <NavLink
                          href={"#" + item.idnm}
                          onClick={() => this.closeToggle(item.idnm)}
                        >
                          {" "}
                          {item.navheading}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <div></div>
                  <div className="nav-button ms-auto">
                    <Login />
                  </div><br></br>
                  <div className="nav-button ms-auto">
                    {/* <button
                      className="btn btn-primary"
                      type="button"
                      style={{
                        // backgroundColor: "aqua"
                        borderRadius: 50,
                      }}
                      onClick={() =>
                        this.handleClick()
                      }
                    > */}
                      <IsplReg />
                    {/* </button> */}
                  </div>
                </ScrollspyNav>
              </Collapse>
              {/* </Container> */}
            </Navbar>
          }
          stickyOffset={-100}
        ></StickyHeader>
      </React.Fragment>
    );
  }
}

export default withRouter(NavbarPage);
