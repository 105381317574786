import React from 'react';
import { Typography, Card, CardContent } from '@material-ui/core';

const cardColors = [
  'linear-gradient(#B1A579,#27241A)',
  'linear-gradient(#6868B5,#0E0E16)',
  'linear-gradient(#9B749F,#0A080B)',
  'linear-gradient(#A17A78,#0A080B)',
  'linear-gradient(#57738A,#151212)',

  // '#FFCDD2',
  // '#F8BBD0',
  // '#E1BEE7',
  // '#D1C4E9',
  // '#C5CAE9',
  // '#BBDEFB',
  // '#B3E5FC',
  // '#B2EBF2',
  // '#B2DFDB',
  // '#C8E6C9',
];

const StemConcepts = ({ stemData }) => {
  let nan

  const renderCard = (title, content, index) => {
    if (content === nan) {
      return null; // Skip rendering the card if content is null
    }

    return (
      <Card style={{ marginBottom: '16px',
       background: cardColors[index]}}>
        <CardContent>
          <Typography variant="h6" gutterBottom style={{color:'white'}}>
            {title}
          </Typography>
          <Typography variant="body1" gutterBottom style={{color:'white'}}>
            {content}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        <b>STEM Concepts</b>
      </Typography>

      {renderCard('Science', stemData.science, 0)}
      {renderCard('Technology', stemData.technology, 1)}
      {renderCard('Engineering', stemData.engineering, 2)}
      {renderCard('Maths', stemData.maths, 3)}
      {renderCard('Key Skills', stemData.key_skills, 4)}
    </div>
  );
};

export default StemConcepts;
