import React, { Component } from "react";
import { Container, Row, Col,NavLink } from "reactstrap";


class WebsiteDesc extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section section-lg bg-web-desc">
          <div className="bg-overlay"></div>
          <Container>
            <Row>
              <Col lg={12} className="text-center">
                <h2 className="text-white">Teach your kids how to think, not what to think</h2>
                <p className="pt-3 home-desc">
                  We believe this focus on mastering skills, not just acquiring them, is essential to 
                  the future of our global economy
                </p>
                <NavLink
                  href="#pricing"
                  // className="btn btn-white mt-4 waves-effect waves-light mb-5"
                >
                  {/* View Plan & Pricing */}
                  <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() =>
                          this.props.setIslp(true)
                        }
                      >
                        ISPL-2023 Details 
                      </button>
                </NavLink>
              </Col>
            </Row>
          </Container>
          <div className="bg-pattern-effect">
            <img src="assets/images/bg-pattern.png" alt="ILearnPlace" />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default WebsiteDesc;
