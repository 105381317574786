import React from "react";
import { useState } from "react";
import { Col } from "reactstrap";

const TestimonialsBox = ({ clients }) => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <React.Fragment>
      {clients.map((client, key) => (
        <Col lg={4} key={key}>
          <div className="testimonial-box hover-effect mt-4">
            <img
              src={client.image}
              alt="client"
              className="img-fluid d-block img-thumbnail rounded-circle"
            />
            <div className="testimonial-decs">
              <p className="text-muted text-center mb-0">
                {/* “{client.desc}” */}
                {showMore ? client.desc : `${client.desc.substring(0, 100)}`}
                <span className="btn" onClick={toggleShowMore}>
                  {showMore ? "Show less" : "... Know more"}
                </span>
              </p>
            </div>
            <h5 className="text-center text-uppercase pt-3">
              {client.title} -{" "}
              <span className="text-muted text-capitalize">
                {client.subTitle}
              </span>
            </h5>
          </div>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default TestimonialsBox;


// import React, { Component } from "react";
// import { Col } from "reactstrap";

// class TestimonialsBox extends Component {
//   render() {
//     return (
//       <React.Fragment>
//         {this.props.clients.map((client, key) => (
//           <Col lg={4} key={key}>
//             <div className="testimonial-box hover-effect mt-4">
//               <img
//                 src={client.image}
//                 alt="client"
//                 className="img-fluid d-block img-thumbnail rounded-circle"
//               />
//               <div className="testimonial-decs">
//                 <p className="text-muted text-center mb-0">“{client.desc}” </p>
//               </div>
//               <h5 className="text-center text-uppercase pt-3">
//                 {client.title} -{" "}
//                 <span className="text-muted text-capitalize">
//                   {client.subTitle}
//                 </span>
//               </h5>
//             </div>
//           </Col>
//         ))}
//       </React.Fragment>
//     );
//   }
// }

// export default TestimonialsBox;
