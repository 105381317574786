import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        maxWidth: '100%',
        marginTop: '20px'
    },
    AppBar: {
        justifyContent: 'center',
        backgroundColor: '#272a33'
    }
});

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function TermsCondition(props) {
    const classes = useStyles();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <div className={classes.root}>
                <CssBaseline />
                <HideOnScroll {...props}>
                    <AppBar >
                        <Toolbar className={classes.AppBar}>
                            <Typography variant="h6">TERMS & CONDITIONS</Typography>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <Toolbar />
                <Container maxWidth="md">



                    <Typography variant="body2" gutterBottom> Welcome to Almondai Technologies  Private Limited (“Ilearnplace”). If you continue to browse and use this
                        website you are agreeing to comply with and be bound by the following terms and conditions of use, which
                        together with our privacy policy govern Ilearnplace relationship with you in relation to this website.</Typography>

                    <Typography variant="body2" gutterBottom>
                        The term Ilearnplace refers to the owner of the website and the term 'you' refers to the user or viewer of
                        our website.
                    </Typography>

                    <Typography variant="body2" gutterBottom>The term “Services” refers to all Products available on the website for viewing and purchase by the user
                        and online portal hosted by Almondai Group - Ilearnplace to post pictures and videos of projects created
                        under the supervision of Ilearnplace as well as User Project Uploads ( defined in Para 12 of these Terms
                        and Conditions) .</Typography>

                    <Typography variant="body2" gutterBottom>The term “Products” refers to the robotic kits, parts and licenses available for sale on the website of
                        Ilearnplace</Typography>

                    <Typography variant="body2" gutterBottom>The Products sold are for personal use only. Commercial usage of the Products are not allowed without the
                        written consent from Ilearnplace. You can write to contact@Ilearnplace.com for request to use the product
                        for commercial purposes.</Typography>


                    <Typography variant="body2" gutterBottom><b>
                        The use of this website is subject to the following terms of use:</b></Typography>

                    <Typography variant="body2" gutterBottom>The content of the pages of this website is for your general information and use only. It is subject to
                        change without notice.</Typography>
                    <Typography variant="body2" gutterBottom>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness,
                        performance, completeness or suitability of the information and materials(including the components of the
                        product like batteries, etc) found or offered on this website for any particular purpose. You acknowledge
                        that such information and materials(including the components of the product like batteries, etc) may
                        contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to
                        the fullest extent permitted by law.</Typography>
                    <Typography variant="body2" gutterBottom>Your use of any information or materials (including the components of the product like batteries, etc) on
                        this website is entirely at your own risk, for which we shall not be liable. It shall be your own
                        responsibility to ensure that any products, services or information available through this website meet
                        your specific requirements.</Typography>
                    <Typography variant="body2" gutterBottom>This website contains material (including the components of the product like batteries, etc) which is owned
                        by or licensed to us. This material includes, but is not limited to, the design,layout, look, appearance
                        and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms
                        part of these terms and conditions</Typography>
                    <Typography variant="body2" gutterBottom>All trademarks reproduced in this website which are not the property of, or licensed to, the operator are
                        acknowledged on the website. Reproduction is prohibited other than in accordance with the copyright notice,
                        which forms part of these terms and conditions.</Typography>
                    <Typography variant="body2" gutterBottom>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence
                        From time to time this website may also include links to other websites. These links are provided for your
                        convenience to provide further information. They do not signify that we endorse the website(s). We have no
                        responsibility for the content of the linked website(s).</Typography>
                    You may create a link to this website from another website or document. However, the link to this website
                    <Typography variant="body2" gutterBottom> cannot be used or created in any manner prohibited under Para 16 of these Terms and Conditions
                        Your use of this website and any dispute arising out of such use of the website is subject to the laws of
                        India or other regulatory authority.</Typography>
                    <Typography variant="body2" gutterBottom>By using Ilearnplace website and service, you agree that you shall be governed by, and construed and
                        interpreted in accordance with, the laws of the Republic of India and shall be subject to the jurisdiction
                        of the courts of Chennai, India.</Typography>
                    <Typography variant="body2" gutterBottom>All disputes, actions, claims or controversies (collectively referred to as “Disputes”) relating in any way
                        to your use of any Ilearnplace service if not settled by the mutual agreement, be referred to arbitration
                        which shall be governed by the Arbitration and Conciliation Act, 1996.</Typography>
                    <Typography variant="body2" gutterBottom> All users who are registered with the online portal hosted by Ilearnplace are allowed to post videos of
                        their projects on the Ilearnplace website. Ilearnplace reserves the right to upload the videos and
                        photographs of the projects of the users enrolled in their online courses as part of its promotional
                        activities. Further, the users also reserve the right to upload their projects developed outside the
                        supervision or project scope of Ilearnplace in the form of videos, pictures and other media on to the
                        social media platforms of Ilearnplace (“User Project Uploads”) The user reserves the right to mark any User
                        Project Uploads as private and such User Project Uploads cannot be shared by any other user. If the user
                        marks any User Project Uploads as public, then any user can share such User Project Uploads.</Typography>
                    <Typography variant="body2" gutterBottom>              The user hereby agrees that the Ilearnplace shall own, and the User agrees to assign and does hereby assign,
                        all rights, title and interest (including but not limited to patent rights, copyrights, trade secret rights,
                        trademark rights, and all other intellectual and industrial property rights of any sort) relating to any
                        and all inventions (whether or not patentable), works of authorship, designs, know-how, ideas and
                        information authored, created, contributed to, made or conceived or reduced to practice, in whole or in part,
                        by the user in such Products/ projects that has been developed by the user under the supervision of Ilearnplace.
                        Such projects developed by the User under the supervision of Ilearnplace once it is uploaded to the website or
                        online platform shall be sole property of Ilearnplace and Ilearnplace has the express right to use such projects
                        in the form of pictures or videos or any other media in any platform and that Ilearnplace may protect the
                        copyright or dispose of or authorize the use of any or all such rights in any manner whatsoever.The user shall
                        have complete right and ownership over the User Project Uploads.</Typography>
                    <Typography variant="body2" gutterBottom>  Ilearnplace reserves the right to change the terms, conditions, and notices under which this website is operated
                        <Typography variant="body2" gutterBottom>     and services are offered, without giving any notice of the same.</Typography>
                        In the event the user posts any inappropriate content with regard to Almondai Group - Ilearnplaces Works or on
                        the official pages and handles of Ilearnplace on different social media handles or by way of User Project Upload,
                        Ilearnplace reserves the right to block such user and initiate appropriate legal proceedings against such user.
                        The following shall be considered inappropriate use of the Ilearnplace website by the user and the user is
                        prohibited from indulging in any of the belowactivities:</Typography>
                    <Typography variant="body2" gutterBottom>  Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of
                        others;</Typography>
                    <Typography variant="body2" gutterBottom>belongs to another person and to which you not have any right;
                        is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive
                        of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money
                        laundering or gambling, or otherwise unlawful in any manner whatever;
                        harms minors in any way;</Typography>
                    <Typography variant="body2" gutterBottom>violates any law for the time being in force;</Typography>
                    <Typography variant="body2" gutterBottom>  deceives or misleads the addressee about the origin of such messages communicates any information which is
                        grossly offensive or menacing in nature</Typography>
                    <Typography variant="body2" gutterBottom>Engage in any activity that interferes with or disrupts access to the website or its services;
                        Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person
                        or entity;</Typography>
                    <Typography variant="body2" gutterBottom>Post any file that infringes the copyright, patent or trademark of other legal entities;
                        Upload or distribute files that contain viruses, corrupted files, or any other similar software or programs
                        that may damage the operation of the website or another's computer;
                        Download any file posted by another user that you know, or reasonably should know, cannot be legally
                        distributed in such manner;</Typography>
                    <Typography variant="body2" gutterBottom>Probe, scan or test the vulnerability of the website or any network connected to the website, nor breach the
                        security or authentication measures on the website or any network connected to the website. You may not reverse
                        look-up, trace or seek to trace any information on any other user, of or visitor to, the website, or any other
                        customer of the website, including any website account not owned by you, to its source, or exploit the website
                        or its services or information made available or offered by or through the website, in any way whether or not
                        the purpose is to reveal any information, including but not limited to personal identification information,
                        other than your own information, as provided for by the website;</Typography>
                    <Typography variant="body2" gutterBottom>                    Disrupt or interfere with the security of, or otherwise cause harm to, the website, system resources, accounts,
                        passwords, servers or networks connected to or accessible through the website or any affiliated or linked sites;
                        Collect or store data about other users in connection with the prohibited conduct and activities set forth in
                        this section;</Typography>
                    <Typography variant="body2" gutterBottom>             Use the website or any material or content for any purpose that is unlawful or prohibited by these Terms, or
                        to solicit the performance of any illegal activity or other activity which infringes the rights of this website
                        or other third parties;</Typography>
                    <Typography variant="body2" gutterBottom>  Violate any code of conduct or other guidelines, which may be applicable for or to any particular service;
                        Violate the Terms including but not limited to any applicable additional terms of the website contained herein
                        or elsewhere;</Typography>
                    <Typography variant="body2" gutterBottom> Violate any code of conduct or other guidelines, which may be applicable for or to any particular service;
                        Threaten the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign
                        states, or public order or cause incitement to the commission of any cognizable offence or prevent investigation
                        of any offence or cause insult to any other nation;</Typography>
                    <Typography variant="body2" gutterBottom>                    Publish, post, disseminate information that is false, inaccurate or misleading; violate any applicable laws or
                        regulations for the time being in force in or outside India;</Typography>
                    <Typography variant="body2" gutterBottom> Directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is
                        prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline
                        for the time being in force;</Typography>

                </Container>
            </div>
        </React.Fragment>
    );
}




//                 </Typography>
//             </Container>
//         </React.Fragment>
//     );
// }
