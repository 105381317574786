import React, { useState } from "react";
import "./Toaster.css"; // Import the CSS file for styling
import pic from "./registrationNow.png";
import { useHistory } from "react-router-dom";

function Toaster({ message, show }) {
  const [isVisible] = useState(parseInt(localStorage.getItem("registrationNow")));
  const history = useHistory();

  const handleClose = () => {
    history.push("/dashboard/registration");
    localStorage.setItem("registrationNow", 0);
  };
// console.log("first",  localStorage.getItem("registrationNow"))
  return (
    <>
      {isVisible === 1 ? 
        <div className="toaster">
          <div
            onClick={() => {
              handleClose();
            }}
            style={{
              padding: "0px 20px 50px 190px",
              backgroundImage: `url(${pic})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div>
              <button className="toaster-close" onClick={handleClose}>
                X
              </button>
            </div>
          </div>
        </div>
       : null}
    </>
  );
}

export default Toaster;
