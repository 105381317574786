import React from "react";
import { Container, Row, Col } from "reactstrap";



function Social() {
 

  return (
    <React.Fragment>
      <section className="contact-social bg-light">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              {/* <ul className="list-inline social mt-4">
                  <li className="list-inline-item">
                    <Link to="#" className="social-icon">
                      <i className="mdi mdi-facebook"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#" className="social-icon">
                      <i className="mdi mdi-twitter"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#" className="social-icon">
                      <i className="mdi mdi-linkedin"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#" className="social-icon">
                      <i className="mdi mdi-google-plus"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#" className="social-icon">
                      <i className="mdi mdi-dribbble"></i>
                    </Link>
                  </li>
                </ul> */}
            </Col>
            <Col lg={3} className="mt-4">
              <p className="contact-title">
                <a href="tel:+91 809 441 4990" className="pe-7s-call">&nbsp;+91 809 441 4990</a>
              </p>
            </Col>
            <Col lg={3} className="mt-4 text-right">
              <p className="contact-title">
                <a href="mailto:info@ilearnplace.com" target="_blank" rel="noreferrer" className="pe-7s-mail-open">&nbsp;info@ilearnplace.com</a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Social;



