import React, { useState } from "react";
import { Container, Row, Col, FormGroup, Form } from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
//Import Section Title
import SectionTitle from "../common/section-title";
import { Contactdata } from "../../api";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(6),
    },
  },
}));

const Features = () => {
  const classes = useStyles();
  const [mopen, setMopen] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    name: '',
    contact: '',
    email: '',
    message: '',
  })

  const clearState = () => {
    setData({
      name: '',
      contact: '',
      email: '',
      message: '',
    });
  };

  const hendle = (e) => {
    const newdata = { ...data }
    newdata[e.target.id] = e.target.value
    setData(newdata)
    //console.log(newdata)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (data.contact.length === 10) {
      Contactdata(data)
      setOpen(false)
      setMopen(true)
      event.target.reset();
    } else {
      setOpen(true)
    }
    clearState()
    //event.target.reset();

  }

  const handleClose = () => {
    setMopen(false);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <section className="section " id="contact">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Contact Us"
          />

          <Row>
            <Col lg={4}>
              <div className="mt-4 pt-4">
                <p className="mt-4">
                  <span className="h5">Office Address </span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    Ileanaplace, #69, Jamna Puri, Murlipura, Jaipur Rajasthan 302032
                    
                  </span>
                </p>
                <p className="mt-4">
                  <span className="h5">Contact</span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    +91 809 441 4990 <br />
                    info@ilearnplace.com
                  </span>
                </p>
                <p className="mt-4">
                  <span className="h5">Working Hours:</span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    9:00AM To 6:00PM
                  </span>
                </p>
              </div>
            </Col>
            <Col lg={8}>
              <div className="custom-form mt-4 pt-4">
                <div id="message"></div>
                <Form onSubmit={handleSubmit} name="contact-form" id="contact-form">
                  <Row>
                    <Col lg={6}>
                      <FormGroup className="mt-2">
                        <input
                          name="name"
                          id="name"
                          maxLength={30}
                          onChange={(e) => hendle(e)}
                          type="text"
                          className="form-control"
                          placeholder="Name*"
                          required
                          autoComplete="true"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="mt-2">
                        <input
                          required
                          id="contact"
                          type="text"
                          name="contact"
                          maxLength={10}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9 ]/g, '').replace(' ', '');
                            hendle(e)
                          }}

                          className="form-control"
                          placeholder="Contact*"
                          autoComplete="true"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <FormGroup className="mt-2">
                        <input
                          name="email"
                          type="email"
                          onChange={(e) => hendle(e)}
                          className="form-control"
                          id="email"
                          placeholder="Email*"
                          required
                          autoComplete="true"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <FormGroup className="mt-2">
                        <textarea
                          name="comments"
                          id="message"
                          onChange={(e) => hendle(e)}
                          rows="4"
                          className="form-control"
                          placeholder="Message..."
                          required
                          autoComplete="true"
                        ></textarea>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <FormGroup className="mt-2">
                      <Col lg={12} className="text-end">
                        <input
                          type="submit"
                          id="submit"
                          name="send"
                          className="submitBnt btn btn-primary form-control"
                          value="Send Message"
                        />
                        <div id="simple-msg"></div>
                      </Col>
                    </FormGroup>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <div className={classes.root}>

          <Snackbar open={mopen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity="success">
              Thanks for joining us !
            </Alert>
          </Snackbar>
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity="error">
              Plasse input your contact number !
            </Alert>
          </Snackbar>
          {/* <Alert severity="success">This is a success message!</Alert> */}
        </div>
      </section>
    </React.Fragment>
  );
}

export default Features;
