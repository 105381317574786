import React, { Component } from "react";
import { Col } from "reactstrap";

class OurPartnerBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.partners.map((partner, key) => (
          <Col lg={6} key={key} className="mt-3">
            <div className="services-box text-center hover-effect">
              <img
                alt="team"
                src={partner.image}
                className="img-fluid img-thumbnail rounded-circle"
              />
              <h4 className="pt-3">{partner.title}</h4>
              <p className="pt-3 text-muted">{partner.desc}</p>
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default OurPartnerBox;
