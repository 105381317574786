import React, { useState, useEffect } from "react";

import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  Container,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  green,
  yellow,
  orange,
} from "@mui/material/colors";

import { TOAST_LOADING, TOAST_SUCCESS, TOAST_WARN } from "../../utils/index";


const colors = [
  red[500],
  pink[500],
  purple[500],
  deepPurple[500],
  indigo[500],
  blue[500],
  green[500],
  yellow[500],
  orange[500],
];

function getRandomColor() {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
}

const paperStyles = {
  borderRadius: "10px",
  boxShadow: "none",
  border: "1px solid #E5E5E5",
};

const buttonStyles = {
  fontSize: "12px",
  color: "white",
};

const gridStyles = {
  flexDirection: "column",
  padding: "45px 0px 20px 10px",
};

const gridContent = {
  margin: {
    xs: "0px 0px",
    md: "10px 20px",
  },
};

const textStyle = {
  display: "flex",
  margin: "10px",
  wordBreak: 'break-word'
 };

const Profile = (props) => {
  const avatarColor = getRandomColor();
  const [open, setOpen] = useState(false);
  const [contactError, setContactError] = useState(false);

  const [formData, setFormData] = useState({
    name: props.name,
    email: props.email,
    contact: props.contact,
    school_name: props.schoolname,
    schoolAddress: props.schooladdress,
  });
  const { name, email, contact, school_name, schoolAddress } = formData;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (contact.length < 10) {
      TOAST_WARN("Please enter a 10-digit phone number.");
      setContactError(true);
    } else {
      setOpen(false);
      props.updateApi({
        id: props.id,
        name: name,
        email: email,
        contact: contact,
        schoolName: school_name,
        address: schoolAddress,
      });
      TOAST_SUCCESS("Edited successfully.");
    }
  };

  return (
    <>
      <Paper variant="outlined" sx={paperStyles}>
        <Grid
          sx={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <Grid
            container
            sx={{
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
            justifyContent="flex-end"
            bgcolor="#272A33"
            height="120px"
          >
            <Button sx={buttonStyles} onClick={handleClickOpen}>
              <IconButton aria-label="edit">
                <EditIcon sx={{ color: "white" }} />
              </IconButton>
              Edit
            </Button>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Avatar
                alt={props.name}
                src="/broken-image.jpg"
                sx={{
                  width: "110px",
                  height: "110px",
                  fontSize: "40px",
                  background: avatarColor,
                  textTransform: "capitalize",
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={gridStyles}>
          <Grid item xs={12}>
            <Typography
              textTransform="capitalize"
              variant="h6"
              fontWeight="bolder"
              gutterBottom
              sx={{ textAlign: "center" }}
            >
              {name}
            </Typography>
            <Grid sx={gridContent}>
              <Typography variant="body2" gutterBottom sx={textStyle}>
                <MailOutlineIcon
                  sx={{ margin: "1px 5px", width: 20, height: 20 }}
                />{" "}
                {email}
              </Typography>
              <Typography variant="body2" gutterBottom sx={textStyle}>
                <LocalPhoneOutlinedIcon
                  sx={{ margin: "1px 5px", width: 20, height: 20 }}
                />{" "}
                {contact}
              </Typography>
              <Typography variant="body2" gutterBottom sx={textStyle}>
                <BusinessIcon
                  sx={{ margin: "1px 5px", width: 20, height: 20 }}
                />{" "}
                {school_name}
              </Typography>

              <Typography variant="body2" gutterBottom sx={textStyle}>
                <PlaceOutlinedIcon
                  sx={{ margin: "1px 5px", width: 20, height: 20 }}
                />{" "}
                {schoolAddress}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/*-----------Edit Dailog Box----------- */}
      <Dialog open={open} onClose={handleClose}>
        <Container
          maxWidth="sm"
          sx={{
            overflowY: "scroll",
            height: "80vh",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#4f4f4b",
              borderRadius: "4px",
            },
          }}
        >
          <Grid container justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <DialogTitle textAlign="center">
            {" "}
            Edit Team Member Details
          </DialogTitle>

          <form onSubmit={handleUpdate}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  value={name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="contact-number"
                  label="Contact Number"
                  type="tel"
                  name="contactNumber"
                  pattern="[0-9]{10}"
                  helperText={
                    contactError ? (
                      <Typography variant="body2" color="red">
                        Please enter a 10-digit phone number.
                      </Typography>
                    ) : (
                      ""
                    )
                  }
                  InputProps={{
                    inputProps: {
                      maxLength: 10,
                    },
                  }}
                  value={contact}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      contact: e.target.value.replace(/[^0-9]/g, ""),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="schoolName"
                  label="School Name"
                  value={school_name}
                  onChange={(e) =>
                    setFormData({ ...formData, school_name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="address"
                  label="Address"
                  name="address"
                  multiline
                  rows={4}
                  value={schoolAddress}
                  onChange={(e) =>
                    setFormData({ ...formData, schoolAddress: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} mb={4}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ bgcolor: "#24346c" }}
                  color="primary"
                >
                  Edit Team Member
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Dialog>

      {/*---------- Edit Dailog Box------------ */}
    </>
  );
};

export default Profile;

// import Avatar from '@mui/material/Avatar';
// // import { grey, yellow } from "@material-ui/core/colors";
// // import { color } from "@mui/system";
// import { Component } from "react";
// import * as React from 'react';
// import Button from '@mui/material/Button';
// // import updatestudent from './updatestudent';
// import Dialog from '@mui/material/Dialog';
// import TextField from '@mui/material/TextField';
// // import DeleteIcon from '@mui/icons-material/Delete';
// // import SendIcon from '@mui/icons-material/Send';
// import Stack from '@mui/material/Stack';

// class Profile extends Component {
//   constructor(props) {
//     super();
//     this.state = {
//       open: false,
//       name: props.name,
//       contact: props.contact,
//       email: props.email,
//       school_name: props.schoolname,
//       schooladdress: props.schooladdress,
//       id: props.id
//     }
//   }
//   render() {
//     console.log(this.props);
//     return <div style={{ color: 'blue', backgroundColor: "rgb(240, 240, 240)", display: 'flex', flexDirection: 'row', padding: '20px', borderRadius: 10, marginLeft: '10px', paddingRight: '10px' }} >
//       {<Dialog
//         open={this.state.open}
//         onClose={() => this.setState({ open: false })}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"

//       >
//         <div style={{ display: 'flex', flexDirection: 'column', marginInline: '50px', paddingTop: '20px', height: '500px', }} >
//           <TextField label={"Name"} value={this.state.name} onChange={(e => this.setState({ name: e.target.value }))} />
//           {/* TextField label={"User Name"}  name={this.state.name} onChange={(e=>this.setState({name:e}))} /> */}
//           <div style={{ marginTop: '10px' }} >  <TextField label={"Contact"} value={this.state.contact} onChange={(e => this.setState({ contact: e.target.value }))} /> </div>
//           <div style={{ marginTop: '10px' }} >  <TextField label={"Email"} value={this.state.email} onChange={(e => this.setState({ email: e.target.value }))} /> </div>
//           <div style={{ marginTop: '10px' }} >  <TextField label={"School Name"} value={this.state.school_name} onChange={(e => this.setState({ school_name: e.target.value }))} /> </div>
//           <div style={{ marginTop: '10px' }} >   <TextField label={"School Address"} value={this.state.schooladdress} onChange={(e => this.setState({ schooladdress: e.target.value }))} /> </div>
//           <Button
//           variant="contained"
//           style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', color:'#4a94d0',}}
//           onClick={() => {

//             this.setState({ open: false });
//             this.props.updateApi({
//               id: this.state.id,
//               name: this.state.name,
//               email: this.state.email,
//               contact: this.state.contact,
//               schoolName: this.state.school_name,
//               address: this.state.schooladdress,
//             });
//           }} >
//             Save
//           </Button>
//         </div>
//       </Dialog>}
//       <Avatar alt={this.props.name} src="/static/images/avatar/1.jpg" />
//       <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px' }} >
//         <div style={{ color: "black", fontWeight: "800", justifyContent: 'center', }}>
//           {this.state.name}</div>
//         <div style={{ fontWeight: '900', color: 'black', }} >{this.state.contact}</div>
//         <div>
//           <div style={{ fontWeight: '800' }} >{this.state.email}
//             <div style={{ fontWeight: '900' }} > {this.state.school_name}
//               <div style={{ fontWeight: '800' }} >{this.state.schooladdress}

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div>
//         <Stack direction="row" spacing={2}>
//           <Button onClick={() => this.setState({ open: true })}>
//             Edit
//           </Button>
//         </Stack>
//       </div>
//     </div>
//   }
// }

// export default Profile;
