import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useState } from "react";


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" target={"_blank"} href="ilearnplace.com">
        ilearnplace.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const steps = [
  "Team Registration",
  "AddTeamMember",
  "ShippingAddress",
  "PaymentDetails",
];


const theme = createTheme();

export default function PaymentSuccess() {
  const [activeStep] = useState(4);
  const history = useHistory();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="primary"
        style={{ backgroundColor: "#272a33" }}
        elevation={0}
        sx={{
          position: "sticky",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            ISLP Registration
          </Typography>
          <button
            className="btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
            type="button"
            onClick={() => history.push("/")}
          >
            Home
          </button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 6 } }}
          style={{
            backgroundColor: "#f4f4f4",
            boxShadow: "3px 4px 4px -2px rgb(0 0 0 / 20%)",
          }}
        >
          <Typography component="h1" variant="h4" align="center">
            {steps.filter((item, index) => (index === activeStep ? item : ""))}
          </Typography>
          <Stepper
            activeStep={activeStep}
            sx={{ pt: 3, pb: 5 }}
            style={{ overflow: "auto" }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Thank you !
            </Typography>
            <Typography variant="subtitle1">
              You have successfully registered your team.
              The Ilearnplace STEM kit will be deliver to
              all registered team member.  Login to <a href="www.ilearnplace.com" style={{ cursor: "pointer", color: "blue" }}> www.ilearnplace.com </a>
              and start playing ILP stem learning game.  Please reach out to
              <a href="info@ilearnplace.com" style={{ cursor: 'pointer', color: "blue" }}> info@ilearnplace.com</a> or +91-8094414990 for any kind of support.
            </Typography>
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
