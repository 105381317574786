import React, { useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: '#f5f5f5',
    borderRadius: 8,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    marginBottom: theme.spacing(1),
    listStyleType: 'circle', // add this line to change bullet points to circles
  },
  readMoreButton: {
    marginTop: theme.spacing(2),
  },
}));

function DashboardDescription(props) {
  const classes = useStyles();
  const [showFullContent, setShowFullContent] = useState(false);

  const handleReadMoreClick = () => {
    setShowFullContent(true);
  };

  const handleShowLessClick = () => {
    setShowFullContent(false);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.title} style={{ color: '#4a94d0' }}>
        Description
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        iLearnPlace is a leading educational platform specialising in the development of innovative educational kits based on robotics, electronics, AI, and IoT. Our kits incorporate laser-cut MDF pieces and high-quality electronics components like Arduino boards, motors, sensors, and more. With these kits, students can assemble and create a variety of projects while learning important STEM concepts.
      </Typography>

      {showFullContent ? (
        <>
          <Typography variant="body1" className={classes.subtitle}>
            We offer four STEM learning games, including "Totoka Smart City" and "Takshak Battlefield" for school students, and "Advanced Smart City" and "Advanced Battlefield" for advanced learners. Each game consists of 12 levels, and students must complete tasks within each level to progress further. By integrating STEM concepts into the tasks, students learn while engaging in interactive gameplay. Membership allows students to access the games, with the Level 1 kit provided for free. Additionally, we provide resources such as YouTube tutorials, free meetings, and seminars where students can learn from qualified educators and enhance their skills. Our mission is to provide practical and engaging STEM education that goes beyond traditional learning methods, nurturing curiosity and fostering a deeper understanding of STEM subjects.
          </Typography>
          <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
            Objective
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            Our objective is to revolutionise STEM (Science, Technology, Engineering, and Mathematics) education by providing students with practical and experiential learning opportunities. We aim to foster curiosity, creativity, and critical thinking skills in students through gamification and hands-on activities.
          </Typography>
          <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
            Mission
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            At iLearnPlace, our mission is to empower students to learn STEM concepts practically. We believe that traditional education methods often focus on exams and rote memorization, neglecting the development of true understanding and problem-solving skills. By integrating gamification into our educational kits, we aim to ignite students' curiosity and provide them with a platform to explore, experiment, and apply their knowledge in a fun and engaging manner.
          </Typography>
          <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
            Values:
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            <ul>
              <li><b>Experiential Learning:</b> We prioritise hands-on learning experiences that allow students to actively engage with STEM concepts and apply them in real-world scenarios.</li>
              <li><b>Curiosity and Exploration:</b> We encourage students to ask questions, explore different possibilities, and think critically to foster a deep understanding of STEM principles.</li>
              <li><b>Collaboration:</b> We promote teamwork and collaboration among students, as it enhances their learning experience and helps them develop communication and interpersonal skills.</li>
              <li><b>Innovation:</b> We strive to incorporate the latest advancements in technology and educational practices to create cutting-edge learning resources for students.</li>
              <li><b>Accessibility:</b> We aim to make STEM education accessible to students from all backgrounds by offering affordable membership options and providing free educational resources.</li>
              {/* <li><b>Age</b>: 10+ years</li> */}
            </ul>
          </Typography>
          <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
            Overall STEM Learning Games
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            iLearnPlace offers engaging STEM learning games, including "Totoka Smart City," "Takshak Battlefield," "Advanced Smart City," and "Advanced Battlefield." With 12 levels in each game, students engage in hands-on assembly, interactive challenges, and tasks to develop problem-solving skills and learn STEM concepts. Join iLearnPlace for practical and immersive STEM education.
          </Typography>
          <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
            Game Flow
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            Each of our STEM learning games consists of 12 levels, offering a progressive and structured learning experience. Within each level, students are presented with a series of engaging tasks that need to be completed. By successfully accomplishing these tasks, students unlock the next level, allowing them to advance further and explore more challenging concepts. This game flow ensures a gradual progression of skills and knowledge throughout the gameplay, making the learning process both exciting and rewarding.
          </Typography>
          <Typography variant="h6" className={classes.subtitle} style={{ color: '#4a94d0' }}>
            School Students:
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            <ul>
              <li><b>Game 1:</b> Totoka Smart City</li>
              <li><b>Game 2:</b> Takshak Battlefield</li>
            </ul>
          </Typography>
          <Typography variant="h6" className={classes.subtitle} style={{ color: '#4a94d0' }}>
            Advanced Students:
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            <ul>
              <li><b>Game 3:</b> Advanced Smart City</li>
              <li><b>Game 4:</b> Advanced Battlefield</li>
            </ul>
          </Typography>
          <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
            How to Join
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            To join iLearnPlace and gain access to our STEM learning games, students can avail a membership. The membership allows students to order the games starting from Level 1. To become a member, students can visit our website and follow the registration process.
          </Typography>
          <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
            How to Quick Start
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            Upon availing the membership, students will receive the Level 1 kit for free. The kit will be delivered directly to the student's provided address. The Level 1 kit contains all the necessary components to assemble the game and get started with the learning journey.
          </Typography>
          <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
            How to Play the Game
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            <ul>
              <li>Students can refer to the provided YouTube video tutorials for step-by-step instructions on assembling the game components.</li>
              <li>Once the game is assembled, students can begin playing by following the tasks outlined within each level.</li>
              <li>Students should complete each task sequentially to progress through the levels.</li>
              <li>Along the way, students will encounter STEM-related concepts, key skills, and key concepts, providing them with a holistic learning experience.</li>
              <li>If students need assistance or have questions, they can join free meetings and seminars conducted by qualified educators. They can also reach out to educators for guidance and support.</li>
            </ul>
          </Typography>
          <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
            Membership Pricing
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            The membership fee for one year is priced at 3000 INR, **Check for offers
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.readMoreButton}
            onClick={handleShowLessClick}
          >
            Show Less
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.readMoreButton}
          onClick={handleReadMoreClick}
        >
          Read More
        </Button>
      )}
    </div>
  );
}

export default DashboardDescription;
