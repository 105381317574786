import React from 'react';
import { Typography } from '@material-ui/core';



const ClubActivites = () => {
    return (
        <div>
            <Typography variant="h6" gutterBottom>
               <b> Club Activites </b>
            </Typography>

            <div>
                <Typography variant="subtitle1" gutterBottom>
                    <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                    There are several activities that can be undertaken by a ILP student club in colleges/schools to help members develop their skills and knowledge in these fields. Some activities that may be of interest include:
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                    <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                        <b>Playing ILP STEM games :</b> Club members can  play ILP STEM games using ILP STEM kits which are available in ILP Lab in colleges/schools.
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                <b>Learning Sessions: </b> The club can organize regular learning sessions where members can learn about the principles of robotics, electronics, coding, and other STEM-related subjects. These sessions can be conducted by club officers or invited guest speakers who are experts in the field.

                </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                <b>Building and Programming Projects:</b>  The club can work on building and programming robotics projects such as robots, drones, or other devices that can be used in real-world scenarios. Members can work in groups or individually to develop their skills and create innovative projects.

                </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                <b>Workshops and Demonstrations:</b> The club can organize workshops and demonstrations to showcase the projects that they have created. This can provide an opportunity for members to share their knowledge and experiences with others and to receive feedback from experts in the field.

                </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                <b>Field Trips:</b>  The club can organize field trips to research centers, technology companies, or universities to learn about the latest advancements in STEM fields. This can provide members with exposure to the latest technologies and opportunities to network with professionals in the industry.

                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
               <b> ISPL/IRPL :</b>  The club can participate in ilearnplace STEM learning/ robotics  premier league national level competitions which are organized by ilearnplace. There will be no participation fee for ILP student club members.
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                <b>Competitions:</b> The club can participate in competitions organized by local or national 
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                <b>organizations:</b> This can provide members with an opportunity to test their skills and knowledge against others and to gain recognition for their work.
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                <b>Outreach Programs:</b> The club can organize outreach programs to introduce robotics and STEM education to local schools or communities. This can provide an opportunity for members to share their passion for STEM with others and to inspire young people to pursue careers in these fields.
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                By engaging in these activities, An ILP student club in colleges/Schools can provide members with valuable experiences that can help them develop their skills, knowledge, and passion for STEM fields.
                    </b>
                </Typography>
            </div>

        </div>
    );
};

export default ClubActivites;
