import React, { Component } from "react";
import Container from '@mui/material/Container';
import IslpJsonData from "./islpData.json";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1.5rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

class Tables extends Component {
  state = {
    awards: [
      { metric: 1, cardAward: 21000 , hardwareAward: "ILP STEM Kit Of Worth 3000" },
      { metric: 2, cardAward: 11000, hardwareAward: "ILP STEM Kit Of Worth 3000" },
      { metric: 3, cardAward: 5000, hardwareAward: "ILP STEM Kit Of Worth 3000" },
      { metric: 4, cardAward: "", hardwareAward: "ILP STEM Kit Of Worth 3000" },
      { metric: 5, cardAward: "", hardwareAward: "ILP STEM Kit Of Worth 3000" },
      { metric: 6, cardAward: "", hardwareAward: "ILP STEM Kit Of Worth 3000" },
      { metric: 7, cardAward: "", hardwareAward: "ILP STEM Kit Of Worth 3000" },
      { metric: 8, cardAward: "", hardwareAward: "ILP STEM Kit Of Worth 3000" },
      { metric: 9, cardAward: "", hardwareAward: "ILP STEM Kit Of Worth 3000" },
      { metric: 10, cardAward: "", hardwareAward: "ILP STEM Kit Of Worth 3000" },
    ],
    expanded: "false",
  };

  handleChange = (panel) => {
    this.setState({ expanded: panel === this.state.expanded ? false : panel });
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <Accordion
            expanded={this.state.expanded === "panel1"}
            onChange={() => this.handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography variant="h5"> Objective</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                STEM (Science, Technology, Engineering, and Mathematics)
                learning competitions between students are designed to achieve
                several objectives, including:
              </b>
              <ol>{IslpJsonData?.objectiveData.map((item, index) => (
                <li key={index} style={{
                  fontFamily: "Rubik", fontWeight: "300", fontSize: 20,
                }}
                >
                  {item.name}
                  <br />
                </li>
              ))}
              </ol>
              <p
                style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20, }}
              >
                Overall, STEM learning competitions between students aim to
                promote STEM education and encourage students to pursue careers
                in these fields.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={this.state.expanded === "panel2"}
            onChange={() => this.handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography variant="h5" > Vision</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }} >
                The vision of STEM (Science, Technology, Engineering, and
                Mathematics) learning is to provide students with a
                comprehensive and integrated education in these subjects to
                foster their critical thinking, problem-solving, and
                innovation skills. The goal is to equip them with the
                knowledge and abilities they need to succeed in the rapidly
                changing technological landscape and to prepare them for
                careers in STEM fields.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={this.state.expanded === "panel3"}
            onChange={() => this.handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography variant="h5" >Awards</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <table className="table">
                <thead>
                  <tr style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                    <th>Merit</th>
                    <th>Case Awards </th>
                    <th>Hardware Award</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.awards && this.state.awards.length > 0
                    ? this.state.awards.map((item, index) => (
                      <tr
                        key={item.metric}
                        style={{
                          fontFamily: "Rubik",
                          fontWeight: "300",
                          fontSize: 20,
                        }}
                      >
                        <td>{item.metric} </td>
                        <td>{item.cardAward}</td>
                        <td>{item.hardwareAward}</td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={this.state.expanded === "panel4"}
            onChange={() => this.handleChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography variant="h5" >Team Benefits</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                {IslpJsonData?.teamBenifits.map((item, index) => (
                  <li
                    key={index}
                    style={{
                      fontFamily: "Rubik",
                      fontWeight: "300",
                      fontSize: 20,
                    }}
                  >
                    {item.name}
                    <br />
                  </li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={this.state.expanded === "panel5"}
            onChange={() => this.handleChange("panel5")}
          >
            <AccordionSummary
              aria-controls="panel5d-content"
              id="panel5d-header"
            >
              <Typography variant="h5" >Important Dates</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <table className="table">
                <thead>
                  <tr
                    style={{
                      fontFamily: "Rubik",
                      fontWeight: "300",
                      fontSize: 20,
                    }}
                  >
                    <th>Objectives</th>
                    <th>Datetime</th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    fontFamily: "Rubik",
                    fontWeight: "300",
                    fontSize: 20,
                  }}
                >
                  <tr>
                    <td>Start Registration </td>
                    <td>15 March</td>
                  </tr>
                  <tr>
                    <td>End Registration</td>
                    <td>15 May</td>
                  </tr>
                  <tr>
                    <td>Competition Date</td>
                    <td>2 June</td>
                  </tr>
                  {/* <tr>
                    <td>Video Submission Start Date </td>
                    <td>30 August 2023</td>
                  </tr> */}
                  {/* <tr>
                    <td>Final Video Submission</td>
                    <td>15 Sept 2023</td>
                  </tr> */}
                  {/* <tr>
                    <td>Award Announcement</td>
                    <td>15 October 2023</td>
                  </tr> */}
                </tbody>
              </table>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion
            expanded={this.state.expanded === "panel6"}
            onChange={() => this.handleChange("panel6")}
          >
            <AccordionSummary
              aria-controls="panel6d-content"
              id="panel6d-header"
            >
              <Typography variant="h5" >SPECIFICATIONS</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <h4>ISPL Stage-1 (Registration)</h4>
                  <p
                    style={{
                      fontFamily: "Rubik",
                      fontWeight: "300",
                      fontSize: 20,
                    }}
                  >
                    Participants have to register as a team. Number of
                    participants in the team are 1 to 6. Team has a choice to
                    submit ideas at registration time. Teams can update their
                    idea to ILearnPlace - Innovative Insight in Action dashboard
                    at ISPL Stage-2 submissions. Team has to logon
                    www.ispl.ilearnplace.com for team registration.{" "}
                  </p>
            </AccordionDetails>
          </Accordion> */}
          <Accordion
            expanded={this.state.expanded === "panel7"}
            onChange={() => this.handleChange("panel7")}
          >
            <AccordionSummary
              aria-controls="panel7d-content"
              id="panel7d-header"
            >
              <Typography variant="h5" >Competition Pattern</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <h4>This competition consists of two stages.</h4>
              <p
                style={{
                  fontFamily: "Rubik",
                  fontWeight: "300",
                  fontSize: 20,
                }}
              >
                Stage 1: In this stage, the Kit Assembly process is done with the ILP STEM Kit.
                 Teams must complete the assembly within the given time frame.
                  If the assembly is not completed within the specified time,
                   points will be deducted from that team.<br></br><br></br>

                Stage 2: In the second stage, the Relay Robo Race will take place among four teams.
                 Winners will advance to the next round, where the competition will be held between the victorious teams.

                 {" "}
              </p>
              {/* <ol
                style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}
              >
                <li>{`Age criteria :     10 Years <= age <=19 Years`}</li>
                <li>{`Nationality :  Anyone interested in STEM learning can participate in ISPL-23`}</li>
              </ol> */}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={this.state.expanded === "panel8"}
            onChange={() => this.handleChange("panel8")}
          >
            <AccordionSummary
              aria-controls="panel8d-content"
              id="panel8d-header"
            >
              <Typography variant="h5" >FEE</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* <h4>ISPL Stage-1 (Registration)</h4> */}
              <b>Team Members : At Least 5</b>
              <br />
              <b>Age : 8-16 yrs</b>
              <br />
              <b>
              Fee : 200 INR Per Member ( One Kit Delivered to a Team )
              </b>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion
            expanded={this.state.expanded === "panel9"}
            onChange={() => this.handleChange("panel9")}
          >
            <AccordionSummary
              aria-controls="panel9d-content"
              id="panel9d-header"
            >
              <Typography variant="h5" >STEM ideas examples{" "}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                There are no limit of STEM concepts, here showing few examples
              </b>
              <ol
                style={{
                  fontFamily: "Rubik",
                  fontWeight: "300",
                  fontSize: 20,
                }}
              >
                <li>{`Circle equations (Circumference, Radius, Diameter, Area etc) `}</li>
                <li>{`Three Newton's Law`}</li>
                <li>{`Mathematics equations( a^2 + b^2 +2ab, Y=MX+C, etc`}</li>
                <li>{`One dimension motion`}</li>
                <li>{`Science concepts - Heat, light, motion, energy, matter, and electricity etc`}</li>
                <li>{`DC Motor concepts`}</li>
                <li>{`Technology- Arduino Uno, Nano, Raspberry Pi etc`}</li>
              </ol>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={this.state.expanded === "panel10"}
            onChange={() => this.handleChange("panel10")}
          >
            <AccordionSummary
              aria-controls="panel10d-content"
              id="panel10d-header"
            >
              <Typography variant="h5" >STEM Model Video Submission</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p
                style={{
                  fontFamily: "Rubik",
                  fontWeight: "300",
                  fontSize: 20,
                }}
              >
                Team has to submit their STEM model recorded video demo of
                their STEM idea. This Stage is completely online, no need to
                come to a particular place to submit a prototype. Team has to
                upload prototype video to{" "}
                <a href="www.ilearnplace.com">www.ilearnplace.com . </a>
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={this.state.expanded === "panel11"}
            onChange={() => this.handleChange("panel11")}
          >
            <AccordionSummary
              aria-controls="panel11d-content"
              id="panel11d-header"
            >
              <Typography variant="h5" >Judgement Criteria</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <b style={{ fontFamily: "Rubik", fontWeight: "300", fontSize: 20 }}>
                Jury panel will evaluate submitted video based on following
                points
              </b>
              <ol
                style={{
                  fontFamily: "Rubik",
                  fontWeight: "300",
                  fontSize: 20,
                }}
              >
                <li>{`Number of Science & Mathematics  Concepts practically implemented  `}</li>
                <li>{`Based on video presentation`}</li>
                <li>{`Engineering & Technology  Design`}</li>
                <li>{`Use of STEM concepts in real life.`}</li>
              </ol>
            </AccordionDetails>
          </Accordion> */}
          <Accordion
            expanded={this.state.expanded === "panel12"}
            onChange={() => this.handleChange("panel12")}
          >
            <AccordionSummary
              aria-controls="panel12d-content"
              id="panel12d-header"
            >
              <Typography variant="h5" >Terms & Conditions and Policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <h6
                style={{
                  fontFamily: "Rubik",
                  fontWeight: "300",
                  fontSize: 20,
                }}
              >
                Terms & Conditions
              </h6>
              <ol
                style={{
                  fontFamily: "Rubik",
                  fontWeight: "300",
                  fontSize: 20,
                }}
              >
                <li>{`Eligibility: The competition is open to participants who meet the 8 to 16 years or educational qualifications more then 4th standard schooling, or other eligibility requirements specified by the organiser.`}</li>
                <li>{`Code of conduct: Participants are expected to conduct themselves in a professional and respectful manner throughout the competition, and may be subject to disqualification or other disciplinary action if they violate the competition's code of conduct.`}</li>
                <li>{`Judging criteria: The competition may specify the criteria by which submissions will be judged.`}</li>
                <li>{`Liability: The organiser may not be held liable for any injury, damage, or loss suffered by participants or their property during the competition.`}</li>
                <li>{`Disqualification: The organiser reserves the right to disqualify participants who violate the competition's rules or fail to meet the competition's standards.`}</li>
                <li>{`Refund Policy: Full refunds will be issued if the competition is cancelled.`}</li>
                {/* <li>{`Liability: The organizer may not be held liable for any injury, damage, or loss suffered by participants or their property during the competition.`}</li>
                <li>{`Disqualification: The organizer reserves the right to disqualify participants who violate the competition's rules or fail to meet the competition's standards.`}</li> */}
              </ol>
              {/* <h6
                style={{
                  fontFamily: "Rubik",
                  fontWeight: "300",
                  fontSize: 20,
                }}
              >
                Refund Policy:
              </h6> */}
              {/* <ol
                style={{
                  fontFamily: "Rubik",
                  fontWeight: "300",
                  fontSize: 20,
                }}
              >
                <li>{`No refunds: We have a strict no refund policy, meaning that once a participant has registered and paid the entry fee, they will not receive a refund under any circumstances.`}</li>
                <li>{`Partial refunds:  Partial refunds in certain circumstances, if the competition is canceled or rescheduled.`}</li>
                <li>{`Full refunds: It may offer full refunds in certain circumstances, such as if the participant is unable to attend the competition due to a medical emergency or other extenuating circumstances.`}</li>
              </ol> */}
              {/* <h6
                style={{
                  fontFamily: "Rubik",
                  fontWeight: "300",
                  fontSize: 20,
                }}
              >
                Policy:
              </h6> */}
              {/* <ol
                style={{
                  fontFamily: "Rubik",
                  fontWeight: "300",
                  fontSize: 20,
                }}
              >
                <li>{`Eligibility: Policies should clearly outline the eligibility criteria for participation in the competition. This may include age restrictions 10 to 19 years or educational qualifications more then 4th standard class, and other relevant factors.`}</li>
                <li>{`Code of Conduct: Participants are expected to abide by a code of conduct that outlines acceptable behavior during the event. This may include rules regarding language, attire, sportsmanship, and general conduct.`}</li>
                <li>{`Safety: Policies should prioritize the safety of participants, staff, and visitors. This may include guidelines regarding the use of equipment, first aid provisions, and emergency procedures.`}</li>
                <li>{`Intellectual Property: Policies should address the ownership and use of intellectual property rights that may arise from the event. This may include policies related to patents, copyrights, and trademarks.`}</li>
                <li>{`Judging Criteria: The competition's judging criteria should be clearly outlined, including the factors that will be evaluated and how the scoring system will be structured.`}</li>
                <li>{`Prizes and Awards: Policies should address the prizes and awards that will be given to winners. This may include the types of prizes, the value of each prize, and the criteria for winning each prize.`}</li>
                <li>{`Liability: Policies should outline the responsibilities and liabilities of the organizers, participants, and any third-party vendors or contractors involved in the event.`}</li>
                <li>{`Accessibility: Policies should ensure that the event is accessible to all participants, regardless of any disabilities or special needs they may have.`}</li>
              </ol> */}
            </AccordionDetails>
          </Accordion>
        </Container>
      </React.Fragment>
    );
  }
}

export default Tables;
