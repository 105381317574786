import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        maxWidth: '100%',
        marginTop: '20px'
    },
    AppBar: {
        justifyContent: 'center',
        backgroundColor: '#272a33'
    }
});

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function ReturnCancellationPolicy(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className={classes.root}>
                <CssBaseline />
                <HideOnScroll {...props}>
                    <AppBar >
                        <Toolbar className={classes.AppBar}>
                            <Typography variant="h6">RETURN & CANCELLATION POLICY</Typography>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <Toolbar />
                <Container maxWidth="md">
                    <Typography variant="body2" gutterBottom>Ilearnplace  believes in helping its
                        customers as far as possible, and has therefore a liberal return and cancellation
                        policy. Under this policy: If there are any damages or missing parts when you receive
                        the order from us, we give 1 week for customers to report to us, and we will replace the
                        damaged products or send you the missing parts within 10 days. However, the case will not
                        be accepted if the products were damaged by inappropriate use or carelessness. To the product
                        need to be exchanged, firstly, please send the photos of damaged products to us. We will
                        estimate the damages then decide the best way to exchange or return the product. We accept no
                        responsibility for improper installation of our products. Electrical polarity must be properly
                        observed in hooking up electrical components. We also accept no responsibility for damages caused
                        during the shipping or transit of the goods. In case you feel that the product received is not as
                        shown on the site or as per your expectations, you must bring it to the notice of our customer
                        service within 24 hours of receiving the product. The Customer Service Team after looking into your
                        complaint will take an appropriate decision. Cancellations and Refund will be considered only if
                        the request is made before we have initiated the process of shipping them. Once the product shipping
                        has been initiated, cancellation and refund is not available. At all times, it shall be the sole
                        discretion of the Ilearnplace to determine whether the product is deemed to be returned and/or
                        replaced.</Typography>

                </Container>
            </div>
        </React.Fragment>
    );
}




//                 </Typography>
//             </Container>
//         </React.Fragment>
//     );
// }
