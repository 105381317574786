import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Card, CardMedia ,Modal} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import Looks6OutlinedIcon from '@mui/icons-material/Looks6Outlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
// import featuremedia from './data'
import {featuremedia, testimonials }  from './data'

import ProductDetails from './Desc'
import BuyNowButton from './Buynow'

import StarRating from './rating'
import t1 from './img/8.png'
// import t2 from './img/9.png'
import t3 from './img/10.png'
import t4 from './img/11.png'
import im1 from './img/rb.png'
import im2 from './img/robocar.png'
// import im3 from './img/7.png'
import im4 from './img/rb_f.png'
import im5 from './img/rb_b.png'
import hw from "./img/hwlist.png"

const sliderMedia = [
  { type: 'image', url: t1 },
//   { type: 'image', url: t2 },
  { type: 'image', url:  t3},
  { type: 'image', url:  t4},
];

const productMedia = [
  { type: 'image', url: im4 },
  { type: 'video', url: 'https://www.youtube.com/embed/FOrE6sohnDo?si=gF4Fu5PU3XqD7Iy7' },
  { type: 'image', url: im1 },
  { type: 'image', url: im2 },
//   { type: 'image', url: im3 },
  { type: 'image', url: im5 },


];

const ProductPage2 = () => {
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleThumbnailClick = (index) => {
    setSelectedMediaIndex(index);
  };

  const handleMainMediaClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [sliderIndex, setSliderIndex] = useState(0);

  // Automatically cycle the slider
  React.useEffect(() => {
    const interval = setInterval(() => {
      setSliderIndex((prevIndex) => (prevIndex + 1) % sliderMedia.length);
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, []);


  return (

    <Box p={2}>
    {/* <Header/> */}
      {/* Product Media and Details */}
      <Grid container spacing={4} justifyContent="center" mt={2}>
        {/* Left Column: Image Gallery Thumbnails */}
        <Grid item xs={12} md={7}>
          <Grid container spacing={2}>
            {/* Thumbnails for larger screens (left side) */}
            <Grid item xs={2} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box
        display="flex"
        flexDirection="column"
        overflow="auto"
        sx={{ maxHeight: '400px' }}
      >
        {productMedia.map((media, index) => (
          <Card
            key={index}
            onClick={() => handleThumbnailClick(index)}
            sx={{
              cursor: 'pointer',
              border: selectedMediaIndex === index ? '2px solid #3498db' : '1px solid #ccc',
              marginBottom: 2,
              position: 'relative', // Required for positioning the icon overlay
            }}
          >
            {/* Show image or video thumbnail */}
            <CardMedia
              component={media.type === 'image' ? 'img' : 'video'}
              src={media.url}
              alt={`Thumbnail ${index + 1}`}
              height="80"
              sx={{ objectFit: 'cover' }}
            />

            {/* Conditionally show play icon if media is a video */}
            {media.type === 'video' && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark background for visibility
                  borderRadius: '50%',
                  padding: '8px',
                }}
              >
                <PlayCircleOutlineIcon sx={{ fontSize: 24 }} /> {/* Play Icon */}
              </Box>
            )}
          </Card>
        ))}
      </Box>
    </Grid>


            {/* Main Image/Video Viewer */}
            <Grid item xs={12} md={10}>
              <SwipeableViews
                index={selectedMediaIndex}
                onChangeIndex={(index) => setSelectedMediaIndex(index)}
                enableMouseEvents
              >
                {productMedia.map((media, index) => (
                  <Card key={index} sx={{ maxWidth: '100%', height: 'auto', cursor: 'pointer' }} onClick={handleMainMediaClick}>
                    {media.type === 'image' ? (
                      <CardMedia
                        component="img"
                        src={media.url}
                        alt={`Media ${index + 1}`}
                        height="400"
                        sx={{ objectFit: 'contain' }}
                      />
                    ) : (
                      <iframe
                      height="400"
                      src={media.url}
                      title={`Video ${index + 1}`}
                      // frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ width: '100%', height: '400' }} // Ensure it fills the space
                    ></iframe>
                    )}
                  </Card>
                ))}
              </SwipeableViews>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Column: Product Details */}
        <Grid item xs={12} md={5}>
          <Box>
            <Typography variant="h4" component="h1" align="left" gutterBottom>
            STEM Learning Kit - Robo Car
            </Typography>
            
            <Box display="flex" alignItems="center" mb={2} justifyContent="left">
              <StarRating rating={4.8} /> {/* Use StarRating here */}

            </Box>

            {/* Product Features */}
            <Typography variant="h6" gutterBottom>
              Key Features:
            </Typography>
            
            
              <li> 🚀 <b>50+ Experiments & 11+ STEM Tasks:</b> Fun, hands-on learning for kids.</li>
              <li>🧠 <b>Develop Critical Thinking:</b> Boost creativity and problem-solving skills.</li>
              <li>🎮 <b>Gamified Learning:</b> Multiple challenges keep kids engaged and motivated.</li>
           

            {/* Price Section */}
            <Box display="flex" alignItems="center" mb={3} justifyContent="left">
              <Typography variant="h5" color="textSecondary" style={{ textDecoration: 'line-through' }}>
                ₹3999
              </Typography>
              <Typography variant="h4" color="primary" ml={2}>
                ₹3499
              </Typography>
              <Typography variant="h6" color="green" ml={2}>
                13% off
              </Typography>
            </Box>
            
            <Grid container spacing={4} justifyContent="left">
              <Grid item>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Looks6OutlinedIcon fontSize="large" />
                  <Typography variant="body2">6+ year</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <ChangeCircleOutlinedIcon fontSize="large" />
                  <Typography variant="body2">easy replaceplement</Typography>
                </Box><br/>
              </Grid>
              <Grid item>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Inventory2OutlinedIcon fontSize="large" />
                  <Typography variant="body2">Delivery in 5 days</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <LocalShippingOutlinedIcon fontSize="large" />
                  <Typography variant="body2">Free Delivery</Typography>
                </Box>
              </Grid>
            </Grid>
            
            {/* Buy Now Button */}<br />
            <BuyNowButton />
          </Box>
        </Grid>
      </Grid>

      {/* Full-Screen Modal for Media */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          {productMedia[selectedMediaIndex].type === 'image' ? (
            <CardMedia
              component="img"
              src={productMedia[selectedMediaIndex].url}
              alt={`Full Media ${selectedMediaIndex + 1}`}
              sx={{ width: '100%', height: 'auto' }}
            />
          ) : (
            <video width="100%" controls>
              <source src={productMedia[selectedMediaIndex].url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </Box>
      </Modal>
    

      {/* Centered Title with Underline at the Bottom */}
      <Box textAlign="center" mt={4}>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 1 }}>
          Tasks in Kit
        </Typography>
        <Box sx={{ borderBottom: '2px solid #f4f6f7', width: '1250px', margin: '0 auto' }} />

        
      </Box>

      {/* Full-Width Image Slider at the Bottom */}
      <Box mt={4}>
        <Card sx={{ height: '100%' ,overflow: 'hidden'}}>
  <SwipeableViews index={sliderIndex} onChangeIndex={(index) => setSliderIndex(index)} enableMouseEvents>
    {sliderMedia.map((media, index) => (
      <CardMedia
        key={index}
        component="img"
        src={media.url}
        alt={`Slider Image ${index + 1}`}
        sx={{
          height: '100%',
          width: '100%',
          objectFit: 'cover', // Ensures the image fits within the Card
          display: 'block', 
        }}
      />
    ))}
  </SwipeableViews>
</Card>

        <Box textAlign="center" mt={4}>
  <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 1 }} align="center">
    Descriptions
  </Typography>
  <Box sx={{ borderBottom: '2px solid #f4f6f7', width: '100%', margin: '0 auto' }} />
  <Typography variant="body1" sx={{ marginTop: 1 }}align="left">
  Embark on an educational journey with the STEM Learning Kit - Military Jeep from iLearnPlace. Designed to engage boys and girls aged 6+, this all-in-one kit introduces them to the world of robotics, engineering, and science. With 50+ experiments and 11+ predefined STEM tasks, each guided by simple, step-by-step instructions, children will build their own military jeep and develop crucial skills like creativity, problem-solving, and critical thinking.     
  </Typography>
  <br/>
  <Typography variant='body1' sx={{ marginTop: 1 }} align='left'>
  Made with <Typography component="span" sx={{ fontWeight: 'bold' }}>safe, child-friendly materials,</Typography> this kit offers a stress-free experience for parents and an exciting challenge for kids. Whether it's a birthday, holiday, or special occasion, this kit is the gift that keeps on giving—combining fun and education in a way that grows with your child!
</Typography>

  {/* Description Section */}
  <Box sx={{ maxWidth: '800px', mx: 'auto', mt: 4 }}>
    {/* Fun & Educational */}
    <Box display="flex" mb={2}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', flexBasis: '35%' }} align="left">
      🔍 FUN & EDUCATIONAL
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ flexBasis: '65%' }} align="left">
      Unlock the magic of hands-on learning with the STEM Learning Kit - Military Jeep by iLearnPlace. Designed for kids aged 6 and up, this kit combines robotics, science, and engineering in one exciting package! With over <Typography component="span" sx={{ fontWeight: 'bold' }}>50 experiments and 11+STEM tasks,</Typography> your child will explore creative solutions, experiment with problem-solving, and develop critical thinking—all while having fun!
</Typography>

    </Box>

    {/* Boosts Creativity & Problem-Solving */}
    <Box display="flex" mb={2}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', flexBasis: '35%' }} align="left">
      💡 BOOSTS CREATIVITY & PROBLEM-SOLVING
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ flexBasis: '65%' }} align="left">
      Engage your child’s imagination through a wide variety of experiments. Whether constructing the jeep or solving engineering challenges, the tasks in this kit inspire curiosity and encourage outside-the-box thinking.
      </Typography>
    </Box>

    {/* Safe & Child-Friendly Materials */}
    <Box display="flex" mb={2}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', flexBasis: '35%' }} align="left">
      🔰 SAFE & CHILD-FRIENDLY
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ flexBasis: '65%' }} align="left">
      Your child’s safety is our top priority. That’s why all materials are made from <Typography component="span" sx={{ fontWeight: 'bold' }}>non-toxic,</Typography> durable components, ensuring safe play. Easy-to-follow instructions allow kids to complete each experiment independently, building confidence along the way.
</Typography>

    </Box>

    {/* Ideal Gift for Kids */}
    <Box display="flex" mb={2}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', flexBasis: '35%' }} align="left">
      🎁 THE PERFECT GIFT
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ flexBasis: '65%' }} align="left">
      Looking for a gift that’s both fun and educational? The iLearnPlace Military Jeep Kit is perfect for birthdays, holidays, or any special occasion. It's more than just a toy—it’s a stepping stone to STEM careers, wrapped in hours of exciting play.
      </Typography>
    </Box>

    {/* All-In-One Kit */}
    {/* <Box display="flex" mb={2}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', flexBasis: '35%' }} align="left">
        ALL-IN-ONE KIT
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ flexBasis: '65%' }} align="left">
      To keep kids engaged, we've gamified the learning process! With multiple gameplay options and rewarding challenges, the kit turns every task into a fun adventure. Your child will stay motivated and excited to learn more, making it a perfect alternative to other DIY toys.
      </Typography>
    </Box> */}

    {/* Gamified Learning */}
    <Box display="flex" mb={2}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', flexBasis: '35%' }} align="left">
      🎮 GAMIFIED LEARNING EXPERIENCE
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ flexBasis: '65%' }} align="left">
      To keep kids engaged, we've gamified the learning process! With multiple gameplay options and rewarding challenges, the kit turns every task into a fun adventure. Your child will stay motivated and excited to learn more, making it a perfect alternative to other DIY toys.
      </Typography>
    </Box>

  </Box>
</Box>

      <Box textAlign="center" mt={4}>
  

  <Box textAlign="center" mt={4}>
  <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 1 }}>
    Features
  </Typography>
  <Box sx={{ borderBottom: '2px solid #f4f6f7', width: '1250px', margin: '0 auto' }} /><br/>

  {/* New text and description below the title */}
  

  {/* Grid of Images with Text */}
  <Grid container spacing={2} justifyContent="center">
    {/* First Row of Images */}
    {featuremedia.map((media,index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
      <Box
        sx={{
          textAlign: 'center',
          padding: 1.5, // Reducing padding to make the box smaller
          border: '2px solid #000',
          borderRadius: '8px',
          maxWidth: '90%', // Set max-width to make the box smaller
          margin: '0 auto', // Center the box
        }}
      >
        <img
          src={media.url}
          alt={`Image ${index}`}
          style={{ width: '100%', height: 'auto' }}
        />
        {/* <Typography variant="body1" sx={{ mt: 1 }}>
          Image {index} Description
        </Typography> */}
      </Box>
    </Grid>
    
    ))}

    {/* Second Row of Images */}
    {/* {[5, 6, 7, 8].map((index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Box sx={{ textAlign: 'center', padding: 2 }}>
          <img
            src={`https://via.placeholder.com/300x200?text=Image+${index}`}
            alt={`Image ${index}`}
            style={{ width: '100%', height: 'auto' }}
          />
          <Typography variant="body1" sx={{ mt: 1 }}>
            Image {index} Description
          </Typography>
        </Box>
      </Grid>
    ))} */}
  </Grid>
  {/* New text and description below the title */}
  <Box textAlign="center" mt={4}>
  <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 1 }}>
    In Box
  </Typography>
  <Box sx={{ borderBottom: '2px solid #f4f6f7', width: '1250px', margin: '0 auto' }} />

  {/* Grid of Images with Text */}
  <Grid container justifyContent="center">
  {/* Single Image */}
  <Grid item xs={12}>
    <Box sx={{ textAlign: 'center', padding: 2 }}>
      <img
        src={hw}
        alt="Full Width Image"
        style={{ width: '100%', height: 'auto' }}
      />
      {/* <Typography variant="body1" sx={{ mt: 1 }}>
        {featuremedia[0].description || 'Image Description'}
      </Typography> */}
    </Box>
  </Grid>
</Grid>

    {/* Second Row of Images */}
    {/* {[5, 6, 7, 8].map((index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Box sx={{ textAlign: 'center', padding: 2 }}>
          <img
            src={`https://via.placeholder.com/300x200?text=Image+${index}`}
            alt={`Image ${index}`}
            style={{ width: '100%', height: 'auto' }}
          />
          <Typography variant="body1" sx={{ mt: 1 }}>
            Image {index} Description
          </Typography>
        </Box>
      </Grid>
    ))} */}
  {/* </Grid> */}
  <Box p={2}>
      {/* Centered Title with Underline */}
      <Box textAlign="center" mt={4}>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 1 }}>
          Amazing Robo Car
        </Typography>
        <Box sx={{ borderBottom: '2px solid #f4f6f7', width: '1250px', margin: '0 auto' }} />
      </Box>

      {/* Full-Width Video Player */}
      <Box mt={4} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <iframe width="100%" height="450" src="https://www.youtube.com/embed/FOrE6sohnDo?si=gF4Fu5PU3XqD7Iy7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </Box>
    </Box>
</Box>

</Box>


</Box>
      </Box>
      <Box textAlign="center" mt={4}>
  <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 1 }}>
    Testimonials
  </Typography>
  <Box sx={{ borderBottom: '2px solid #f4f6f7', width: '1250px', margin: '0 auto' }} />

  {/* Grid of Testimonials */}
  <Grid container spacing={2} justifyContent="center">
  {testimonials.map((testimonial, index) => (
    <Grid item xs={12} sm={6} md={4} key={index}>
      <Box sx={{ textAlign: 'center', padding: 2 }}>
        {/* Display video in an iframe */}
        <iframe
          width="300"
          height="200"
          src={testimonial.video} // Ensure this is a valid video URL (like a YouTube link)
          title={`Testimonial Video ${index + 1}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
        
        {/* Fallback content if the video cannot be played */}
        {/* <Typography variant="body1" sx={{ mt: 1 }}>
          {testimonial.title}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {testimonial.description}
        </Typography> */}
      </Box>
    </Grid>
  ))}
</Grid>


</Box>

{/* <FooterBar/> */}
    </Box>
    
  );
};

export default ProductPage2;
