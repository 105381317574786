import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Link, Route, Switch } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import {
  Typography,
  Grid,
  StepLabel,
  Hidden,
  CardHeader,
} from "@material-ui/core";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import { HOST_URL } from "../action/api";
import TaskPage from "./Tasks/TaskPage";
import { Box } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";

import watermark from "../../src/dashboard/assets/watermark.png";
import LevelDescription from "./LevelDescription";
import CryptoJS from "crypto-js";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiStepper-alternativeLabel": {
      backgroundColor: "inherit",
    },
    "& .MuiStepper-root": {
      flexWrap: "wrap",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  card: {
    maxWidth: 300,
    maxHeight:"400px",
    minWidth: 270,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  lablearro: {
    overflow: "inherite",
    maxWidth: 300,
    margin: "auto",
  },
  media: {
    height: 140,
    paddingTop: "56.25%",
  },
  content: {
    textAlign: "left",
    // padding: muiBaseTheme.spacing.unit * 3
  },
  heading: {
    fontWeight: "bold",
  },
  subheading: {
    lineHeight: 1.8,
  },
  LabelImportantIcon: {
    position: "relative",
    top: "-150px",
    left: "258px",
    bottom: 0,
    right: "0",
    fontSize: "50px",
  },
  lockIcon: {
    background: "#11568e", // Use a brighter color of your choice, e.g., #FFCC00
    color: "#FFFFFF",
    width: "60px",
    height: "60px",
    margin: "auto",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: -200,
  },
}));

function Challenge(props) {
  const classes = useStyles();
  const [decryptedData, setDecryptedData] = useState("");
  useEffect(() => {
    const encryptedData = props?.challenge.data.photo;
    const base64Key ='jW1DMN6Gd62k4pQdssZBG0y1M7SldkWZTYIEENToswE=';

    const decryptData = () => {
      const key = CryptoJS.enc.Base64.parse(base64Key);

      const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
      const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
      const ciphertext = CryptoJS.lib.WordArray.create(
        dataBytes.words.slice(4)
      );

      const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });
      const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
      setDecryptedData(decryptedText);
    };

    decryptData();
  }, [props.challenge.data.photo]);

  console.log("anil kumar",props.challenge.data)
  return (
    <Grid
      xs
      item
      style={{
        opacity: props.challenge.index > props.challenge.disabled - 1 ? 0.4 : 1,
        pointerEvents:
          props.challenge.index > props.challenge.disabled - 1
            ? "none"
            : "initial",
      }}
    >
    
      <Link
        to={{
          pathname: `/dashboard/levels/tasks/content`,
          search: `?task=${props?.challenge.data.task_code}`,
          state: { tasks: props },
        }}
      >
        <div className={classes.lablearro}>
          <Card className={classes.card}>
            <CardHeader
              style={{ textAlign: "center" }}
              title={props?.challenge.data.task_name}
              titleTypographyProps={{
                style: {
                  color: "#4a94d0",
                  fontSize: "17px",
                  fontWeight: "bold",
                },
              }}
            />

            <CardMedia
              className={classes.media}
              image={decryptedData}
                // props?.challenge.data.photo
                  // ? `${HOST_URL}${props?.challenge.data.photo}`
                  // : watermark
              // }
            />
            <Hidden xsDown>
              <LabelImportantIcon
                className={classes.LabelImportantIcon}
                color="primary"
              />
            </Hidden>
            <CardContent className={classes.content}>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#ee383a",
                  fontSize: "25px",
                  fontWeight: "bold",
                }}
              >
                {props.challenge.data.task_code}
              </Typography>
              <Typography
                className={"MuiTypography--heading"}
                variant={"h6"}
                gutterBottom
              >
                Task : {props.challenge.data.task_number}
              </Typography>
              {props.challenge.index > props.challenge.disabled - 1 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  position="relative"
                >
                  <Box className={classes.lockIcon}>
                    <LockIcon />
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </div>
      </Link>
    </Grid>
  );
}

export default function Challenges(props) {
  return (
    <>
      <Switch>
        <Route
          path="/dashboard/levels/tasks/content"
          render={(props) => (
            <TaskPage tasks={props?.location?.state?.tasks?.challenge?.data} /> // Access the 'tasks' property from 'location.state'
          )}
        />
        <Route>
          <LevelDescription levelDec={props.levels} />
          <Task tasks={props.content} active_task={props.active_task} />
        </Route>
      </Switch>
    </>
  );
}

function Task(props) {
  const classes = useStyles();
  const [activeStep] = React.useState(0);
  const [disabled, setDisabled] = useState("");

  useEffect(() => {
    setDisabled(props?.active_task.length);
  }, [props?.active_task]);

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {props.tasks.map((label, index) => (
          <Step key={index}>
            <StepLabel>Task-{label.task_number}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid xs container spacing={3}>
        {props.tasks.map((data, index) => (
          <Challenge
            key={index}
            challenge={{ data: data, index: index, disabled: disabled }}
          />
        ))}
      </Grid>
    </div>
  );
}
