import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import { HOST_URL } from "../../action/api";

export default function TeamEdit({ team_name, stem_project_idea, project_descripition, getIsplDetails }) {
  const [open, setOpen] = React.useState(false);
  const [teamName, setTeamName] = React.useState(team_name);
  const [projectName, setProjectName] = React.useState(stem_project_idea);
  const [projectDesc, setProjectDesc] = React.useState(project_descripition);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {

    var token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "team_name": teamName,
      "stem_project_idea": projectName,
      "project_description": projectDesc
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${HOST_URL}ispl/team/update`, requestOptions)
      .then(response => response.text())
      .then(result => {
        getIsplDetails()
        handleClose(); // close the dialog box after submission
      })
      .catch(error => console.log('error', error));
  };

  return (
    <div>
      {/* <EditIcon onClick={handleClickOpen}/> */}
      <Button onClick={handleClickOpen}>
        Edit
        <EditIcon />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Team Name
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="teamName"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            type="name"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogContent>
          <DialogContentText>
            Project idea name
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="projectName"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)} type="name"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogContent>
          <DialogContentText>
            Project idea Description
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="projectDesc"
            value={projectDesc}
            onChange={(e) => setProjectDesc(e.target.value)}
            type="name"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
