import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services1: [
        {
          icon: "pe-7s-diamond",
          title: "AI Driven Education Innovation",
          desc:
            "Artificial Intelligence based  S.T.E.M  Learning  aligned with National and International standards.",
        },
        {
          icon: "pe-7s-display2",
          title: "Learning Content",
          desc:
            "Cutting edge technologies driving STEM learning content aligned with national and international  standards.",
        },
        {
          icon: "pe-7s-piggy",
          title: "AI & Robotics STEM kits",
          desc:
            "Artificial Intelligence assisted STEM robotic educational Kits. ",
        },
      ],
      services2: [
        {
          icon: "pe-7s-science",
          title: "Ecosystem",
          desc:
            " An Ecosystem that allows students to learn by doing. We have focused on developing a way for students to assist each other in learning.",
        },
        {
          icon: "pe-7s-news-paper",
          title: "Assessment",
          desc:
            "Strategies positioning the assessment of student performance with the goals of inquiry-centered Experence based learing.",
        },
        {
          icon: "pe-7s-plane",
          title: "Cognitive Learning",
          desc:
            "AI driven cognitive learning.",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className={"section " + this.props.sectionClass} id="services">
          <Container>
            {/* section title */}
            <SectionTitle
              title="What we do?"
              desc="In the digital age, technology empowers, yet also poses a few 
              challenges for students. How do we continue to teach and learn if we 
              cannot see our teachers. To help solve this dilemma, Ilearnplace was 
              created. It is an ecosystem where Thinkers engage in cognitive 
              learning through experience (experience), that enrich essential 
              skills such as STEM skills, critical & logical reasoning, communication, 
              problem-solving, leadership & more. Thinkers gain online access to 
              their activities(experiences) from anywhere of their choice via iLearnplace 
              Apps on Android & iOS. Thinkers can also access their projects from any 
              device in their possession, thus enabling mobility."
            />

            <Row className="mt-4">
              {/* services box */}
              <ServiceBox services={this.state.services1} />
            </Row>

            <Row className="mt-4">
              {/* service box */}
              <ServiceBox services={this.state.services2} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
