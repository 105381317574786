import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { Grid } from '@material-ui/core';
import Seminarimage from '../OnlineSeminar/seminarImage1.jpeg'
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    media: {
        maxWidth: 400,
        height: 540,
        borderRadius: '15px'

    },
});

export default function OnlineSeminar() {
    const classes = useStyles();

    return (
        <Grid style={{ textAlign: '-webkit-center' }}>
            <Link target="_blank" rel='noreferrer' to={{pathname: 'https://meet.google.com/ury-uvvo-gpk'}}>
                <Card style={{boxShadow:'none', paddingTop:'50px'}} >
                    <CardMedia
                        className={classes.media}
                        image={Seminarimage}
                        title="Contemplative Reptile"
                    />
                    
                </Card>
            </Link>
        </Grid >
    );
}


