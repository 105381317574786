import { GET_ALL_INFO } from "../reducerKeys";

const initialState = {};

export default function allInfo(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_INFO:
      return { ...state, allInfo: action.payload };
    default:
      return state;
  }
}
