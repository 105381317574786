// import React, { useState, useEffect, useRef } from "react";
// import {
//   Box,
//   Grid,
//   Typography,
//   Button,
//   Container,
//   Paper,
// } from "@material-ui/core";

// import { Row, Col } from "reactstrap";
// import { useParams } from "react-router-dom";
// import GameLevelImage from "../Pricing/assets/ISPL-202333.jpg";
// import Navbar from "./Navbar";
// import Buynow from "./buyNow";
// import { HOST_URL } from "../../action/api";
// import Footer from "../Footer/footer";
// import Section from "../../pages/Index2/section";
// import ModalSection from "../common/ModalSection";
// import { Link } from "react-router-dom";
// import Login from "./BuyNoww";

// export default function GameDetails(props) {

//   const [showLessDescription, setShowLessDescription] = useState(false);

//   const toggleDescription = () => {
//     setShowLessDescription(!showLessDescription);
//   }

//   const [showLessProgram, setShowLessProgram] = useState(true);

//   const toggleProgram = () => {
//     setShowLessProgram(!showLessProgram);
//   };

//   const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
//     const discountedPrice = originalPrice - (originalPrice * (discountPercentage / 100));
//     return discountedPrice.toFixed(2); // Assuming 2 decimal places for the price
//   };

//   // const sata = {
//   //   membership_price: 3000, // Example original price
//   // };

//   // const discountPercentage = 10;


//   const { id } = useParams();

//   const [data, setData] = useState([]);
//   const [isOpen, setIsOpen] = useState(false);

//   const [isLoaded, setIsLoaded] = useState(false);

//   const childRef = useRef(null);

//   const callModal = () => {
//     childRef.current.openModal();
//   };

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         const response = await fetch(`${HOST_URL}games/`);
//         const item = await response.json();
//         if (item !== null) {
//           setIsLoaded(true);
//           setData(item);
//         }
//       } catch (e) {
//         console.error(e);
//       }
//     }
//     fetchData();
//   }, []);
//   console.log(data);
//   return (
//     <>
//       <Navbar /><br></br>
//       <br></br><br></br>
//       <Container sx={{ py: 4 }}>

//         <Box style={{ position: "relative" }}>
//           <Grid container spacing={2}>
//             <Grid item xs={16} md={16}>
//               <Paper elevation={6} variant="outlined">
//                 <Box
//                   p={4}
//                   sx={{ display: "flex", justifyContent: "center" }}
//                 >
//                   <img
//                     src={GameLevelImage}
//                     style={{
//                       width: "100%",
//                       height: "auto",
//                       objectFit: "cover",
//                     }}
//                   />
//                   <p
//                     className="play-shadow"
//                     style={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       position: "absolute",
//                       top: "50%",
//                       left: "50%",
//                       transform: "translate(-50%, -50%)",
//                       width: "100%",
//                       height: "auto",
//                     }}
//                   >

//                     <Link to="#" onClick={callModal} className="play-btn video-play-icon">
//                       <i className="mdi mdi-play text-center"></i>
//                     </Link>
//                   </p>
//                   <ModalSection
//                     ref={childRef}
//                     channel="youtube"
//                     videoId="SIpCbSJJXlM"
//                     mute={1}
//                     autoplay={1}
//                   />
//                 </Box>
//               </Paper>
//             </Grid>
//           </Grid>
//         </Box>
//       </Container>

//       <Box mt={12} mb={10}>
//         {data
//           .filter((e) => e.id == `${id}`)
//           .map((data, index) => {
//             return (
//               <Container key={index} sx={{ py: 4 }}>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12} md={6}>
//                     <Paper elevation={6} variant="outlined">
//                       <Box
//                         p={4}
//                         sx={{ display: "flex", justifyContent: "center" }}
//                       >
//                         <img
//                           src={`${HOST_URL}${data.gamephoto}/`}
//                           width="70%"
//                           height="auto"

//                           alt="your-image-alt"
//                         />
//                       </Box>
//                     </Paper>
//                   </Grid>
//                   <Grid item xs={12} md={6}>
//                     <Box py={3} px={3}>
//                       <Typography variant="h6" sx={{ mr: 1 }}>
//                         {data.game_name} :{" "}
//                         <span style={{ color: "#6c757d" }}>{data.title}</span>{" "}
//                       </Typography>
//                       <Typography variant="h6" sx={{ mr: 1 }}>
//                         <span style={{ fontWeight: 'bold' }}>Product Type :</span>
//                         {/* Product Type :{" "} */}
//                         <span style={{ color: "#6c757d" }}>
//                           {data.product_type}
//                         </span>
//                       </Typography>
//                       <Typography variant="h6" sx={{ mr: 1 }}>
//                         <span style={{ fontWeight: 'bold' }}>Age Group :</span>
//                         {/* Age Group :{" "} */}
//                         <span style={{ color: "#6c757d" }}>
//                           {data.age_group}
//                         </span>{" "}
//                       </Typography>
//                       <Typography variant="h6" sx={{ mr: 1 }}>
//                         <span style={{ fontWeight: 'bold' }}>Emi :</span>
//                         {/* EMI :{" "} */}
//                         <span style={{ color: "#6c757d" }}>{data.emi}</span>
//                       </Typography>
//                       <Typography variant="h6" sx={{ mr: 1 }}>
//                         <span style={{ fontWeight: 'bold' }}>Shipping :</span>
//                         {/* Shipping :{" "} */}
//                         <span style={{ color: "#6c757d" }}>
//                           {data.shipping}
//                         </span>{" "}
//                       </Typography>
//                       <Typography variant="h6" sx={{ mr: 1 }}>
//                         <span style={{ fontWeight: 'bold' }}>Total Kits :</span>
//                         {/* Total Kits :{" "} */}
//                         <span style={{ color: "#6c757d" }}>
//                           {data.total_kits}
//                         </span>
//                       </Typography>
//                       <Typography variant="h6" sx={{ mr: 1 }}>
//                         <span style={{ fontWeight: 'bold' }}>Total Game Level :</span>
//                         {/* Total Game Level :{" "} */}
//                         <span style={{ color: "#6c757d" }}>
//                           {data.total_game_level}
//                         </span>
//                       </Typography>
//                       <Typography variant="h6" sx={{ mr: 1 }}>
//                         <span style={{ fontWeight: 'bold' }}>Membership :</span>
//                         {/* Membership :{" "} */}
//                         <span style={{ color: "#6c757d" }}><br></br>
//                           {data.membership}
//                         </span>
//                       </Typography>
//                       <Typography variant="h6" sx={{ mr: 1 }}>
//                         <span style={{ fontWeight: 'bold' }}>Mode :</span>
//                         {/* Mode :{" "} */}
//                         <span style={{ color: "#6c757d" }}>{data.mode}</span>
//                       </Typography>
//                       <Typography variant="h6" sx={{ mr: 1 }}>
//                         <span style={{ fontWeight: 'bold' }}>Price :</span>
//                         {/* Mode :{" "} */}
//                         {/* <span style={{ color: "#6c757d" }}>{data.membership_price}</span> */}
//                         <span style={{ color: "#6c757d", textDecoration: 'line-through', marginRight: '0.5rem' }}>
//                           {data.membership_price}
//                         </span>
//                         <span style={{ color: "#6c757d", fontWeight: 'bold' }}>
//                           {calculateDiscountedPrice(data.membership_price, data.discount)}
//                         </span>
//                         <Typography variant="body2" sx={{ mt: 1 }}>
//                           <span style={{ color: "#6c757d" }}>
//                             Save {data.discount}% with this discount offer!
//                           </span>

//                         </Typography>
//                       </Typography>
//                       <br></br><br></br>
//                       <br></br><br></br>

//                       <Box my={2} style={{ marginLeft: "80%" }}>
//                         {/* <Buynow pricings={data.game_name} /> */}
//                         <Login />

//                       </Box>
//                       <Grid item xs={12} md={6}>
//                         <Box py={3} px={3}>
//                           <Typography variant="h6" sx={{ mr: 4 }} style={{marginLeft:'10%'}} >
//                             <span style={{ fontWeight: 'bold' }}>Description :</span>
//                             {/* Description :{" "} */}
//                             <span style={{ color: "#6c757d" }}><br></br>
//                               {/* {data.description} */}
//                               {showLessDescription ? data.description : `${data.description.slice(0, 350)}...`}
//                               <span onClick={toggleDescription}>
//                                 <span style={{ background: "#4a94d0" }}>
//                                   {showLessDescription ? 'Read Less' : 'Read More'}
//                                 </span>
//                               </span>

//                               {/* {showLessDescription ? data.description : `${data.description.slice(0, 100)}`}
//                             <span  onClick={toggleDescription}>
//                               {toggleDescription ? "...Read More" : "...Read Less"}
//                             </span> */}
//                             </span>
//                           </Typography>
//                         </Box>
//                       </Grid>


//                     </Box>
//                   </Grid>
//                 </Grid>

//                 {/* ------------- */}

//                 <Box 
//                   my={6}
//                   sx={{
//                     textTransform: "uppercase",
//                     width: "300px",
//                     margin: "auto",
//                     color: "#11568e",
//                     p: 1,
//                   }}
//                 >
//                   <Typography variant="h6" sx={{ mr: 1 }} >
//                     <span style={{ fontWeight: 'bold' }}>Game Levels</span>
//                   </Typography>
//                 </Box>
//                 <Grid container spacing={2}>
//                   <Grid container spacing={2}>
//                     <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
//                       <Box py={4} px={3}>
//                         <Typography variant="h6" sx={{ mr: 1 }} style={{ lineHeight: 1 }} >
//                           <span style={{ fontWeight: 'bold' }}>KeySkills :</span><br></br>
//                           <span style={{ color: "#6c757d", whiteSpace: 'pre-line' }}>
//                             {data.key_skills.replace(/\n|\d+/g, (match) => {
//                               if (match === '\n') {
//                                 return '\n';
//                               } else {
//                                 return '\n' + match;
//                               }
//                             })}

//                           </span>
//                         </Typography><br></br>
//                         <br></br>

//                         <Grid container spacing={3}>


//                           <Grid item xs={12} md={6} style={{ marginLeft: '-100%', position: 'relative' }}>

//                             <Paper elevation={6} variant="outlined">
//                               <Box
//                                 p={4}
//                                 sx={{ display: "flex", justifyContent: "center" }}
//                               >
//                                 <img
//                                   src={`${HOST_URL}${data.gamephoto}/`}
//                                   alt="your-image-alt"
//                                   style={{
//                                     position: 'absolute',
//                                     top: 0,
//                                     left: 600,
//                                     width: '180%',
//                                     height: 'auto%',
//                                     objectFit: 'cover',

//                                   }}
//                                 />
//                               </Box>
//                             </Paper>
//                             <Typography variant="h6" sx={{ mr: 1 }} style={{ marginLeft: '550%', lineHeight: 1 }}><br></br>
//                               <span style={{ fontWeight: 'bold' }}>Concept:</span><br></br>
//                               <span style={{ color: "#6c757d", whiteSpace: 'pre-line', display: 'flex', whiteSpace: 'pre' }}>
//                                 {data.key_concept.replace(/\n|\d+/g, (match) => {
//                                   if (match === '\n') {
//                                     return '\n';
//                                   } else {
//                                     return '\n' + match;
//                                   }
//                                 })}

//                               </span>
//                             </Typography><br></br><br></br>
//                           </Grid>
//                         </Grid>
//                         );
//                         <Grid container spacing={3}>
//                           <Grid item xs={12} md={6} style={{ position: 'relative' }}>

//                             <Paper elevation={6} variant="outlined">

//                                 <img
//                                   src={`${HOST_URL}${data.gamephoto}/`}
//                                   alt="your-image-alt"
//                                   style={{
//                                     position: 'absolute',
//                                     top: 10,
//                                     left: 600,
//                                     width: '200%',
//                                     height: 'auto',
//                                     objectFit: 'cover',

//                                   }}
//                                 />


//                             </Paper>

//                             <Typography variant="h6" sx={{ mr: 1 }} style={{ lineHeight: 1 }}>
//                               <span style={{ fontWeight: 'bold' }}>Learning_Project :</span><br></br>
//                               <span style={{ color: "#6c757d", whiteSpace: 'pre-line' }}>
//                                 {data.learning_projects.replace(/\n|\d+/g, (match) => {
//                                   if (match === '\n') {
//                                     return '\n';
//                                   } else {
//                                     return '\n' + match;
//                                   }
//                                 })}
//                               </span>
//                             </Typography><br></br><br></br><br></br>

//                           </Grid>
//                         </Grid>
//                         <Grid item xs={12} md={6}>
//                           <Box py={3} px={3}>

//                             <Typography variant="h6" sx={{ mr: 1 }} >
//                               <span style={{ fontWeight: 'bold' }}>Program :</span><br></br>
//                               {/* Program : */}
//                               <span style={{ color: "#6c757d" }}>
//                                 {/* {data.program} */}
//                                 {/* {showLessProgram ? data.program : `${data.program.slice(0, 50)}...`}
//                             <button onClick={toggleProgram}>
//                               {showLessProgram ? 'Read Less' : 'Read More'}
//                             </button> */}
//                                 {/* {showLessProgram ? data.program : `${data.program.substring(0, 50)}`}
//                             <span onClick={toggleProgram}>
//                               {showLessProgram ? "...Read Less" : "...Read More"}
//                             </span> */}


//                                 <Typography variant="body1">
//                                   {showLessProgram ? (
//                                     <div>
//                                       {data.program.slice(0, 250)}
//                                       {data.program.length > 50 && (
//                                         <span style={{ background: "#4a94d0" }}>
//                                           <span onClick={toggleProgram}>...Read More</span>
//                                         </span>
//                                       )}
//                                     </div>
//                                   ) : (
//                                     <div>
//                                       {data.program}
//                                       <span style={{ background: "#4a94d0" }}>
//                                         <span onClick={toggleProgram}>...Read Less</span>
//                                       </span>
//                                     </div>
//                                   )}
//                                 </Typography>
//                               </span>
//                             </Typography><br></br>
//                           </Box>
//                         </Grid>
//                         {/* <Typography variant="h6" sx={{ mr: 1 }} style={{ marginRight: '-130%' }}> */}
//                         {/* <span style={{ fontWeight: 'bold' }}>Description :</span><br></br> */}
//                         {/* Description : */}
//                         {/* <span style={{ color: "#6c757d" }}> */}
//                         {/* {data.description} */}
//                         {/* {showLessDescription ? data.description : `${data.description.slice(0, 50)}...`}
//                             <button onClick={toggleDescription}>
//                               {showLessDescription ? 'Read Less' : 'Read More'}
//                             </button> */}



//                         {/* <Typography variant="body1">
//                               {showLessDescription ? (
//                                 <div>
//                                   {data.description.slice(0, 50)}
//                                   {data.description.length > 50 && (
//                                     <span onClick={toggleDescription}>...Read More</span>
//                                   )}
//                                 </div>
//                               ) : (
//                                 <div>
//                                   {data.description}
//                                   <span onClick={toggleDescription}>...Read Less</span>
//                                 </div>
//                               )}
//                             </Typography> */}


//                         {/* </span>{" "} */}
//                         {/* </Typography> */}
//                       </Box>
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                       <Paper elevation={6} variant="outlined">
//                         <Box
//                           p={3}
//                           sx={{ display: "flex", justifyContent: "center" }}
//                         >
//                           <img
//                             src={`${HOST_URL}${data.gamephoto}/`}
//                             width="80%"
//                             height="auto"
//                             alt="your-image-alt"
//                           />

//                         </Box>
//                       </Paper>
//                     </Grid>

//                   </Grid>
//                 </Grid>
//               </Container>
//             );
//           })
//         }
//       </Box>
//       <Footer />
//     </>
//   );
// }





// import React, { useState, useEffect, useRef } from "react";
// import { Box, Grid, Typography, Button, Container, Paper } from "@material-ui/core";
// import { useParams } from "react-router-dom";
// import GameLevelImage from "../Pricing/assets/ISPL-202333.jpg";
// import Navbar from "./Navbar";
// import Buynow from "./buyNow";
// import { HOST_URL } from "../../action/api";
// import Footer from "../Footer/footer";
// import Section from "../../pages/Index2/section";
// import ModalSection from "../common/ModalSection";
// import { Link } from "react-router-dom";
// import Login from "./BuyNoww";

// const GameDetails = (props) => {
//   const [showLessDescription, setShowLessDescription] = useState(false);
//   const [showLessProgram, setShowLessProgram] = useState(true);

//   const toggleDescription = () => {
//     setShowLessDescription(!showLessDescription);
//   };

//   const toggleProgram = () => {
//     setShowLessProgram(!showLessProgram);
//   };

//   const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
//     const discountedPrice = originalPrice - (originalPrice * (discountPercentage / 100));
//     return discountedPrice.toFixed(2); // Assuming 2 decimal places for the price
//   };

//   const { id } = useParams();

//   const [data, setData] = useState([]);
//   const [isOpen, setIsOpen] = useState(false);
//   const [isLoaded, setIsLoaded] = useState(false);

//   const childRef = useRef(null);

//   const callModal = () => {
//     childRef.current.openModal();
//   };

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         const response = await fetch(`${HOST_URL}games/`);
//         const item = await response.json();
//         if (item !== null) {
//           setIsLoaded(true);
//           setData(item);
//         }
//       } catch (e) {
//         console.error(e);
//       }
//     }
//     fetchData();
//   }, []);

//   return (
//     <>
//       <Navbar />
//       <br />
//       <br />
//       <br />
//       <Container sx={{ py: 4 }}>
//         <Box style={{ position: "relative" }}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={12}>
//               <Paper elevation={6} variant="outlined">
//                 <Box p={4} sx={{ display: "flex", justifyContent: "center" }}>
//                   <img
//                     src={GameLevelImage}
//                     style={{
//                       width: "100%",
//                       height: "auto",
//                       objectFit: "cover",
//                       maxWidth: "100%",
//                     }}
//                     alt="Game Level"
//                   />
//                   <p className="play-shadow">
//                     <Link to="#" onClick={callModal} className="play-btn video-play-icon">
//                       <i className="mdi mdi-play text-center"></i>
//                     </Link>
//                   </p>
//                   <ModalSection
//                     ref={childRef}
//                     channel="youtube"
//                     videoId="SIpCbSJJXlM"
//                     mute={1}
//                     autoplay={1}
//                   />
//                 </Box>
//               </Paper>
//             </Grid>
//           </Grid>
//         </Box>
//       </Container>

//       <Box mt={12} mb={10}>
//         {data
//           .filter((e) => e.id == `${id}`)
//           .map((data, index) => (
//             <Container key={index} sx={{ py: 4 }}>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} md={6}>
//                   <Paper elevation={6} variant="outlined">
//                     <Box p={4} sx={{ display: "flex", justifyContent: "center" }}>
//                       <img
//                         src={`${HOST_URL}${data.gamephoto}/`}
//                         width="70%"
//                         height="auto"
//                         alt="Game Photo"
//                       />
//                     </Box>
//                   </Paper>
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <Box py={3} px={3}>
//                     <Typography variant="h6" sx={{ mr: 1 }}>
//                       {data.game_name} :{" "}
//                       <span style={{ color: "#6c757d" }}>{data.title}</span>
//                     </Typography>
//                     <Typography variant="h6" sx={{ mr: 1 }}>
//                       <span style={{ fontWeight: 'bold' }}>Product Type :</span>{" "}
//                       <span style={{ color: "#6c757d" }}>{data.product_type}</span>
//                     </Typography>
//                     <Typography variant="h6" sx={{ mr: 1 }}>
//                       <span style={{ fontWeight: 'bold' }}>Age Group :</span>{" "}
//                       <span style={{ color: "#6c757d" }}>{data.age_group}</span>{" "}
//                     </Typography>
//                     <Typography variant="h6" sx={{ mr: 1 }}>
//                       <span style={{ fontWeight: 'bold' }}>Emi :</span>{" "}
//                       <span style={{ color: "#6c757d" }}>{data.emi}</span>
//                     </Typography>
//                     <Typography variant="h6" sx={{ mr: 1 }}>
//                       <span style={{ fontWeight: 'bold' }}>Shipping :</span>{" "}
//                       <span style={{ color: "#6c757d" }}>{data.shipping}</span>{" "}
//                     </Typography>
//                     <Typography variant="h6" sx={{ mr: 1 }}>
//                       <span style={{ fontWeight: 'bold' }}>Total Kits :</span>{" "}
//                       <span style={{ color: "#6c757d" }}>{data.total_kits}</span>
//                     </Typography>
//                     <Typography variant="h6" sx={{ mr: 1 }}>
//                       <span style={{ fontWeight: 'bold' }}>Total Game Level :</span>{" "}
//                       <span style={{ color: "#6c757d" }}>{data.total_game_level}</span>
//                     </Typography>
//                     <Typography variant="h6" sx={{ mr: 1 }}>
//                       <span style={{ fontWeight: 'bold' }}>Membership :</span>
//                       <br />
//                       <span style={{ color: "#6c757d" }}>{data.membership}</span>
//                     </Typography>
//                     <Typography variant="h6" sx={{ mr: 1 }}>
//                       <span style={{ fontWeight: 'bold' }}>Mode :</span>{" "}
//                       <span style={{ color: "#6c757d" }}>{data.mode}</span>
//                     </Typography>
//                     <Typography variant="h6" sx={{ mr: 1 }}>
//                       <span style={{ fontWeight: 'bold' }}>Price :</span>{" "}
//                       <span style={{ color: "#6c757d", textDecoration: 'line-through', marginRight: '0.5rem' }}>
//                         {data.membership_price}
//                       </span>
//                       <span style={{ color: "#6c757d", fontWeight: 'bold' }}>
//                         {calculateDiscountedPrice(data.membership_price, data.discount)}
//                       </span>
//                       <Typography variant="body2" sx={{ mt: 1 }}>
//                         <span style={{ color: "#6c757d" }}>
//                           Save {data.discount}% with this discount offer!
//                         </span>
//                       </Typography>
//                     </Typography>

//                     <Box my={2} style={{ marginLeft: "80%" }}>
//                       <Login />
//                     </Box>

//                     <Grid item xs={12} md={6}>
//                       <Box py={3} px={3}>
//                         <Typography variant="h6" sx={{ mr: 4 }} style={{ marginLeft: '10%' }}>
//                           <span style={{ fontWeight: 'bold' }}>Description :</span><br />
//                           <span style={{ color: "#6c757d" }}>
//                             {showLessDescription
//                               ? data.description
//                               : `${data.description.slice(0, 350)}...`}
//                             <span onClick={toggleDescription}>
//                               <span style={{ background: "#4a94d0" }}>
//                                 {showLessDescription ? 'Read Less' : 'Read More'}
//                               </span>
//                             </span>
//                           </span>
//                         </Typography>
//                       </Box>
//                     </Grid>
//                   </Box>
//                 </Grid>
//               </Grid>

//               <Box
//                 my={6}
//                 sx={{
//                   textTransform: "uppercase",
//                   width: "300px",
//                   margin: "auto",
//                   color: "#11568e",
//                   p: 1,
//                 }}
//               >
//                 <Typography variant="h6" sx={{ mr: 1 }} >
//                   <span style={{ fontWeight: 'bold' }}>Game Levels</span>
//                 </Typography>
//               </Box>

//               <Grid container spacing={2}>
//                 <Grid item xs={12} md={6}>
//                   <Box py={4} px={3}>
//                     <Typography variant="h6" sx={{ mr: 1 }} style={{ lineHeight: 1 }}>
//                       <span style={{ fontWeight: 'bold' }}>KeySkills :</span><br />
//                       <span style={{ color: "#6c757d", whiteSpace: 'pre-line' }}>
//                         {data.key_skills.replace(/\n|\d+/g, (match) => {
//                           if (match === '\n') {
//                             return '\n';
//                           } else {
//                             return '\n' + match;
//                           }
//                         })}
//                       </span>
//                     </Typography>
//                     <br /><br />
//                     <Grid container spacing={3}>
//                       <Grid item xs={12} md={6} style={{ marginLeft: '-100%', position: 'relative' }}>
//                         <Paper elevation={6} variant="outlined">
//                           <Box p={4} sx={{ display: "flex", justifyContent: "center" }}>
//                             <img
//                               src={`${HOST_URL}${data.gamephoto}/`}
//                               alt="Game Photo"
//                               style={{
//                                 position: 'absolute',
//                                 top: 0,
//                                 left: 600,
//                                 width: '180%',
//                                 height: 'auto%',
//                                 objectFit: 'cover',
//                               }}
//                             />
//                           </Box>
//                         </Paper>
//                         <Typography variant="h6" sx={{ mr: 1 }} style={{ marginLeft: '550%', lineHeight: 1 }}>
//                           <br />
//                           <span style={{ fontWeight: 'bold' }}>Concept:</span><br />
//                           <span style={{ color: "#6c757d", whiteSpace: 'pre-line', display: 'flex', whiteSpace: 'pre' }}>
//                             {data.key_concept.replace(/\n|\d+/g, (match) => {
//                               if (match === '\n') {
//                                 return '\n';
//                               } else {
//                                 return '\n' + match;
//                               }
//                             })}
//                           </span>
//                         </Typography>
//                         <br /><br />
//                       </Grid>
//                     </Grid>
//                     <Grid container spacing={3}>
//                       <Grid item xs={12} md={6} style={{ position: 'relative' }}>
//                         <Paper elevation={6} variant="outlined">
//                           <img
//                             src={`${HOST_URL}${data.gamephoto}/`}
//                             alt="Game Photo"
//                             style={{
//                               position: 'absolute',
//                               top: 10,
//                               left: 600,
//                               width: '200%',
//                               height: 'auto',
//                               objectFit: 'cover',
//                             }}
//                           />
//                         </Paper>
//                         <Typography variant="h6" sx={{ mr: 1 }} style={{ lineHeight: 1 }}>
//                           <span style={{ fontWeight: 'bold' }}>Learning_Project :</span><br />
//                           <span style={{ color: "#6c757d", whiteSpace: 'pre-line' }}>
//                             {data.learning_projects.replace(/\n|\d+/g, (match) => {
//                               if (match === '\n') {
//                                 return '\n';
//                               } else {
//                                 return '\n' + match;
//                               }
//                             })}
//                           </span>
//                         </Typography>
//                         <br /><br /><br />
//                       </Grid>
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                       <Box py={3} px={3}>
//                         <Typography variant="h6" sx={{ mr: 1 }}>
//                           <span style={{ fontWeight: 'bold' }}>Program :</span><br />
//                           <span style={{ color: "#6c757d" }}>
//                             {showLessProgram ? (
//                               <div>
//                                 {data.program.slice(0, 250)}
//                                 {data.program.length > 250 && (
//                                   <span style={{ background: "#4a94d0" }}>
//                                     <span onClick={toggleProgram}>...Read More</span>
//                                   </span>
//                                 )}
//                               </div>
//                             ) : (
//                               <div>
//                                 {data.program}
//                                 <span style={{ background: "#4a94d0" }}>
//                                   <span onClick={toggleProgram}>...Read Less</span>
//                                 </span>
//                               </div>
//                             )}
//                           </span>
//                         </Typography>
//                         <br />
//                       </Box>
//                     </Grid>
//                   </Box>
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <Paper elevation={6} variant="outlined">
//                     <Box p={3} sx={{ display: "flex", justifyContent: "center" }}>
//                       <img
//                         src={`${HOST_URL}${data.gamephoto}/`}
//                         width="80%"
//                         height="auto"
//                         alt="Game Photo"
//                       />
//                     </Box>
//                   </Paper>
//                 </Grid>
//               </Grid>
//             </Container>
//           ))}
//       </Box>
//       <Footer />
//     </>
//   );
// };

// export default GameDetails;




import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Container,
  Paper,
  Grid,
  Button,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import GameLevelImage from "../Pricing/assets/ISPL-202333.jpg";
import Navbar from "./Navbar";
import Buynow from "./buyNow";
import { HOST_URL } from "../../action/api";
import Footer from "../Footer/footer";
import Section from "../../pages/Index2/section";
import ModalSection from "../common/ModalSection";
import { Link } from "react-router-dom";
import Login from "./BuyNoww";
import '../../Index.css';

const GameDetails = (props) => {
  const [showLessDescription, setShowLessDescription] = useState(false);
  const [showLessProgram, setShowLessProgram] = useState(true);

  const toggleDescription = () => {
    setShowLessDescription(!showLessDescription);
  };

  const toggleProgram = () => {
    setShowLessProgram(!showLessProgram);
  };

  const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
    const discountedPrice = originalPrice - (originalPrice * (discountPercentage / 100));
    return discountedPrice.toFixed(2); // Assuming 2 decimal places for the price
  };

  const { id } = useParams();

  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const childRef = useRef(null);

  const callModal = () => {
    childRef.current.openModal();
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${HOST_URL}games/`);
        const item = await response.json();
        if (item !== null) {
          setIsLoaded(true);
          setData(item);
        }
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <Container sx={{ py: 4 }}>
        <Box style={{ position: "relative" }}>
          <Paper elevation={6} variant="outlined">
            <Box p={4} sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={GameLevelImage}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  maxWidth: "100%",
                }}
                alt="Game Level"
              />
              <p className="play-shadow">
                <Link to="#" onClick={callModal} className="play-btn video-play-icon">
                  <i className="mdi mdi-play text-center"></i>
                </Link>
              </p>
              <ModalSection
                ref={childRef}
                channel="youtube"
                videoId="SIpCbSJJXlM"
                mute={1}
                autoplay={1}
              />
            </Box>
          </Paper>
        </Box>
      </Container>

      <Box mt={12} mb={10}>
        {data
          .filter((e) => e.id == `${id}`)
          .map((data, index) => (
            <Container key={index} sx={{ py: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Paper elevation={6} variant="outlined">
                    <Box p={4} sx={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={`${HOST_URL}${data.gamephoto}/`}
                        width="100%"
                        height="auto"
                        alt="Game Photo"
                      />
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box py={3} px={3}>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      {data.game_name} :{" "}
                      <span style={{ color: "#6c757d" }}>{data.title}</span>
                    </Typography>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      <span style={{ fontWeight: 'bold' }}>Product Type :</span>{" "}
                      <span style={{ color: "#6c757d" }}>{data.product_type}</span>
                    </Typography>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      <span style={{ fontWeight: 'bold' }}>Age Group :</span>{" "}
                      <span style={{ color: "#6c757d" }}>{data.age_group}</span>{" "}
                    </Typography>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      <span style={{ fontWeight: 'bold' }}>Emi :</span>{" "}
                      <span style={{ color: "#6c757d" }}>{data.emi}</span>
                    </Typography>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      <span style={{ fontWeight: 'bold' }}>Shipping :</span>{" "}
                      <span style={{ color: "#6c757d" }}>{data.shipping}</span>{" "}
                    </Typography>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      <span style={{ fontWeight: 'bold' }}>Total Kits :</span>{" "}
                      <span style={{ color: "#6c757d" }}>{data.total_kits}</span>
                    </Typography>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      <span style={{ fontWeight: 'bold' }}>Total Game Level :</span>{" "}
                      <span style={{ color: "#6c757d" }}>{data.total_game_level}</span>
                    </Typography>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      <span style={{ fontWeight: 'bold' }}>Membership :</span>
                      <br />
                      <span style={{ color: "#6c757d" }}>{data.membership}</span>
                    </Typography>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      <span style={{ fontWeight: 'bold' }}>Mode :</span>{" "}
                      <span style={{ color: "#6c757d" }}>{data.mode}</span>
                    </Typography>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      <span style={{ fontWeight: 'bold' }}>Price :</span>{" "}
                      <span style={{ color: "#6c757d", textDecoration: 'line-through', marginRight: '0.5rem' }}>
                        {data.membership_price}
                      </span>
                      <span style={{ color: "#6c757d", fontWeight: 'bold' }}>
                        {calculateDiscountedPrice(data.membership_price, data.discount)}
                      </span>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        <span style={{ color: "#6c757d" }}>
                          Save {data.discount}% with this discount offer!
                        </span>
                      </Typography>
                    </Typography>

                    <Box my={2} sx={{ marginLeft: "auto" }}>
                      <Login />
                    </Box>

                    <Box mt={4} ml="auto">
                      <Typography variant="h6" sx={{ mr: 4 }}>
                        <span style={{ fontWeight: 'bold' }}>Description :</span><br />
                        <span style={{ color: "#6c757d" }}>
                          {showLessDescription
                            ? data.description
                            : `${data.description.slice(0, 350)}...`}
                          <span onClick={toggleDescription}>
                            <span style={{ background: "#4a94d0" }}>
                              {showLessDescription ? 'Read Less' : 'Read More'}
                            </span>
                          </span>
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Box
                my={6}
                sx={{
                  textTransform: "uppercase",
                  width: "300px",
                  margin: "auto",
                  color: "#11568e",
                  p: 1,
                }}
              >
                <Typography variant="h6" sx={{ mr: 1 }} >
                  <span style={{ fontWeight: 'bold' }}>Game Levels</span>
                </Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box py={4} px={3}>
                    <Typography variant="h6" sx={{ mr: 1 }} style={{ lineHeight: 1 }}>
                      <span style={{ fontWeight: 'bold' }}>KeySkills :</span><br />
                      <span style={{ color: "#6c757d", whiteSpace: 'pre-line' }}>
                        {data.key_skills.replace(/\n|\d+/g, (match) => {
                          if (match === '\n') {
                            return '\n';
                          } else {
                            return '\n' + match;
                          }
                        })}
                      </span>
                    </Typography>
                    <br /><br />
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Paper elevation={6} variant="outlined" style={{marginTop:"10%"}}>
                          <Box sx={{ display: "flex", justifyContent: "center" ,width:"190%",marginLeft:"-19%"}}>
                            <img
                              src={`${HOST_URL}${data.gamephoto}/`}
                              alt="Game Photo"
                              style={{
                                width: "80%",
                                height: "auto",
                                objectFit: "cover",
                                maxWidth: "100%",
                              }}
                            />
                          </Box>
                        </Paper>
                      </Grid>
                      
                      <br /><br />
                      <Grid item xs={12} md={6}>
                        <Box mt={4}>
                          <Typography variant="h6" sx={{ mr: 1 }} style={{ lineHeight: 1,marginLeft:"115%",marginRight:"-215%",marginTop:"60%" }}>
                            <span style={{ fontWeight: 'bold' }}>Concept:</span><br />
                            <span style={{ color: "#6c757d", whiteSpace: 'pre-line' }}>
                              {data.key_concept.replace(/\n|\d+/g, (match) => {
                                if (match === '\n') {
                                  return '\n';
                                } else {
                                  return '\n' + match;
                                }
                              })}
                            </span>
                          </Typography>
                          <br /><br />
                        </Box>
                      </Grid>
                      </Grid>
                    {/* </Grid> */}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper elevation={6} variant="outlined">
                    <Box p={3} sx={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={`${HOST_URL}${data.gamephoto}/`}
                        width="80%"
                        height="auto"
                        alt="Game Photo"
                      />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box mt={4}>
                    <Typography variant="h6" sx={{ mr: 1 }} style={{ lineHeight: 1 }}>
                      <span style={{ fontWeight: 'bold' }}>Learning_Project :</span><br />
                      <span style={{ color: "#6c757d", whiteSpace: 'pre-line' }}>
                        {data.learning_projects.replace(/\n|\d+/g, (match) => {
                          if (match === '\n') {
                            return '\n';
                          } else {
                            return '\n' + match;
                          }
                        })}
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper elevation={6} variant="outlined">
                    <Box p={3} sx={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={`${HOST_URL}${data.gamephoto}/`}
                        width="80%"
                        height="auto"
                        alt="Game Photo"
                      />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
              <Box mt={4}>
                <Typography variant="h6" sx={{ mr: 1 }}>
                  <span style={{ fontWeight: 'bold' }}>Program :</span><br />
                  <span style={{ color: "#6c757d" }}>
                    {showLessProgram ? (
                      <div>
                        {data.program.slice(0, 250)}
                        {data.program.length > 250 && (
                          <span style={{ background: "#4a94d0" }}>
                            <span onClick={toggleProgram}>...Read More</span>
                          </span>
                        )}
                      </div>
                    ) : (
                      <div>
                        {data.program}
                        <span style={{ background: "#4a94d0" }}>
                          <span onClick={toggleProgram}>...Read Less</span>
                        </span>
                      </div>
                    )}
                  </span>
                </Typography>
              </Box>
            </Container>
          ))}
      </Box>
      <Footer />
    </>
  );
};

export default GameDetails;


