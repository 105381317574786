// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   makeStyles,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   Button,
//   TextField,
//   Grid,
// } from "@material-ui/core";
// import BuyNowDetails from "./BuyNowDetails";
// import OrderSummary from "../membershipstudentpayment/OrderSummary";
// // import StudentPaytm from "../../payment/StudentPaytm";
// import { Container } from "reactstrap";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Paytm from "../../pages/NewIslp/Registration/paytm";
// import RazorpayButton from "../../pages/NewIslp/Registration/razorpay"
// import { useSelector } from "react-redux";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import {
//   TOAST_ERROR,
//   TOAST_SUCCESS,
//   calculateDiscountedPrice,
// } from "../../utils";
// import StudentPaytm from "../../payment/StudentPaytm";

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "space-evenly",
//     backgroundColor: "#fff00",
//     minHeight: "65vh",
//   },
//   accordion: {},
//   activeAccordion: {
//     backgroundColor: "#c5e1f6",
//   },
//   numberBox: {
//     display: "inline-flex",
//     alignItems: "center",
//     justifyContent: "center",
//     width: "26px",
//     height: "26px",
//     borderRadius: "50%",
//     backgroundColor: "#2196f3",
//     marginRight: theme.spacing(1),
//   },
//   numberText: {
//     color: "#fff",
//   },
//   accordiondetails: {
//     display: "block",
//   },
//   footer: {
//     display: "block",
//     marginTop: "3vh",
//     backgroundColor: theme.palette.grey[200],
//     [theme.breakpoints.down("sm")]: {
//       justifyContent: "start",
//       marginTop: "1vh"
//     },
//   },
//   footer1: {
//     display: "grid",
//     justifyContent: "end",
//     alignItems: "center",
//     marginTop: "3vh",
//     backgroundColor: theme.palette.grey[200],
//     [theme.breakpoints.down("sm")]: {
//       justifyContent: "start",
//       marginTop: "1vh"
//     },
//   },
//   input: {
//     // marginRight: theme.spacing(1),
//     // width: "100%",
//     // [theme.breakpoints.up("sm")]: {
//     //   width: "auto",
//     // },
//     padding: 12,
//   },
// }));

// export default function PaymentDetails() {
//   const classes = useStyles();
//   const [currentStep, setCurrentStep] = useState(1);
//   const selector = useSelector((state) => state);
//   const locationState = useLocation();
//   const [totalPrice, setTotalPrice] = useState();
//   const [game_name, setGame_name] = useState();
//   const [discount, setDiscount] = useState(0);
//   const [coupon, setCoupon] = useState();
//   const [address, setAddresses] = useState();
//   const [address_id, setAddress_id] = useState();
//   const [level_code, setLevel_code] = useState();
//   const [addressAdded, setAddressAdded] = useState(false);
//   // const [address, setAddress] = useState();
//   const validCoupon =
//     coupon == "ILP080L" ||
//     coupon == "ILP081L" ||
//     coupon == "ILP082L" ||
//     coupon == "ILP083L" ||
//     coupon == "ILP084L" ||
//     coupon == "ILP085L" ||
//     coupon == "ILP087L" ||
//     coupon == "ILP088L" ||
//     coupon == "ILP089L" ||
//     coupon == "ILP090L" ||
//     coupon == "ILP091L" ||
//     coupon == "ILP092L" ||
//     coupon == "ILP093L" ||
//     coupon == "ILP094L" ||
//     coupon == "ILP095L" ||
//     coupon == "ILP096L" ||
//     coupon == "ILP097L" ||
//     coupon == "ILP098L" ||
//     coupon == "ILP099L" ||
//     coupon == "ILP0100L"  ||
//     coupon == "ILP001L" ||
//     coupon == "ILP002L" ||
//     coupon == "ILP003L" ||
//     coupon == "ILP004L" ||
//     coupon == "ILP005L" ||
//     coupon == "ILP006L" ||
//     coupon == "ILP007L" ||
//     coupon == "ILP008L" ||
//     coupon == "ILP009L" ||
//     coupon == "ILP010L"
//           ? true
//       : false;
//   // console.log("locationState.levelDetails", locationState.levelDetails);
//   // console.log("selector", selector.allInfo.allInfo);
//   // const tatalPrice =
//   const handleAddressSave = () => {
//     // Your logic for handling address save
//   };

//   useEffect(() => {
//     setTotalPrice(
//       calculateDiscountedPrice(
//         locationState.levelDetails?.level.data.price,
//         locationState.levelDetails?.level.data.discount
//       )
//     );
//     setLevel_code(locationState.levelDetails?.level.data.level_code);
//   }, []);

//   const applyCoupon = () => {
//     if (address > 0 && validCoupon) {
//       TOAST_SUCCESS("Coupon Sucessfully Applied");
//       const discount = validCoupon ? 1000 : 0;
//       setDiscount(discount);
//     } else {
//       if (!address && validCoupon) {
//         TOAST_ERROR("Please fill Address");
//       } else if (!validCoupon) {
//         TOAST_ERROR("not applied");
//         const discount = validCoupon ? 1000 : 0;
//         setDiscount(discount);
//       } else {
//       }
//     }
//   };
//   return (
//     <Container maxWidth="sm">
//       <Accordion
//         expanded={currentStep === 1}
//         onChange={() => setCurrentStep(currentStep === 1 ? 0 : 1)}
//       >
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           className={currentStep === 1 ? classes.activeAccordion : ""}
//         >
//           <Box className={classes.numberBox}>
//             <Typography
//               variant="subtitle1"
//               component="span"
//               className={classes.numberText}
//             >
//               1
//             </Typography>
//           </Box>
//           <Typography variant="subtitle1" component="span">
//             DELIVERY ADDRESS
//           </Typography>
//         </AccordionSummary>
//         <AccordionDetails className={classes.accordiondetails}>
//           <Box className={classes.accordion}>
//           <BuyNowDetails setAddressesLength={setAddresses} onAddressSave={() => setAddressAdded(true)} />
//           </Box>
//         </AccordionDetails>
//       </Accordion>
//       <Accordion
//         expanded={currentStep === 2}
//         onChange={() => setCurrentStep(currentStep === 2 ? 0 : 2)}
//       >
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           className={currentStep === 2 ? classes.activeAccordion : ""}
//         >
//           <Box className={classes.numberBox}>
//             <Typography
//               variant="subtitle1"
//               component="span"
//               className={classes.numberText}
//             >
//               2
//             </Typography>
//           </Box>
//           <Typography variant="subtitle1" component="span">
//             ORDER SUMMARY
//           </Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Box className={classes.accordion}>
//             <OrderSummary
//               details={locationState.levelDetails}
//               discount={discount}
//               couponCode={coupon}
//             />
//           </Box>
//         </AccordionDetails>
//       </Accordion>
//       {/* <footer className={classes.footer}>
//         <Container maxWidth="sm">
//           <Typography
//             variant="body1"
//             align="center"
//             style={{ justifyContent: "space-between", display: "flex" }}
//           >
//             <TextField
//               className={classes.input}
//               variant="outlined"
//               size="small"
//               placeholder="Enter coupon code"
//               onChange={(e) => setCoupon(e.target.value)}
//             />
//             <Typography variant="h5" color="">
//               {totalPrice - discount} INR
//             </Typography>
//           </Typography>
//         </Container>
//       </footer>
//       <footer className={classes.footer1}>
//         <Container maxWidth="sm">
//           <Typography
//             variant="body1"
//             align="center"
//             style={{ justifyContent: "space-between", display: "flex" }}
//           >
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={() => applyCoupon()}
//               disabled={address == 0 ? true : false}
//             >
//               Apply Coupon
//             </Button>
//             <Paytm
//               P_from={"ilp"}
//               totalAmount={totalPrice - discount} //{calculateDiscountedPrice(totalPrice, discount)}
//               address_id={address_id}
//               level_code={level_code}
//             />
//           </Typography>
//         </Container>
//       </footer> */}
//       <Grid container xs={12}>
//         <Grid item xs={12}  sm={6} className={classes.footer}>
//           <Grid item xs={12}>
//             <TextField
//               className={classes.input}
//               variant="outlined"
//               size="small"
//               placeholder="Enter coupon code"
//               onChange={(e) => setCoupon(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             {" "}
//             <Typography className={classes.input}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={() => applyCoupon()}
//                 disabled={address == 0 ? true : false}
//               >
//                 Apply Coupon
//               </Button>
//             </Typography>
//           </Grid>
//         </Grid>
//         <Grid item xs={12} sm={6} className={classes.footer1}>
//           <Grid item xs={12}>
//             <Typography className={classes.input} variant="h5" color="">
//               3499.0 INR
//             </Typography>
//           </Grid>
//           <Grid item xs={12}>
//             <Typography className={classes.input}>
//             <RazorpayButton/>
//               {/* <StudentPaytm
//                 P_from={"ilp"}
//                 totalAmount={Math.floor(totalPrice - discount)} //{calculateDiscountedPrice(totalPrice, discount)}
//                 // team_id={team_id}
//         // totalAmount={totalAmount}
//         // handleNext={handleNext}
//         // P_from={P_from}
//         address={address}
//         address_id={address_id}
//         // paytmType={paytmType}
//         level_code={level_code}
//         addressAdded={addressAdded}
//               /> */}
//             </Typography>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// }


import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  TextField,
  Grid,
} from "@material-ui/core";
import BuyNowDetails from "./BuyNowDetails";
import OrderSummary from "../membershipstudentpayment/OrderSummary";
import { Container } from "reactstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RazorpayButton from "../../pages/NewIslp/Registration/razorpay";
import RazorpayButton2 from "../../pages/NewIslp/Registration/razorpay2.js"; // Assuming RazorpayButton2 is correctly imported
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  TOAST_ERROR,
  TOAST_SUCCESS,
  calculateDiscountedPrice,
} from "../../utils";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    backgroundColor: "#fff00",
    minHeight: "65vh",
  },
  accordion: {},
  activeAccordion: {
    backgroundColor: "#c5e1f6",
  },
  numberBox: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "26px",
    height: "26px",
    borderRadius: "50%",
    backgroundColor: "#2196f3",
    marginRight: theme.spacing(1),
  },
  numberText: {
    color: "#fff",
  },
  accordiondetails: {
    display: "block",
  },
  footer: {
    display: "block",
    marginTop: "3vh",
    backgroundColor: theme.palette.grey[200],
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
      marginTop: "1vh",
    },
  },
  footer1: {
    display: "grid",
    justifyContent: "end",
    alignItems: "center",
    marginTop: "3vh",
    backgroundColor: theme.palette.grey[200],
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
      marginTop: "1vh",
    },
  },
  input: {
    padding: 12,
  },
}));

export default function PaymentDetails() {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(1);
  const selector = useSelector((state) => state);
  const locationState = useLocation();
  const [totalPrice, setTotalPrice] = useState();
  const [discount, setDiscount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [address, setAddresses] = useState();
  const [address_id, setAddress_id] = useState();
  const [level_code, setLevel_code] = useState();
  const [addressAdded, setAddressAdded] = useState(false);

  const validCouponCodes = [
    "ILP080L", "ILP081L", "ILP082L", "ILP083L", "ILP084L", "ILP085L",
    "ILP087L", "ILP088L", "ILP089L", "ILP090L", "ILP091L", "ILP092L",
    "ILP093L", "ILP094L", "ILP095L", "ILP096L", "ILP097L", "ILP098L",
    "ILP099L", "ILP0100L", "ILP001L", "ILP002L", "ILP003L", "ILP004L",
    "ILP005L", "ILP006L", "ILP007L", "ILP008L", "ILP009L", "ILP010L"
  ];

  const isValidCoupon = validCouponCodes.includes(coupon);

  useEffect(() => {
    setTotalPrice(
      calculateDiscountedPrice(
        locationState.levelDetails?.level.data.price,
        locationState.levelDetails?.level.data.discount
      )
    );
    setLevel_code(locationState.levelDetails?.level.data.level_code);
  }, []);

  const applyCoupon = () => {
    if (address > 0 && isValidCoupon) {
      TOAST_SUCCESS("Coupon Successfully Applied");
      setDiscount(1000); // Example discount amount
      setIsCouponApplied(true);
    } else {
      TOAST_ERROR(isValidCoupon ? "Please fill Address" : "Invalid Coupon");
      setIsCouponApplied(false);
      setDiscount(0);
    }
  };

  return (
    <Container maxWidth="sm">
      <Accordion
        expanded={currentStep === 1}
        onChange={() => setCurrentStep(currentStep === 1 ? 0 : 1)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={currentStep === 1 ? classes.activeAccordion : ""}
        >
          <Box className={classes.numberBox}>
            <Typography
              variant="subtitle1"
              component="span"
              className={classes.numberText}
            >
              1
            </Typography>
          </Box>
          <Typography variant="subtitle1" component="span">
            DELIVERY ADDRESS
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordiondetails}>
          <Box className={classes.accordion}>
            <BuyNowDetails
              setAddressesLength={setAddresses}
              onAddressSave={() => setAddressAdded(true)}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={currentStep === 2}
        onChange={() => setCurrentStep(currentStep === 2 ? 0 : 2)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={currentStep === 2 ? classes.activeAccordion : ""}
        >
          <Box className={classes.numberBox}>
            <Typography
              variant="subtitle1"
              component="span"
              className={classes.numberText}
            >
              2
            </Typography>
          </Box>
          <Typography variant="subtitle1" component="span">
            ORDER SUMMARY
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.accordion}>
            <OrderSummary
              details={locationState.levelDetails}
              discount={discount}
              couponCode={coupon}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Grid container xs={12}>
        <Grid item xs={12} sm={6} className={classes.footer}>
          <Box display="flex" flexDirection="column" alignItems="left">
            <TextField
              className={classes.input}
              variant="outlined"
              size="small"
              placeholder="Enter coupon code"
              onChange={(e) => setCoupon(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={applyCoupon}
              disabled={!address}
              style={{ marginTop: "8px" }} // Adding some spacing between the text field and button
            >
              Apply Coupon
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.footer1}>
          <Typography className={classes.input} variant="h5" color="">
            {isCouponApplied ? "3149 INR" : "3499 INR"}
          </Typography>
          {isCouponApplied ? (
            <RazorpayButton2 />
          ) : (
            <RazorpayButton />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
