import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  Popover,
  Typography,
  Grid,
  Box,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import { HOST_URL } from "../action/api";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    zIndex: 1,
  },
  root1: {
    width: "100%",
    minWidth: 240,
    minHeight: 260,
    textAlign: "center",
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
    "& .MuiAvatar-root": {
      innerHeight: 90,
      margin: "auto",
    },
    "& .MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  user_details: {
    padding: "20px",
  },
  typography: {
    padding: theme.spacing(2),
  },
  signout: {
    marginTop: "5px",
  },
  link: {
    textDecoration: "none",
    color: "#000000DE",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  avatar: {
    cursor: "pointer",
  },
  popoverContainer: {
    position: "absolute",
  },
}));

function InsetDividers(props) {
  const classes = useStyles();

  const close_parent_popover = () => {
    props.hendleChange();
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <List className={classes.root1}>
      <Grid className={classes.user_details}>
        <Avatar className={classes.large}>
          <img
            src={`${HOST_URL}${props.profileData?.photo}`}
            alt="user"
            width={"80px"}
          />
        </Avatar>
        <Typography>{props.profileData?.name}</Typography>
        <Typography color="textSecondary" variant="body2">
          {props.profileData?.email}
        </Typography>
      </Grid>
      <Divider />
      <Grid>
        <Link className={classes.link} to="/dashboard/profile">
          <Box my={1}>
            <Typography variant="h6" onClick={close_parent_popover}>
              Profile
            </Typography>
          </Box>
        </Link>
        <Divider />
        <Box my={1}>
          <Typography variant="h6">
            <AutoAwesomeIcon sx={{ fontSize: "18px" }} mt={4} /> ILP Points
          </Typography>
        </Box>
      </Grid>
      <Divider />
      <Grid className={classes.signout}>
        <Box my={1}>
          <Button
            className={classes.signout} 
            onClick={logout}
            variant="outlined"
          >
            sign out
          </Button>
        </Box>
      </Grid>
    </List>
  );
}

export default function Popover_for_userprofile(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.root}>
      <Avatar
        className={classes.avatar}
        aria-describedby={id}
        onClick={handleClick}
        src={`${HOST_URL}${props.profileData?.photo}`}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.popoverContainer }}
      >
        <InsetDividers
          hendleChange={handleClose}
          profileData={props?.profileData}
        />
      </Popover>
    </div>
  );
}
