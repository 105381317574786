import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import MuiPhoneNumber from "material-ui-phone-number";
import { Signin } from "../../api";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import ReactGA from "react-ga4";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router";
import ErrorIcon from "@material-ui/icons/Error";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { HOST_URL } from "../../action/api";
import styled, { keyframes } from 'styled-components';  // Add this line



const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(6),
    },

  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "outset",
    borderRadius: "15px",
    boxShadow: theme.shadows[6],
    padding: theme.spacing(5, 0, 3),
    // width: 370,
    minWidth: 360,
  },
  submit: {
    marginRight: 13,
  },
  otpC_Button: {
    justifyContent: "center",
  },
  info: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Poppins",
    color: "dimgray",
    paddingLeft: 25,
    paddingBottom: 40,
    paddingRight: 25,
  },
  paper1: {
    backgroundColor: theme.palette.background.paper,
    border: "outset",
    borderRadius: "10px",
    boxShadow: theme.shadows[6],
    padding: theme.spacing(3, 4, 3),
    width: 370,
  },
  otpTime: {
    marginRight: 13,
  },
  
}));


// Define the shake animation
const shakeAnimation = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-12deg); }
  20% { transform: rotate(12deg); }
  30% { transform: rotate(-12deg); }
  40% { transform: rotate(12deg); }
  50% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

// Styled component for animated button with pause
const AnimatedButton = styled.button`
  animation: ${shakeAnimation} 2.5s infinite ease-in-out;
  background-color: #007bff; /* Assuming primary button color */
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
`;

export default function Login() {
  const [contact, setContact] = useState({ number: "" });
  const [otp, setOtp] = useState({ number: "" });
  const [msg, setMsg] = useState("");
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [mopen, setMopen] = useState(false);
  const [error, setError] = useState(false);
  let [seconds, setSeconds] = useState(120);
  let [stopTime, setStopTime] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState("");

  if (resendOtp === true) {
    setInterval(() => {
      if (seconds > 0) {
        setSeconds((seconds = seconds - 1));
      } else {
      }
    }, 1000);
    setResendOtp(false);
  }

  function Verify(data) {
    return fetch(`${HOST_URL}otp/verify`, {
      method: "POST",
      mode: "cors",
      body: data,
      headers: {
        "content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem("token", data.token);
        localStorage.setItem("id", data.id);
        if (data.success === true) {
          setMopen(false);
          history.push("/dashboard/buynow");
        } else if (data.success === false) {
          setError(true);
        }
      })
      .catch((err) => err);
  }
  function VerifyToken(tokenData) {
    return fetch(`${HOST_URL}token/validate`, {
      method: "POST",
      mode: "cors",
      body: tokenData,
      headers: {
        "content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.sucess === true) {
          setLoading(false);
          setMopen(false);
          history.push("/dashboard/buynow");
        } else if (data.sucess === false) {
          localStorage.clear();
          setLoading(false);
          setOpen(true);
        } else if (data.message === "No such user") {
          localStorage.clear();
          setLoading(false);
          setOpen(true);
        }
      })
      .catch((err) => err);
  }

  const mySubmitHandler1 = (event) => {
    event.preventDefault();
    let data = { contact: contact.number };
    if (
      !(
        data.contact &&
        data.contact?.length == 15 &&
        data.contact.includes("+91")
      )
    ) {
      setMsg("plasse input a valid number");
    } else {
      var startTime = setInterval(() => {
        if (seconds > 0) {
          setSeconds((seconds = seconds - 1));
        }
      }, 1000);
      if (stopTime) {
        clearInterval(startTime);
      }
      localStorage.setItem(
        "contact",
        JSON.stringify(data.contact).replace("+91 ", "").replace("-", "")
      );
      let data1 = JSON.stringify(data).replace("+91 ", "").replace("-", "");
      Signin(data1);
      setOpen(false);
      setMopen(true);
    }
  };

  const mySubmitHandler2 = (event) => {
    event.preventDefault();
    if (!(otp.number && otp.number.length == 6)) {
      setOtpError("please enter a valid otp");
    } else {
      let data = { contact: contact.number, otp: otp.number };
      let phoneNumber = JSON.stringify(data)
        .replace("+91 ", "")
        .replace("-", "");
      Verify(phoneNumber);
    }
  };

  const login = () => {
    ReactGA.event({
      category: "Button",
      action: "click the Button from login",
    });
    if (!token) {
      setOpen(true);
    } else if (token) {
      setLoading(true);
      let data = {
        contact: JSON.parse(
          localStorage.getItem("contact").replace("+91 ", "").replace("-", "")
        ),
        jwt_token: localStorage.getItem("token"),
      };
      let tokenData = JSON.stringify(data);
      VerifyToken(tokenData);
    } else {
    }
  };

  const handleClose = () => {
    setMsg("");
    setMopen(false);
    setOpen(false);
    setContact({ number: null });
    setOtp({ number: "" });
    setStopTime(true);
  };
  const handleCloseOtp = () => {
    setError(false);
  };

  const hendleKeyPress = (e) => {
    if (e.keyCode === 13) {
      let data = e.target.getAttribute("name");
      if (data == "phone") {
        mySubmitHandler1(e);
      } else if (data == "otp") {
        mySubmitHandler2(e);
      }
    }
  };

  const ResendOTP = () => {
    let data = { contact: contact.number };
    let data1 = JSON.stringify(data.contact)
      .replace("+91 ", "")
      .replace("-", "");
    Signin(data1);
    setSeconds(120);
    setResendOtp(true);
  };

  return (
    <>
      <AnimatedButton onClick={login}>
          Buy Now
        </AnimatedButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={loading}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? "800ms" : "0ms",
          }}
          unmountOnExit
        >
          <CircularProgress size={100} color="secondary" />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onKeyDown={(e) => hendleKeyPress(e)}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div name="phone">
            <div className={classes.paper}>
              <Typography className={classes.info}>Register/Login</Typography>
              <DialogContent>
                <MuiPhoneNumber
                  required
                  name="phone"
                  label="Enter your phone number"
                  data-cy="user-phone"
                  defaultCountry={"in"}
                  // variant="outlined"
                  value={contact.number}
                  onChange={(e) => {
                    setMsg("");
                    setContact({ number: e });
                  }}
                  fullWidth
                />
                <Typography color="secondary">{msg}</Typography>
              </DialogContent>
              <DialogActions></DialogActions>

              <DialogActions className={classes.submit}>
                <button className="btn btn-primary" onClick={handleClose}>
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={mySubmitHandler1}>
                  Submit
                </button>
              </DialogActions>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={mopen}
        onKeyDown={(e) => hendleKeyPress(e)}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={mopen}>
          <div name="otp">
            <div className={classes.paper}>
              <Typography className={classes.info}>Register/Login</Typography>
              <DialogContent>
                <MuiPhoneNumber disabled value={contact.number} fullWidth />
              </DialogContent>
              <DialogContent>
                <TextField
                  required
                  inputProps={{ maxLength: 6 }}
                  name="otp"
                  label="Enter OTP"
                  value={otp.number}
                  onChange={(e) => {
                    setOtpError("");
                    e.target.value = e.target.value
                      .replace(/[^0-9 ]/g, "")
                      .replace(" ", "");
                    setOtp({ number: e.target.value });
                  }}
                  fullWidth
                />
                <Typography color="secondary">{otpError}</Typography>
              </DialogContent>

              <DialogActions>
                {seconds > 0 ? (
                  <Link className={classes.otpTime} to={""}>
                    Resend OTP will be enabled in {seconds} second
                  </Link>
                ) : (
                  <Link
                    onClick={() => ResendOTP()}
                    className={classes.otpTime}
                    to={""}
                  >
                    Resend Otp
                  </Link>
                )}
              </DialogActions>

              <DialogActions className={classes.submit}>
                <button className="btn btn-primary" onClick={handleClose}>
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={mySubmitHandler2}>
                  Verify OTP
                </button>
              </DialogActions>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={error}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={error}>
          <div className={classes.paper}>
            <ErrorIcon />
            <Typography className={classes.info}>Wrong Otp</Typography>
            <DialogActions className={classes.otpC_Button}>
              <button className="btn btn-primary" onClick={handleCloseOtp}>
                Close
              </button>
            </DialogActions>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
