// import React, { Component } from 'react';

// //Importing Modal
// import ModalVideo from 'react-modal-video';
// import '../../../node_modules/react-modal-video/scss/modal-video.scss';

// class ModalSection extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isOpen: false
//         };
//         this.openModal.bind(this)
//     }

//     openModal() {
//         this.setState({ isOpen: true })
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <ModalVideo
//                     channel={this.props.channel}
//                     isOpen={this.state.isOpen}
//                     videoId={this.props.videoId}
//                     onClose={() => this.setState({ isOpen: false })}
//                 />
//             </React.Fragment>
//         );
//     }
// }

// export default ModalSection;

// import React, { Component } from "react";

// //Importing Modal
// import ModalVideo from "react-modal-video";
// import "../../../node_modules/react-modal-video/scss/modal-video.scss";

// class ModalSection extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isOpen: true,
//     };
//     this.openModal.bind(this);
//   }

//   openModal() {
//     this.setState({ isOpen: true });
//   }

//   render() {
//     return (
//       <React.Fragment>
//         <ModalVideo
//           channel={this.props.channel}
//           isOpen={this.state.isOpen}
//           videoId={this.props.videoId}
//           onClose={() => this.setState({ isOpen: false })}
//           autoplay={this.props.autoplay}
//           mute={this.props.mute}
//         />
//       </React.Fragment>
//     );
//   }
// }

// export default ModalSection;


// import React, { Component } from "react";
// import ModalVideo from "react-modal-video";
// import "../../../node_modules/react-modal-video/scss/modal-video.scss";
// import Cookies from "js-cookie";

// class ModalSection extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isOpen: false,
//     };
//     this.openModal.bind(this);
//   }

//   componentDidMount() {
//     // Check if the modal has been shown before
//     const modalShown = Cookies.get("modalShown");
//     if (!modalShown) {
//       // If the modal has not been shown, open it and set the cookie
//       this.setState({ isOpen: true });
//       // Cookies.set("modalShown", true, { expires: 365 }); // Cookie expires after 365 days
//       Cookies.set("modalShown", true);
//     }
//   }

//   openModal() {
//     this.setState({ isOpen: true });
//   }


//   render() {
//     return (
//       <React.Fragment>
//         <ModalVideo
//           channel={this.props.channel}
//           isOpen={this.state.isOpen}
//           videoId={this.props.videoId}
//           onClose={() => this.setState({ isOpen: false })}
//           autoplay={this.props.autoplay}
//           mute={this.props.mute}
//         />
//       </React.Fragment>
//     );
//   }
// }

// export default ModalSection;
import React, { Component } from "react";
import Cookies from "js-cookie";
import "./ModalCard.css"; // Custom CSS file for card styling
import im1 from "../Product/img/top (1).png"

class ModalCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    // Check if the modal has been shown before
    const modalShown = Cookies.get("modalShown");
    if (!modalShown) {
      // If the modal has not been shown, open it and set the cookie
      this.setState({ isOpen: true });
      Cookies.set("modalShown", true, { expires: 0 }); 
    }
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isOpen && (
          <div className="modal-overlay">
            <button className="close-button" onClick={this.closeModal}>
              &times;
            </button>
            <div className="modal-card">
              <img src={im1} alt="Sample" />
              {/* <h2>Welcome to Our Website!</h2>
              <p>This is a special message for our visitors. Enjoy browsing!</p> */}
              <button className="product-button" onClick={() => window.location.href = "/militaryjeep"}>
                Shop Now
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ModalCard;

