import React, { useState } from 'react';
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    Button,
    makeStyles,
} from '@material-ui/core';
import CircleIcon from '@mui/icons-material/Circle';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        backgroundColor: '#f5f5f5',
        borderRadius: 8,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
    title: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    listItem: {
        marginBottom: theme.spacing(0.01),
        listStyleType: 'circle', // add this line to change bullet points to circles
    },
    readMoreButton: {
        marginTop: theme.spacing(2),
    },
}));

function GameDescription({ gameDescription }) {
    const classes = useStyles();
    const [showFullContent, setShowFullContent] = useState(false);

    const handleReadMoreClick = () => {
        setShowFullContent(true);
    };

    const handleShowLessClick = () => {
        setShowFullContent(false);
    };

    const formatTextWithNewlines = (text) => {
        return text
            ? text.split('\n').map((line, index) => (
                <ListItem key={index} className={classes.listItem}>
                    <ListItemText primary={line} />
                </ListItem>
            ))
            : null;
    };

    const formattedLearningProjects = formatTextWithNewlines(gameDescription.learning_projects)
    const formattedComponents = formatTextWithNewlines(gameDescription.components);
    const formattedKeySkills = formatTextWithNewlines(gameDescription.key_skills);
    const formattedKeyConcepts = formatTextWithNewlines(gameDescription.key_concept);

    return (
        <div className={classes.container}>
            <Typography variant="h4" className={classes.title} style={{ color: '#4a94d0' }}>
                {/* Takshak Battle Field */}
                {gameDescription.game_name} {gameDescription.game_type}
            </Typography>
            <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
                Introducing "{gameDescription.game_name} {gameDescription.game_type}" – A Revolutionary STEM Gaming Experience
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
                {gameDescription.description}
            </Typography>

            {showFullContent ? (
                <>
                    <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
                        Why STEM Education
                    </Typography>
                    <Typography variant="body1" className={classes.subtitle}>
                        Takshak Battle Field is not just a game—it's a transformative educational adventure that fuels curiosity, sharpens problem-solving skills, and ignites a lifelong passion for STEM. Join a vibrant community of like-minded players, collaborate with others, and engage in friendly competitions to test your skills. Get ready to redefine learning, one level at a time, and embrace a future filled with limitless possibilities. The battlefield awaits your arrival!
                    </Typography>
                    <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
                        Personalized Online Learning
                    </Typography>
                    <Typography variant="body1" className={classes.subtitle}>
                        <ul>
                            <li><b>Mode</b>: {gameDescription.mode}</li>
                            <li><b>Duration</b>: {gameDescription.duration}</li>
                            <li><b>Membership</b>: {gameDescription.membership}</li>
                            <li>Shipping: {gameDescription.shipping}</li>
                            <li><b>Program</b>: {gameDescription.program}</li>
                            <li><b>Age</b>: {gameDescription.age_group}</li>
                        </ul>
                    </Typography>
                    {/* <Typography variant="h5" className={classes.subtitle}>
                        Learning Projects in Levels
                    </Typography>
                    <List>
                        <ListItem className={classes.listItem}>
                            <ListItemText primary={gameDescription.learning_projects} />
                        </ListItem>
                    </List> */}
                    <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
                        Learning Projects in Levels
                    </Typography>
                    <List>{formattedLearningProjects}</List>
                    <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
                        Game Components
                    </Typography>
                    <List>{formattedComponents}</List>
                    <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
                        Key Skills
                    </Typography>
                    <List>{formattedKeySkills}</List>
                    <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
                        Key Concepts
                    </Typography>
                    <List>{formattedKeyConcepts}</List>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.readMoreButton}
                        onClick={handleShowLessClick}
                    >
                        Show Less
                    </Button>
                </>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.readMoreButton}
                    onClick={handleReadMoreClick}
                >
                    Read More
                </Button>
            )}
        </div>
    );
}

export default GameDescription;
