import axios from "axios";
import {
  AddressApi,
  AddStudentApi,
  ClubMember,
  PaymentApi,
  postClubDetails,
  postClubExcutive,
  postClubMember,
  RegistrationApi,
  StudentPaymentApi,
} from "./api";

// export const onRegistrationData = (data) => {
//   return axios
//     .post(RegistrationApi, { ...data })
// };
export const onRegistrationData = (data) => {
  const token = localStorage.getItem("token"); // Retrieve token from localStorage

  return axios.post(
    RegistrationApi,
    { ...data },
    {
      headers: {
        Authorization: token, // Include token in the Authorization header
      },
    }
  );
};

// export const onTeamData = (data) => {
//   axios
//     .post(AddStudentApi, { ...data }, { headers: { team_id: data.theam_id } })
//     .then((response) => console.log(response))
//     .catch(function (error) { console.log(error) });
// };

export const onTeamData = (data) => {
  const token = localStorage.getItem("token"); // Retrieve token from localStorage

  axios
    .post(
      AddStudentApi,
      { ...data },
      {
        headers: {
          Authorization: token, // Include token in the Authorization header
          team_id: data.theam_id, // Include team_id in the headers
        },
      }
    )
    .then((response) => console.log(response))
    .catch(function (error) {
      console.log(error);
    });
};

// export const onAddressData = (data) => {
//   return axios
//     .post(AddressApi, { ...data }, { headers: { team_id: data.theam_id } })
// };

export const onAddressData = (data) => {
  const token = localStorage.getItem("token"); // Retrieve token from localStorage

  return axios.post(
    AddressApi,
    { ...data },
    {
      headers: {
        Authorization: token, // Include token in the Authorization header
        team_id: data.theam_id, // Include team_id in the headers
      },
    }
  );
};

export const onPaymentData = () => {
  // const paymentData = {
  //   orderId: 'ORDER12345', // Replace with your own order ID
  //   txnAmount: {
  //     value: '100.00', // Replace with your own order amount
  //     currency: 'INR',
  //   },
  //   userInfo: {
  //     custId: 'CUSTOMER123', // Replace with your own customer ID
  //   },
  // };
  // Initiate payment process
  // checkoutJs.initiateTransaction(paymentData, responseHandler);
};

export const getPaymentToken = (data) => {
  return axios.post(
    PaymentApi,
    { ...data },
    { headers: { team_id: data.theam_id } }
  );
};

export const onClubData = (data) => {
  return fetch(ClubMember, { ...data });
};

export const onPostClubDetails = (data) => {
  return fetch(postClubDetails, { ...data });
};

export const onPostClubExcutive = (data) => {
  return fetch(postClubExcutive, { ...data });
};

// export const onPostClubMember = (data) => {
//   return fetch(postClubMember, {...data} )
// }

export const getPaymentTokenStudent = (data) => {
  const token = localStorage.getItem("token");
  return axios.post(
    StudentPaymentApi,
    { ...data },
    { headers: { Authorization: token } }
  );
};
// export const getPaymentTokenStudent = (data) => {
//   const token = localStorage.getItem("token");
//   const headers = { headers: { token: token } };

//   return axios.post(StudentPaymentApi, { ...data }, headers);
// }
