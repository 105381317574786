
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    Button,
    makeStyles,
} from '@material-ui/core';
import CircleIcon from '@mui/icons-material/Circle';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        backgroundColor: '#f5f5f5',
        borderRadius: 8,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
    title: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    listItem: {
        marginBottom: theme.spacing(0.01),
        listStyleType: 'circle', // add this line to change bullet points to circles
    },
    readMoreButton: {
        marginTop: theme.spacing(2),
    },
}));

function LevelDescription({levelDec}) {
    // console.log("levelDesdkkdn----------", props)
    // const { levels } = props.location.state;
    // const location = useLocation();
    // const { levels } = location.state;
    const classes = useStyles();
    const [showFullContent, setShowFullContent] = useState(false);
  
    const handleReadMoreClick = () => {
      setShowFullContent(true);
    };
  
    const handleShowLessClick = () => {
      setShowFullContent(false);
    };
  
    return (
      <div className={classes.container}>
        <Typography variant="h4" className={classes.title} style={{ color: '#4a94d0' }}>
          {/* Level-{levelDec.level_number}, <br /> 
          {levelDec.level.data.level_code}, <br /> */}
          Level Name : {levelDec.level.data.level_name}
        </Typography>
        <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
          Introducing "{levelDec.level.data.level_name}" – A Revolutionary STEM Gaming Experience
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
        {levelDec.level.data.description}
          {/* Embark on an extraordinary journey with "Robo Car", the groundbreaking online multiplayer STEM game developed and published by iLearnPlace.com, a subsidiary of Almondai Technologies. Inspired by the practical learning approach of "Learn by doing," this immersive gaming experience combines the realms of STEM education and robotics, redefining interactive learning. */}
        </Typography>
  
        {showFullContent ? (
          <>
  
            <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
              Why STEM Education
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Takshak Battle Field is not just a game—it's a transformative educational adventure that fuels curiosity, sharpens problem-solving skills, and ignites a lifelong passion for STEM. Join a vibrant community of like-minded players, collaborate with others, and engage in friendly competitions to test your skills. Get ready to redefine learning, one level at a time, and embrace a future filled with limitless possibilities. The battlefield awaits your arrival!
            </Typography>
            {/* <Typography variant="h5" className={classes.subtitle}>
              Personalized Online Learning
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              <ul>
                <li><b>Mode</b>: Online</li>
                <li><b>Duration</b>: 3 Years</li>
                <li><b>Membership</b>: 1 Year **Membership will be renewed on every game level entry, but if no purchases are made within one year, it will expire.</li>
                <li><b>Shipping</b>: Shipping STEM Kit every month</li>
                <li><b>Program</b>: At iLearnPlace (ILP), we are dedicated to providing personalized learning experiences. Each learner is assigned an ILP expert, and learning sessions are scheduled based on student requests. Our comprehensive learning package includes pre-recorded videos and exclusive one-on-one live interactive sessions with our experts. With a carefully curated kit, comprising all the necessary activity materials, tools, and step-by-step instructions, we ensure that every activity comes to life, fostering hands-on engagement and practical learning.</li>
                <li><b>Age</b>: 10+ years</li>
              </ul>
            </Typography> */}
            <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
              Learning Projects in these tasks
            </Typography>
            <List>
              <ListItem className={classes.listItem}>
                <ListItemText primary={levelDec.level.data.learning_concepts} />
              </ListItem>
            </List>
            <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
              Game Components
            </Typography>
            <List>
              <ListItemText primary={levelDec.level.data.hardware_components} />
            </List>
            <Typography variant="h5" className={classes.subtitle} style={{ color: '#4a94d0' }}>
              Key Skills
            </Typography>
            <List>
              <ListItem className={classes.listItem}>
                <ListItemText primary={levelDec.level.data.key_skills} />
              </ListItem>
            </List>
            {/* <Typography variant="h5" className={classes.subtitle}>
              Key Concepts
            </Typography>
            <List>
              <ListItem className={classes.listItem}>
                <ListItemText primary="1. Robotics" />
              </ListItem>
            </List> */}
            <Button
              variant="contained"
              color="primary"
              className={classes.readMoreButton}
              onClick={handleShowLessClick}
            >
              Show Less
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.readMoreButton}
            onClick={handleReadMoreClick}
          >
            Read More
          </Button>
        )}
      </div>
    );
  }

export default LevelDescription;
