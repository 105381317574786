import React, { useState } from 'react';
import { Box, Button, useMediaQuery } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import Login from './plogin'; // Adjust the import path if necessary

// Define the shake animation
const shakeAnimation = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-12deg); }
  20% { transform: rotate(12deg); }
  30% { transform: rotate(-12deg); }
  40% { transform: rotate(12deg); }
  50% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

const AnimatedButton = styled(Button)`
  animation: ${shakeAnimation} 2.5s infinite ease-in-out;
`;

const BuyNowButton = () => {
  const [openLogin, setOpenLogin] = useState(false); // State to manage the login modal
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleLoginClick = () => {
    setOpenLogin(true); // Open the login modal
  };

  return (
    <Box display="flex" justifyContent="center" width={isMobile ? '100%' : 'auto'}>
      {/* <AnimatedButton
        variant="contained"
        color="primary"
        size="large"
        fullWidth={isMobile}
        onClick={handleLoginClick} // Show login modal on click
      >
        Buy Now
      </AnimatedButton> */}
      <Login open={openLogin} onClose={() => setOpenLogin(false)} /> {/* Render Login modal */}
    </Box>
  );
};

export default BuyNowButton;
