import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { HOST_URL } from "../../action/api";
import axios from "axios";
import AddNewAddress from "../../dashboard/membershipstudentpayment/AddNewAddress";
import { Edit } from "@mui/icons-material";

export default function BuyNowDetails({setAddressesLength, onAddressSave}) {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [addresses, setAddresses] = useState([]);

  const fetchAddresses = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${HOST_URL}student/address`, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      const result = response.data;
      const adc = result.address;

      if (Array.isArray(adc)) {
        setAddresses(adc);
        setSelectedAddress(adc.length > 0 ? adc[0].address_id : "");
        setAddressesLength(adc.length)
      } else {
        setAddresses([]);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // setLoading(false);
    }
  }, []);

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  const handleEditAddress = (addressId) => {
    setSelectedAddress(addressId);
    setIsFormOpen(true);
  };

  const handleAddNewAddress = () => {
    setSelectedAddress("");
    setIsFormOpen(true);
  };

  const handleAddressSave = () => {
    fetchAddresses();
    setIsFormOpen(false);
    // Call the callback function passed via props
    onAddressSave();
  };

  const handleCancel = () => {
    setIsFormOpen(false);
  };

  useEffect(() => {
    fetchAddresses();
  }, [fetchAddresses]);

  return (
    <>
      <Grid p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {addresses.length > 0 ? (
              <>
                <FormControl
                  component="fieldset"
                  style={{
                    display: "flex",
                  }}
                >
                  <RadioGroup
                    value={selectedAddress}
                    onChange={handleAddressChange}
                  >
                    {addresses.map((address, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControlLabel
                          value={address.address_id}
                          control={<Radio />}
                          label={
                            <div>
                              <Typography variant="body1">
                                {address.address}, {address.district},{" "}
                                {address.state}, {address.pincode}
                              </Typography>
                            </div>
                          }
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleEditAddress(address.address_id)}
                        >
                          <Edit /> Edit
                        </Button>
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
              </>
            ) : (
              <Typography variant="body1">No addresses found.</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddNewAddress}
            >
              <AddIcon />
              Add New Address
            </Button>
          </Grid>
          <Grid item xs={12}>
            {isFormOpen && (
              <AddNewAddress
                initialAddress={
                  selectedAddress
                    ? addresses.find(
                        (address) => address.address_id === selectedAddress
                      )
                    : {}
                }
                onAddressSave={handleAddressSave} // Update prop name to onAddressSave
                onCancel={handleCancel}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
