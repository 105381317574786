import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Link as RouterLink,
} from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { MainListItems } from "./listItems";
import Games from "./games";
import Levels from "./levels";
import { Icon, useMediaQuery } from "@material-ui/core";
import PopoverForUserprofile from "./popover_for_profile";
import logo from "./image/ilp.svg";
import IsplDashboard from "./ISPLDashboard/IsplDashboard";
import Button from "@mui/material/Button";
import HelpISPL from "./ISPLDashboard/HelpISPL";
import ClubDashboard from "./ClubDashboard/ClubDashboard";
import PaymentDetails from "./Payment Page/PaymentDetails";
import { useDispatch, useSelector } from "react-redux";
import { allInfo, profileData } from "../redux/action/action";
import UserProfile from "./userprofile";
import CryptoJS from 'crypto-js';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{color:"black"}}>
      {"Copyright © "}
      <Link color="inherit" href="https://ilearnplace.com/">
        ilearnplace.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    // marginLeft:"14%",
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  navContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2), // Add some margin for spacing
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0, // Remove margin on small screens
    },
  },
  title_logo:{
    marginRight: theme.spacing(2), 
  },
  navItem: {
    marginRight: theme.spacing(2), // Add some margin for spacing
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide on small screens
    },
  },
  hideOnSmallScreen: {
    [theme.breakpoints.down("md")]: {
      display: "none", // Hide on small screens using this class
    },
  },
  title_link:{
    [theme.breakpoints.down("12")]:{
      display:"none"
    },
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: "#f5f6fa",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  copyright: {},
  hideOnSmallScreen: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export default function Dashboard() {
  const match = useRouteMatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [db_data, setDb_data] = useState({ game: [], activegame: [] });
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const isScreenAbove1200 = useMediaQuery('(min-width:1130px)');

  useEffect(() => {
    dispatch(allInfo());
    dispatch(profileData());
  }, []);
  useEffect(() => {
    setDb_data({
      game: selector.allInfo.allInfo?.game,
      activegame: selector.allInfo.allInfo?.activegame,
    });
  }, [selector]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ background: "#272a33" }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>

          {/* Wrap the logo and navigation items in a container */}
          <Box className={classes.navContainer}>
            <RouterLink style={{ textDecoration: "none" }} to="/">
              <Icon className={classes.logo}>
                <img src={logo} height={40} width={100} alt="logo" style={{marginLeft:"-20%"}}/>
              </Icon>
            </RouterLink>

            {/* Navigation items with responsive styling */}
            <div className={classes.hideOnSmallScreen} style={{marginRight:"-10%",marginLeft:"10%"}}>
            <RouterLink
              style={{ textDecoration: "none" }}
              to="/dashboard"
              className={clsx(classes.navItem, {
                [classes.navItem]: isScreenAbove1200,
                [classes.hideOnSmallScreen]: true,
              })}
            >
              <Button
                sx={{
                  color: "#e9ebea",
                  border: 0,
                  fontSize: "18px",
                  textTransform: "capitalize",
                  ":hover": {
                    color: "#fff",
                    outline:"none"
                  },
                }}
              >
                iLEARNPLACE
              </Button>
            </RouterLink>

            <RouterLink
              style={{ textDecoration: "none" }}
              to="/dashboard/ispl"
              className={clsx(classes.navItem, {
                [classes.navItem]: isScreenAbove1200,
                [classes.hideOnSmallScreen]: true,
              })}
            >
              <Button
                sx={{
                  color: "#e9ebea",
                  border: 0,
                  fontSize: "18px",
                  textTransform: "capitalize",
                  ":hover": {
                    color: "#fff",
                    outline: "none",
                  },
                }}
              >
                ISPL DASHBOARD
              </Button>
            </RouterLink>

            <RouterLink
              style={{ textDecoration: "none" }}
              to="/dashboard/club"
              className={clsx(classes.navItem, {
                [classes.navItem]: isScreenAbove1200,
                [classes.hideOnSmallScreen]: true,
              })}
            >
              <Button
                sx={{
                  color: "#e9ebea",
                  border: 0,
                  fontSize: "18px",
                  textTransform: "capitalize",
                  ":hover": {
                    color: "#fff",
                    outline: "none",
                  },
                }}
              >
                CLUB DASHBOARD
              </Button>
            </RouterLink>
            </div>
          </Box>
          {/* <Toolbar className={classes.toolbar}> */}
          <div className={classes.title} style={{marginLeft:"-12%"}}/>
          {/* <div className={clsx(classes.navContainer)}> */}
          <HelpISPL />
          <IconButton color="inherit">
            <Badge badgeContent={0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          {/* popover for user profile */}
          <PopoverForUserprofile
            profileData={selector.profileData?.profileData}
          />
          {/* </div> */}
        </Toolbar>
        {/* </Toolbar> */}
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems />
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route path={`${match.path}/profile`} component={()=><UserProfile profileData={selector.profileData?.profileData} />} />
            <Route
              path={`${match.path}/levels`}
              render={(props) => (
                <Levels levels={props.location.state.levels} />
              )}
            />
            <Route
              path={`/dashboard/buynow`}
              render={() => <PaymentDetails />}
            />
            <Route
              path="/dashboard/ispl"
              render={(props) => <IsplDashboard />}
            />
            <Route path="/dashboard/club" render={() => <ClubDashboard />} />
            <Route>
              <Games state={db_data} />
            </Route>
          </Switch>
          <Box className={classes.copyright} pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
