import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
// import Button from "@mui/material/Button";
// import { HOST_URL } from "../../../action/api";
export default function PaymentForm({ paymentData }) {
  const { teamSize } = paymentData;
  const teameMambers = teamSize;
  const deliveryCharge = 0;
  const registrationFee = 1;
  const TotalRegitrationFee = registrationFee * teameMambers;
  const totalAmount =
    teameMambers * deliveryCharge + registrationFee * teameMambers;

    // const handlePayment = async () => {
    //   try {
    //     const response = await fetch(`${HOST_URL}ispl/team/payment`, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         teamSize: teamSize,
    //         totalAmount: totalAmount,
    //         // Add other relevant payment information
    //       }),
    //     });
  
    //     if (response.ok) {
    //       console.log("Payment successful");
    //       // Handle successful payment (update state, navigate, etc.)
    //     } else {
    //       console.error("Payment failed");
    //       // Handle failed payment (update state, show error message, etc.)
    //     }
    //   } catch (error) {
    //     console.error("Error making payment:", error);
    //     // Handle other errors during the payment process
    //   }
    // };
  


  useEffect(() => {
    paymentData.setTotleAmount(totalAmount);
  }, [totalAmount, paymentData]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment Details
      </Typography>
      <p style={{ fontSize: 15, lineHeight: 1.3, marginBottom: 25 }}>
        Every registered student will receive ILP membership and a selected ILP
        STEM game’s(Takshak battle field or Totoka smart city) level-1 Kit at
        your home, which is worth of 3500 INR.
      </p>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography variant="body">
            Total Team Member : <b>{teameMambers}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="body">
            Delivery Charges :{" "}
            <b>
              {teameMambers}*{deliveryCharge}
            </b>{" "}
            = <b>{teameMambers * deliveryCharge}</b> INR
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          {" "}
          <Typography variant="body">
            Total Registration Fee :{" "}
            <b>
              {teameMambers}*{registrationFee}
            </b>{" "}
            = <b>{TotalRegitrationFee}</b> INR{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          {" "}
          <Typography variant="body">
    
            Total Amount : <b>{totalAmount}</b>
            
          </Typography>
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
}
