import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        maxWidth: '100%',
        marginTop: '20px'
    },
    AppBar: {
        justifyContent: 'center',
        backgroundColor: '#272a33'
    }
});

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function PrivacyPolicy(props) {
    const classes = useStyles();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <React.Fragment>
            <div className={classes.root}>
                {/* <CssBaseline /> */}
                <HideOnScroll {...props}>
                    <AppBar >
                        <Toolbar className={classes.AppBar}>
                            <Typography variant="h6">PRIVACY POLICY</Typography>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <Toolbar />
                <Container maxWidth="md">

                    <Typography variant="body2" gutterBottom>This privacy policy sets out how Almondai Group - Ilearnplace (“ILP - Group”)
                        uses and protects any information that you give us when you use this networking platform (“ILP - Group Community”) on
                        the website www.ilearnplace.com.</Typography>

                    <Typography variant="body2" gutterBottom>This Privacy Policy is intended to explain what data we collect from Users of the website. A “User” means either a member of the ILP - Group Community” or a non-Member who is simply visiting the Website. Our Service is intended for Users over seven (7) years of age and we will not intentionally collect or maintain information about anyone under seven (7) years of age.</Typography>

                    <Typography variant="body2" gutterBottom>ILP - Group Community provides a social networking platform where learners and other user of the website of Almondai Group - Ilearnplace can interact and follow each other on the platform and share ideas and concepts and participate in social interactions with each other (“Services”). Almondai Group - Ilearnplace is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</Typography>

                    <Typography variant="body2" gutterBottom>ILP - Group may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</Typography>

                    <Typography variant="body2" gutterBottom>This privacy policy document outlines the types of personal information received and collected by www.ilearnplace.com (“Website”) and how it is used..</Typography>

                    <Typography variant="body2" gutterBottom>LOG FILES</Typography>
                    <Typography variant="body2" gutterBottom>We will be collecting non personally identifiable information like many other Web sites, ILP - Group makes use of log files. The information inside the log files includes internet protocol ( IP ) addresses, type of browser, Internet Service Provider ( ISP ), date/time stamp, referring/exit pages, and number of clicks to analyze trends, administer the site, track users movement around the site, and gather demographic information. IP addresses, and other such information are not linked to any information that is personally identifiable.</Typography>

                    <Typography variant="body2" gutterBottom>COLLECTION OF YOUR INFORMATION</Typography>
                    <Typography variant="body2" gutterBottom>We may collect the following information:</Typography>

                    <Typography variant="body2" gutterBottom>Name</Typography>
                    <Typography variant="body2" gutterBottom>Contact information including email address</Typography>
                    <Typography variant="body2" gutterBottom>Demographic information such as gender, postcode, preferences and interests</Typography>
                    <Typography variant="body2" gutterBottom>Profile Photo</Typography>
                    <Typography variant="body2" gutterBottom>Username or Login Ids on other platforms such as Facebook and Google</Typography>
                    <Typography variant="body2" gutterBottom>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</Typography>

                    <Typography variant="body2" gutterBottom>Internal record keeping.</Typography>
                    <Typography variant="body2" gutterBottom>We may use the information to improve our products and services.</Typography>
                    <Typography variant="body2" gutterBottom>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</Typography>
                    <Typography variant="body2" gutterBottom>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customize the website according to your interests.</Typography>
                    <Typography variant="body2" gutterBottom>We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</Typography>

                    <Typography variant="body2" gutterBottom>USE OF THE INFORMATION</Typography>
                    <Typography variant="body2" gutterBottom>ILP - Group may share your information with its business partners, third party customers, suppliers or service providers. These business partners, customers, suppliers and service providers shall be contractually obligated to keep your information confidential and secure and they shall be required to use your information only for the needs of the services that are promised or entrusted to them.</Typography>

                    <Typography variant="body2" gutterBottom>We shall store and use this information to communicate with you, resolve your concerns, help promote safe use of the Website, collect fees or any other money owed by you, measure consumer interest in the Services provided by us, inform you about online and offline offers, services, and updates, customize your experience, detect and protect us against error, fraud and other criminal activity and/or set in motion the delivery of the products which are ordered by you. We may also use the said information to update our records, maintain your accounts with us, display content such as wish lists and customer reviews and recommend merchandise, updates and services that might be of interest to you. You must note that you are under no obligation to provide such information in the event you do not want to register with us on our Website and avail our offers, recommendations, updates and the like.</Typography>

                    <Typography variant="body2" gutterBottom>Subject to the above paragraphs, ILP - Group shall not sell, rent or provide your information to third parties without your consent, except pursuant to a court order or to comply with applicable law or to protect the rights or interests of ILP - Group. Further, in order to prevent unauthorized access to or illegal interception of your information by third parties, maintain data accuracy and ensure correct use of information, ILP - Group shall employ reasonable security practices and procedures and current internet security methods and technologies in compliance with Information Technology Act, 2000 and subordinate legislations framed thereunder.</Typography>

                    <Typography variant="body2" gutterBottom>DISCLOSURE OF INFORMATION</Typography>
                    <Typography variant="body2" gutterBottom>We may, as directed or approved by you, disclose the information that we collect in the following circumstances:</Typography>

                    <Typography variant="body2" gutterBottom>To network administrators, and other users authorized by you to access the requested information;</Typography>
                    <Typography variant="body2" gutterBottom>To third-party service providers;</Typography>
                    <Typography variant="body2" gutterBottom>To third-party apps, websites, or other services that you can connect to through the Services;</Typography>
                    <Typography variant="body2" gutterBottom>In connection with a substantial corporate transaction, such as the sale of our Services, a merger, consolidation, asset sale, or in the unlikely event of bankruptcy or insolvency;</Typography>
                    <Typography variant="body2" gutterBottom>To protect the safety of any person; to address fraud, security or technical issues; or to protect Workplace’s rights or property; and</Typography>
                    <Typography variant="body2" gutterBottom>As otherwise directed or authorized by you.</Typography>
                    <Typography variant="body2" gutterBottom>Legal Requests: If we receive a subpoena, warrant, discovery order or other request or order from a law enforcement agency, court, other governmental entity, or litigant that seeks data relating to the Services (collectively a “Legal Request”), we will make reasonable attempts to direct the requesting party to seek the data directly from you. If we ask the requesting party to direct the request to you, we will provide your contact information to the requesting party. If legally compelled to produce information and unless legally prohibited, we will use reasonable efforts to notify you. We will direct any requests for information under data protection laws to you unless prohibited by law.</Typography>

                    <Typography variant="body2" gutterBottom>Aggregate or de-identified data: We may also disclose information that has been aggregated or that otherwise does not personally identify you to third parties and affiliates who may use it for analytics, trends and analysis to improve and provide our products and services.</Typography>

                    <Typography variant="body2" gutterBottom>COOKIES AND WEB BEACONS</Typography>
                    <Typography variant="body2" gutterBottom>ILP - Group does use cookies to store information about visitors preferences, record user-specific information on which pages the user access or visit, customize Website page content based on visitors browser type or other information that the visitor sends via their browser.</Typography>

                    <Typography variant="body2" gutterBottom>ONLINE ADVERTISING WE USE</Typography>
                    <Typography variant="body2" gutterBottom>In addition to using cookies and related technologies as described above, we use third party advertising services, including Google, to help us tailor advertising that we think may be of interest to users and to collect and use other data about user activities on our Website and/or Services (e.g., to allow them to tailor ads on third party services). Third-party vendors, including Google, may deliver ads that might also place cookies and otherwise track user behavior. These companies may use information about user behavior in order to provide customized advertisements across various services and products. In the course of providing these services, products or placing advertisements, these third party companies may place or recognize a unique cookie on your computer, and may record information to these cookies based upon your activities on any of our Sites and/or Services and on third party websites. Each of these companies uses this cookie information according to their own privacy and security policies. If you wish to not have this information used for the purpose of serving you targeted ads, you may opt-out as indicated in this Policy. Please note this does not opt you out of being delivered advertising. You will continue to receive generic ads.</Typography>

                    <Typography variant="body2" gutterBottom>You may opt out of Google's use of cookies by visiting Google's Ads Settings or you may opt out of Google Analytics by visiting the Google Analytics opt-out page.</Typography>

                    <Typography variant="body2" gutterBottom>Google has additional information available about their Remarketing Privacy Guidelines, and Restrictions.</Typography>

                    <Typography variant="body2" gutterBottom>You may view a list of other third party service providers who collect information, and/or opt-out of such collection of information about you, by visiting http://www.networkadvertising.org/choices/ or http://www.aboutads.info/choices/.</Typography>

                    <Typography variant="body2" gutterBottom>REDIRECTING TO OTHER WEBSITES</Typography>
                    <Typography variant="body2" gutterBottom>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</Typography>

                    <Typography variant="body2" gutterBottom>SAFETY AND SECURITY</Typography>
                    <Typography variant="body2" gutterBottom>We use the information that we have to help verify accounts and activity and to promote safety and security on and off our Services on your behalf, such as by investigating suspicious activity or violations of our terms or policies. We work hard to protect Your Account using teams of engineers, automated systems and advanced technology such as encryption and machine learning. For example, we may deploy automated technologies to detect abusive behaviour and content, such as child pornography, that may harm our Services, you, other users, or others.</Typography>

                    <Typography variant="body2" gutterBottom>HOW SECURE IS MY PERSONAL INFORMATION</Typography>
                    <Typography variant="body2" gutterBottom>The information including the information and pictures which you chose to post on ILP - Group Community can also be viewed and accessed by other members of the ILP - Group Community who can view the profiles of the members of the ILP - Group Community. We use commercially reasonable administrative, technical, personnel and physical measures to safeguard your personal information gathered against loss, theft and unauthorized use, disclosure or modification. Moroever, ILP - Group Community also carries features which enable a user to block, unfollow or unfriend another member on the ILP - Group Community which enables the user to restrict the users who can access his/her profile. However, we cannot completely guarantee the security of any information on the profile. Although we make commercially reasonable efforts to protect it from loss, misuse, or alteration by third parties, you should be aware that there is always some risk involved in transmitting information over the Internet.</Typography>

                    <Typography variant="body2" gutterBottom>We don’t collect or retain your personal financial information such as bank account, debit card or credit card data.</Typography>

                    <Typography variant="body2" gutterBottom>CLOSURE OF AN ACCOUNT OPENED ON THE WEBSITE</Typography>
                    <Typography variant="body2" gutterBottom>If you would like to stop using the Services, you should contact contact@ilearnplace.com and we will suspend Your Account and/or delete any information associated with Your Account. It will take 10 days to complete the process and upon account closure, some information will remain as they are backup copies for a reasonable period of time. Please note, content you create and share on the Website will be owned by you and may remain on the website even upon termination or closure of account.</Typography>

                    <Typography variant="body2" gutterBottom>AMENDMENT</Typography>
                    <Typography variant="body2" gutterBottom>ILP - Group Community reserves the right to amend or update the terms of this Privacy Policy without giving any notice to you. It is advised that you regularly go through our Privacy Policy to make sure you are aware of the changes, if any. Further, ILP - Group allows you to review, change, update or delete your account information at anytime. ILP - Group, however, reserves the right to verify and authenticate your identity for the same.</Typography>

                    <Typography variant="body2" gutterBottom>CONTACT</Typography>
                    <Typography variant="body2" gutterBottom>Should you have any grievance/queries/complaints about the collection/use of your information by ILP - Group, please feel free to contact Mr.Manikandan at feedback@ilearnplace.com or +91 809 441 4990</Typography>

                    <Typography variant="body2" gutterBottom>CONSENT</Typography>
                    <Typography variant="body2" gutterBottom>By using the website and/or by providing your information, you signify your assent to this Privacy Policy and further you consent to the collection, storage and use of the information you disclose on the website by ILP - Group in accordance with this Privacy Policy. You further agree and acknowledge that ILP - Group shall not be liable for any loss or damage or unauthorized use or disclosure of any information provided by you to ILP - Group in the event the same happens due to any reason(s) which are out of ILP - Group’s control or anticipation.</Typography>

                    <Typography variant="body2" gutterBottom>OPTING OUT AND UNSUBSCRIBING</Typography>
                    <Typography variant="body2" gutterBottom>1. Reviewing, Correcting and Removing Your Personal Information</Typography>

                    <Typography variant="body2" gutterBottom>Upon request, ILP - Group will provide you with the requisite information about whether we hold any of your Personal Information. If you provide us with your Personal Information, you have the following rights with respect to that information:</Typography>

                    <Typography variant="body2" gutterBottom>To review the information that you have supplied to us</Typography>
                    <Typography variant="body2" gutterBottom>To request that we correct any errors, outdated information, or omissions in user information that you have supplied to us</Typography>
                    <Typography variant="body2" gutterBottom>To request that your user information not be used to contact you</Typography>
                    <Typography variant="body2" gutterBottom>To request that your user information be removed from any solicitation list that we use</Typography>
                    <Typography variant="body2" gutterBottom>To request that your user information be deleted from our records</Typography>
                    <Typography variant="body2" gutterBottom>To opt out of being solicited by ILP - Group</Typography>
                    <Typography variant="body2" gutterBottom>To exercise any of these rights, please contact us at contact@ilearnplace.com. We will respond to your request to change, correct, or delete your information within a reasonable timeframe and notify you of the action we have taken.</Typography>

                    <Typography variant="body2" gutterBottom>2. To Unsubscribe from Our Communications</Typography>

                    <Typography variant="body2" gutterBottom>You may unsubscribe from our marketing communications by clicking on the "unsubscribe" link located on the bottom of our e-mails, or by sending us an email at info@sproboticworks.com. Attention: Privacy. Customers cannot opt out of receiving transactional emails related to their account with us.</Typography>


                </Container>
            </div>
        </React.Fragment >
    );
}




//                 </Typography>
//             </Container>
//         </React.Fragment>
//     );
// }
