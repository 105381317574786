import React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import NavLogo from "../Pricing/assets/ilp.svg";

const Nav = styled(AppBar)({
  background: "#272a33",
  position: "fixed",
  top: 0,
});
export default function Navbar() {
  return (
    <div>
      <Nav position="fixed" >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box ml={13} >
            <img src={NavLogo} height={44} width={120} alt="logo" />
          </Box>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <Box mr={13}>
              <Typography variant="h6">Home</Typography>
            </Box>
          </Link>
        </Toolbar>
      </Nav>
    </div>
  );
}
