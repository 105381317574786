import React, { useState } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Service from "../../components/Service/service";
import OurPatner from "../../components/OurPartner/ourpartner";
import AboutUs from "../../components/AboutUs/AboutUs";
import WebsiteDesc from "../../components/WebsiteDesc/WebsiteDesc";
import Pricing from "../../components/Pricing/pricing";
//import Team from '../../components/Team/Team';
//import Process from "../../components/Process/Process";
import Testimonials from "../../components/Testimonials/Testimonials";
import GetStart from "../../components/GetStart/GetStart";
//import Blog from "../../components/Blog/Blog"
// import {Contact} from "../../components/Contact/Contact";
import Contact from "../../components/Contact/Contact";
import GameDetails from "../../components/Pricing/GameDetails";
import Social from "../../components/Social/Social";
import Footer from "../../components/Footer/footer";
import Islp from "../NewIslp/section";
// import Video from "./promoVideo";
import Tables from "../NewIslp/table";
import Toaster from "../../utils/updates/registrationNow";

function Index2() {
  const [islp, setIslp] = useState(false);
  const state = {
    navItems: [
      { id: 1, idnm: "home", navheading: "Home" },
      { id: 2, idnm: "features", navheading: "AboutUs" },
      { id: 3, idnm: "ispl", navheading: "ISPL-2024" },
      { id: 4, idnm: "games", navheading: "Games" },
      // { id: 5, idnm: "services", navheading: "Services" },
      { id: 5, idnm: "partners", navheading: "Partners" },
      { id: 6, idnm: "contact", navheading: "ContactUs" },
      // { id: 6, idnm: "team", navheading: "Team" },
      // { id: 6 , idnm : "blog", navheading: "Blog" },
    ],
    navClass: "",
    new: true,
  };

  return (
    <React.Fragment>
      {/* Importing Navbar */}
     <NavbarPage
        navItems={state.navItems}
        navClass={state.navClass}
        setIslp={setIslp}
      />

      <div className="promoVideo">{/* <Video /> */}</div>

      {/* section */}
      {!islp ? <Section setIslp={setIslp} /> : null}

      {/* about us */}
      {!islp ? <AboutUs /> : null}

      {/* pricing */}
      {!islp ? <Pricing /> : null}

      {/* services */}
      {!islp ? <Service sectionClass="pt-5" /> : null}

      {/* OurPatner */}
      {!islp ? <OurPatner sectionClass="pt-5" /> : null}

      {/* website description */}
      {!islp ? <WebsiteDesc setIslp={setIslp} /> : null}

      {/* team */}
      {/* <Team /> */}
      {islp ? <Islp /> : null}
      {islp ? <AboutUs Islp={islp} /> : null}
      {islp ? <Tables /> : null}

      {/* testimonial */}
      {!islp ? <Testimonials /> : null}

      {/* get started */}
      {!islp ? <GetStart setIslp={setIslp} /> : null}

      {/* blog */}
      {/* <Blog/> */}

      {/* contact */}
      {!islp ? <Contact /> : null}

      {/* social */}
      <Social />

      {/* footer */}
      <Footer />
      <Toaster show={true} message="Registration Now" />
    </React.Fragment>
  );
}

export default Index2;
